<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-1">
            <b-col md="3">
              <b-input-group>
                <b-form-input
                  v-model="codigo"
                  type="number"
                  placeholder="FILTRO POR CÓDIGO"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="filterCodigo"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-overlay
            :show="load"
            variant="transparent"
          >
            <b-row>
              <b-col>
                <b-table
                  responsive
                  striped
                  :items="items"
                  :fields="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #cell(show_details)="row">
                    <b-form-checkbox
                      v-model="row.detailsShowing"
                      class="custom-control-primary"
                      @change="row.toggleDetails"
                    >
                      <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                          <i class="vs-icon feather icon-check" />
                        </span>
                      </span>
                      <span class="vs-label">{{ row.detailsShowing ? 'Esconder' : 'Descrição' }}</span>
                    </b-form-checkbox>
                  </template>

                  <template #row-details="row">
                    <b-card>
                      <b-row class="mb-2">
                        <b-col
                          md="12"
                          class="mb-1"
                        >
                          <p style="white-space: pre-wrap; word-wrap: break-word; white-space: pre-line">
                            {{ row.item.descricao }}
                          </p>
                        </b-col>
                      </b-row>

                      <b-button
                        size="sm"
                        variant="outline-secondary"
                        @click="row.toggleDetails"
                      >
                        Esconder Descrição
                      </b-button>
                    </b-card>
                  </template>

                  <template #cell(executivo)="data">
                    <b-avatar
                      :text="getInitials(data.item.executivo)"
                      size="32"
                    />
                    {{ fisrtLastName(data.item.executivo) }}
                  </template>

                  <template #cell(datahora)="data">
                    {{ americaDate(data.item.datahora) }} {{ data.item.datahora.split(' ')[1] }}
                  </template>

                  <template #cell(motivoEvento)="data">
                    <strong>{{ data.item.codigo }}</strong> - {{ data.item.motivo }}
                  </template>
                </b-table>

                <b-pagination
                  v-model="currentPage"
                  :total-rows="items.length"
                  :per-page="perPage"
                  align="center"
                  size="md"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormCheckbox,
  BButton,
  BAvatar,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'

import { mapState } from 'vuex'
import api from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormCheckbox,
    BButton,
    BAvatar,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      eventos: [],
      items: [],
      fields: [
        { key: 'show_details', label: 'Mostrar Detalhes' },
        { key: 'datahora', label: 'Data' },
        { key: 'executivo', label: 'Executivo' },
        { key: 'motivoEvento', label: 'Motivo' },
      ],
      codigo: null,
      perPage: 15,
      currentPage: 1,
      load: false,
    }
  },
  computed: {
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
    }),
  },
  created() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.load = true
      const { cpf_cnpj: doc } = this.cliente
      if (doc) {
        api.get(`http://192.168.254.205/crm/eventos/${doc}`, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(res => {
          this.load = false
          this.eventos = res.data
          this.items = res.data
        })
      }
    },
    filterCodigo() {
      // eslint-disable-next-line no-unused-vars
      const { eventos, codigo } = this
      if (codigo) {
        this.items = eventos.filter(e => e.codigo === parseInt(codigo))
      } else {
        this.items = eventos
      }
    },
  },
}
</script>
