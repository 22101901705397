<template>
  <b-row>
    <b-col sm="2">
      <h6><strong>Valor da Dívida</strong></h6>
      <h3>{{ valorBr(total.totalNominal, true) }}</h3>
    </b-col>
    <b-col sm="2">
      <h6><strong>{{ juros }}% Juros</strong></h6>
      <h3>{{ valorBr(total.totalJuros, true) }}</h3>
    </b-col>
    <b-col sm="2">
      <h6><strong>{{ multa }}% Multa</strong></h6>
      <h3>{{ valorBr(total.totalMulta, true) }}</h3>
    </b-col>
    <b-col sm="2">
      <h6>
        <strong>&asymp; {{ honorarios }}% Hon. Variável</strong>
      </h6>
      <h3>{{ valorBr(total.totalHonorario, true) }}</h3>
    </b-col>
    <b-col sm="2">
      <h6>
        <strong>{{ desconto }}% Desconto</strong>
      </h6>
      <h3>{{ valorBr(total.totalDesconto, true) }}</h3>
    </b-col>
    <b-col sm="2">
      <h6><strong>Valor da Negociação</strong></h6>
      <h3>{{ valorBr(total.valorTotal, true) }}</h3>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
  },
  computed: {
    ...mapState({
      juros: state => state.negociar.negociacao.juros,
      multa: state => state.negociar.negociacao.multa,
      honorarios: state => state.negociar.negociacao.honorarios,
      desconto: state => state.negociar.negociacao.desconto,
      operacoesSelected: state => state.negociar.operacoesSelected,
      total: state => state.negociar.totais,
    }),
  },
}

</script>
