<template>
  <div>
    <b-row>
      <b-col>
        <label for=""><strong>Juros</strong></label>
        <b-form-input
          v-model="parametro.juros"
          type="number"
        />
      </b-col>
      <b-col>
        <label for=""><strong>Multas</strong></label>
        <b-form-input
          v-model="parametro.multa"
          type="number"
        />
      </b-col>
      <b-col>
        <label for=""><strong>Descontos</strong></label>
        <b-form-input
          v-model="parametro.desconto"
          type="number"
        />
      </b-col>
    </b-row>

    <hr>

    <b-table
      :fields="fields"
      :items="parcelas"
      striped
      selectable
      select-mode="multi"
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template>

        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template>

      <template #cell(vencimento_original)="data">
        {{ formatTimezone(data.item.vencimento_original) }}
      </template>

      <template #cell(valor_nominal_original)="data">
        {{ valorBr(parseFloat(data.item.valor_nominal_original), true) }}
      </template>

      <template #cell(juros_atualizado)="data">
        {{ valorBr(parseFloat(data.item.juros_atualizado), true) }}
      </template>

      <template #cell(multa_atualizado)="data">
        {{ valorBr(parseFloat(data.item.multa_atualizado), true) }}
      </template>

      <template #cell(honorario_atualizado)="data">
        {{ valorBr(parseFloat(data.item.honorario_atualizado), true) }}
      </template>

      <template #cell(desconto_atualizado)="data">
        {{ valorBr(parseFloat(data.item.desconto_atualizado), true) }}
      </template>

      <template #cell(nova_data)="data">
        <div>
          <b-input-group class="mb-1">
            <b-form-input
              disabled
              type="text"
              show-decade-nav
              :value="americaDate(data.item.newVencimento)"
            />
            <b-input-group-append>
              <b-form-datepicker
                id="date-picker"
                v-model="dataVenc[data.index]"
                show-decade-nav
                button-only
                size="sm"
                right
                local="en-US"
                aria-controls="example-input"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </template>
    </b-table>

    <hr>

    <b-row class="mb-1">
      <b-col sm="2">
        <h6>Valor da Dívida</h6>
        <h3>R$ {{ valorBr(totais.nominal) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>Juros</h6>
        <h3>R$ {{ valorBr(totais.juros) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>Multa</h6>
        <h3>R$ {{ valorBr(totais.multa) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>Honorários</h6>
        <h3>R$ {{ valorBr(totais.honorarios) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>
          Desconto
        </h6>
        <h3>R$ {{ valorBr(totais.desconto) }}</h3>
      </b-col>
      <b-col sm="2">
        <h6>Valor da Negociação</h6>
        <h3>R$ {{ valorBr(totais.total) }}</h3>
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col>
        <b-row>
          <b-col class="text-center">
            <h4>{{ progressMessage }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-progress
              :key="bar.variant"
              animated
              :value="bar.value"
              :variant="bar.variant"
              :class="'progress-bar-' + bar.variant"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BFormInput,
  BTable,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BProgress,
} from 'bootstrap-vue'

import { mapState } from 'vuex'

import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'

dayjs.extend(dayjsBusinessDays)

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BProgress,
  },
  data() {
    return {
      fields: [
        { key: 'selected', label: '' },
        { key: 'numero_parcela', label: 'Nº Parcela', class: 'text-center' },
        { key: 'vencimento_original', label: 'Vencida em', class: 'text-center' },
        { key: 'valor_nominal_original', label: 'Nominal', class: 'text-center' },
        { key: 'juros_atualizado', label: 'Juros', class: 'text-center' },
        { key: 'multa_atualizado', label: 'Multa', class: 'text-center' },
        { key: 'honorario_atualizado', label: 'Hon. Variável', class: 'text-center' },
        { key: 'desconto_atualizado', label: 'Descontos', class: 'text-center' },
        { key: 'valor_atualizado', label: 'Atualizado', class: 'text-center' },
        { key: 'nova_data', label: 'Novo Vencimento', class: 'text-center' },
      ],
      parametro: {
        juros: 1,
        multa: 2,
        desconto: 0,
      },
      totais: {
        nominal: 0,
        juros: 0,
        multa: 0,
        honorarios: 0,
        desconto: 0,
        total: 0,
      },

      dataVenc: [],
    }
  },
  computed: {
    ...mapState({
      parcelasToUpdate: state => state.negociacao.parcelasToUpdate,
      parcelasSelected: state => state.negociacao.parcelasSelected,
      progressMessage: state => state.negociacao.attParcerlaMessage,
      bar: state => state.negociacao.attParcerlaBar,
    }),

    parcelas() {
      const { parcelasToUpdate } = this
      const diasVencidos = (dataVencimento, dataEntrada) => {
        const dtv = dataVencimento.split('/')
        const dataV = `${dtv[1]}/${dtv[0]}/${dtv[2]}`
        const dte = dataEntrada.split('-')
        const dataE = `${dte[1]}/${dte[2]}/${dte[0]}`
        const dataInicialMilissegundos = new Date(dataV).getTime()
        const dataFinalMilissegundos = new Date(dataE).getTime()
        const umDiaMilissegundos = 1000 * 60 * 60 * 24
        const diferencaMilissegundos = dataFinalMilissegundos - dataInicialMilissegundos
        const diferencaData = Math.round(
          diferencaMilissegundos / umDiaMilissegundos,
        )
        return diferencaData
      }
      const valorJuros = (valorNominal, dVencidos, taxa) => {
        const tJuros = taxa / 3000
        const vlJuros = valorNominal * parseFloat(tJuros) * dVencidos
        return vlJuros.toFixed(2)
      }
      const valorMulta = (valorNominal, taxa) => {
        const tMulta = taxa / 100
        const vlMulta = valorNominal * parseFloat(tMulta)
        return vlMulta.toFixed(2)
      }
      // eslint-disable-next-line no-unused-vars
      const items = parcelasToUpdate.map((parcela, idx) => {
        const dataEntrada = this.dataVenc[idx] || new Date().toISOString().split('T')[0]
        const {
          // valor_nominal_original: valorNominal,
          vencimento_original: dataVencimento,
        } = parcela
        const dVencidos = diasVencidos(this.americaDate(dataVencimento, true), dataEntrada)

        const baseJuros = parseFloat(valorJuros(parseFloat(parcela.valor_nominal_original), dVencidos, this.parametro.juros))
        const baseMulta = parseFloat(valorMulta(parseFloat(parcela.valor_nominal_original), this.parametro.multa))

        const juros = baseJuros - (baseJuros * 0.2)
        const multa = baseMulta - (baseMulta * 0.2)

        const honorario = (baseJuros - juros) + (baseMulta - multa)

        const desconto = parseFloat(parcela.valor_nominal_original) * (this.parametro.desconto / 100)
        const newNominal = (parseFloat(parcela.valor_nominal_original) + juros + multa - desconto + honorario)

        parcela.juros_atualizado = parseFloat(juros).toFixed(2)
        parcela.multa_atualizado = parseFloat(multa).toFixed(2)
        parcela.honorario_atualizado = honorario.toFixed(2)
        parcela.desconto_atualizado = desconto.toFixed(2)
        parcela.newNominal = newNominal.toFixed(2)
        parcela.valor_nominal = newNominal.toFixed(2)
        parcela.newVencimento = dataEntrada
        parcela.parametro = this.parametro
        parcela.parametro.honorario = 20
        parcela.valor_atualizado = (parseFloat(juros) + parseFloat(multa) + honorario + parseFloat(parcela.valor_nominal_original) - desconto).toFixed(2)
        return parcela
      })
      const totais = this.setTotais()
      Object.keys(totais).forEach(key => {
        this.totais[key] = totais[key]
      })
      return items
    },
  },
  watch: {
  },
  created() {
    this.parcelasToUpdate.map((parcela, idx) => {
      const vencimento = dayjs(new Date()).businessDaysAdd(22 * idx).format('YYYY-MM-DD')
      this.dataVenc[idx] = vencimento
    })
  },
  methods: {
    onRowSelected(items) {
      this.$store.commit('negociacao/setParcelasSelected', items)
    },
    setTotais() {
      const { parcelasSelected } = this

      const sumValues = key => parcelasSelected.reduce((acc, p) => acc + parseFloat(p[key]), 0).toFixed(2)

      const nominal = parseFloat(sumValues('valor_nominal_original'))
      const juros = parseFloat(sumValues('juros_atualizado'))
      const multa = parseFloat(sumValues('multa_atualizado'))
      const honorarios = parseFloat(sumValues('honorario_atualizado'))
      const desconto = parseFloat(sumValues('desconto_atualizado'))

      const total = parseFloat((nominal + juros + multa + honorarios - desconto).toFixed(2))

      return {
        nominal,
        juros,
        multa,
        honorarios,
        desconto,
        total,
      }
    },
  },
}
</script>
