<template>
  <div>
    <b-modal
      ref="modalPendencia"
      no-close-on-backdrop
      title="Motivo Pendência"
      ok-title="Alterar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="description === ''"
      @ok="updatePend"
      @show="limparModal"
    >
      Motivo Pendência:
      <v-select
        v-model="novaPend"
        :options="mtPend.options"
        class="mb-1"
      />
      Descrição:
      <b-form-textarea
        v-model="description"
      />
    </b-modal>

    <div>
      <b-modal
        id="create-devolucao"
        ref="modalDevolucao"
        no-close-on-backdrop
        ok-title="Criar Devolução"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @show="resetModal"
        @hidden="resetModal"
        @ok="newDevolution"
      >
        <b-row>
          <b-col>
            Motivo da Devolução
            <v-select
              v-model="reasonSelected"
              :options="reasonDevolution"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            Descirção da Devolução
            <b-form-textarea
              v-model="descriptionDevolution"
            />
          </b-col>
        </b-row>
      </b-modal>
    </div>

    <b-modal
      ref="viewDataPendencies"
      title="Log de pendencias"
      ok-only
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in rend"
          :key="item.id"
          :variant="item.status === 'CONCLUIDO' ? 'success' : item.status === 'EM ANDAMENTO'? 'warning' : item.status === 'REGISTRO' ? 'secondary' : item.status === 'REENVIADO' ? 'primary' : 'info'"
        >
          <div>
            <h6>
              {{ item.numero_operacao }}
            </h6>

            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Observações: </strong>{{ item.observação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Status: </strong>{{ item.status }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Data: </strong>{{ item.data_criação }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Atualização: </strong>{{ item.data_atualização }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Nome usuário: </strong>{{ item.nome_usuario }}</span>
              </b-list-group-item>
              <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span><strong>Motivo Pendência: </strong>{{ item.motivo_pendencia }}</span>
              </b-list-group-item>

              <br>
            </b-list-group>

          </div>
        </app-timeline-item>
      </app-timeline>
    </b-modal>
    <!-- Modal de tratar pendencias -->
    <b-modal
      ref="sendStatus"
      title="Tratar pendencias"
      ok-only
      no-close-on-backdrop
      :ok-disabled="cardData.observacao === ''"
      @ok="updatedPendencia(cardData.observacao, cardData.status, cardData.operacao)"
    >
      <b-row>
        <!-- <b-col
          v-show="showOper === true"
          cols="12"
          class="mt-2"
        >
          Operações:
          <v-select
            v-model="selected"
            multiple
          />
        </b-col> -->
        <b-col
          cols="12"
          class="mt-2"
        >
          Status:
          <v-select
            v-model="cardData.status"
            :options="option"
          />

        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          Descrição:
          <b-form-textarea
            v-model="cardData.observacao"
            required
          />
        </b-col>
        <b-col
          v-show="cardData.status === 'CONCLUIDO'"
          cols="12"
          class="mt-2"
        >
          Motivo Operação:
          <v-select
            v-model="cardData.operacao"
            :options="optionsOperacaoMotivo"
          />
        </b-col>
      </b-row>
    </b-modal>
    <!-- Modal de tratar pendencias -->

    <!-- Modal para retirar a negativacao -->
    <div>
      <b-modal
        ref="show-modal"
        title="Retirar Negativação"
        no-close-on-backdrop
        ok-title="Alterar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @ok="updateNegativacao"
      >
        <modal-retirada-negativacao
          ref="modal"
          :operacoes="select"
          @refreshGrid="refresh"
        />
      </b-modal>
    </div>
    <!-- Modal para retirar a negativacao -->

    <b-modal
      ref="modalDevolucaoAlterar"
      title="Alterar Devolução"
      no-close-on-backdrop
      ok-title="Alterar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="!reasonSelected"
      @ok="updateDevolution"
    >
      <v-select
        v-model="reasonSelected"
        :options="reasonDevolution"
      />
    </b-modal>
    <b-card>
      <b-row>
        <b-col
          v-for="(item, index) in cards"
          :key="item.icon"
          xl="2"
          sm="3"
          :style="{cursor: 'pointer'}"
          @click="itemClick(index)"
        >
          <b-media
            class="expandir"
            no-body
          >
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-1">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-row align-h="end">
            <b-col class="d-flex justify-content-end mt-1 gapzim">
              <b-button
                v-if="operacaoDevolvida"
                v-show="userData !== 'Executivo de Cobrança' && !isCredor"
                v-b-tooltip.hover
                title="selecione uma pendência"
                :disabled="select.length === 0"
                variant="outline-primary"
                @click="retirarDevolucao()"
              >
                Retirar Devolução
              </b-button>
              <b-button
                v-else
                v-show="userData !== 'Executivo de Cobrança' && !isCredor"
                v-b-tooltip.hover
                title="selecione uma pendência"
                :disabled="select.length === 0"
                variant="outline-primary"
                @click="openModalCriarDevolucao"
              >
                Criar Devolução
              </b-button>
              <b-button
                v-show="!isCredor"
                v-b-tooltip.hover
                :disabled="select.length === 0"
                variant="outline-warning"
                title="selecione uma pendência"
                @click="openModalPendencias"
              >
                Alterar Pendência
              </b-button>
              <b-button
                v-show="!isCredor && userData !== 'Executivo de Cobrança'"
                v-b-tooltip.hover
                title="selecione uma pendência"
                :disabled="select.length === 0"
                variant="outline-info"
                @click="$refs.sendStatus.show()"
              >
                Tratar Pendência
              </b-button>
              <b-button
                v-show="!isCredor && userData !== 'Executivo de Cobrança' && isFinanceiro()"
                v-b-tooltip.hover
                title="selecione uma pendência"
                variant="outline-danger"
                :disabled="select.length === 0"
                @click="openModalRetirarNegativacao()"
              >
                Retirar Negativação
              </b-button>
              <b-button
                v-show="operacaoDevolvida"
                v-b-tooltip.hover
                title="Alterar Devolução"
                variant="outline-danger"
                :disabled="select.length === 0"
                @click="openModalAlterarDevolucao"
              >
                Alterar Devolução
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-card>
          <div
            v-for="(key) in Object.keys(fisrt ? credores : credorNovo)"
            :key="key"
          >
            <h4>
              {{ fisrt ? credores[key].nome : credorNovo[key].nome }}
            </h4>
            <b-table
              ref="selectableTable"
              small
              :fields="fields"
              :items="fisrt ? credores[key].operacoes : credorNovo[key].operacoes"
              :per-page="perPage.selected"
              :current-page="perPage.currentPage"
              striped
              responsive
              :sort-compare="mySortCompare"
              @row-selected="onRowSelected"
            >
              <!--Selects-->
              <template #head(check)>
                <b-form-checkbox
                  v-model="selectAll"
                  :value="fisrt ? credores[key].nome : credorNovo[key].nome"
                />
              </template>

              <template #cell(check)="data">
                <b-form-checkbox
                  v-model="select"
                  :value="data.item"
                />
              </template>
              <!--selects-->

              <template
                #cell(pendencias)="data"
              >
                <b-col
                  md="2"
                >
                  <b-badge
                    v-if="data.item.status.nome"
                    id="popover"
                    v-b-popover.hover.top="`${data.item.status.nome}`"
                    pill
                    :style="{background: 'transparent', color: '#FF8C00'}"
                  >
                    <feather-icon
                      :style=" { height: '18px', width: '18px' } "
                      icon="AlertTriangleIcon"
                    />
                  </b-badge>
                </b-col>

                <b-badge
                  v-show="data.item.pendencias !== null"
                  :style="{cursor: 'pointer'}"
                  :variant="
                    data.item.pendencias === 'CONCLUIDO' ? 'light-success' :
                    data.item.pendencias === 'EM ANDAMENTO'? 'light-warning' :
                    data.item.pendencias === 'REGISTRO' ? 'light-secondary' :
                    data.item.pendencias === 'REENVIADO' ? 'light-primary' :
                    'light-info'"
                  @click="logPendencias(data.item)"
                >

                  {{ data.item.pendencias }}

                </b-badge>

              </template>

              <template
                #cell(order_status)="data"
              >
                <b-row :style="{alignItems: 'center'}">
                  <b-col>
                    <b-badge
                      pill
                      :style="{background: data.item.color, color: '#fff'}"
                    >
                      {{ data.item.sigla }}
                    </b-badge>
                  </b-col>
                </b-row>
              </template>

              <template #cell(numero_acordo)="data">
                <b-link
                  v-if="data.item.acordo"
                  class="font-weight-bold d-block text-nowrap"
                  @click="openAcordo(data.item.acordo)"
                >
                  <b-badge
                    pill
                    :variant="resolveBadgeOperacao(data.item.acordo.status).variant"
                    :style="{ color: resolveBadgeOperacao(data.item.acordo.status).color }"
                  >
                    {{ data.item.acordo ? data.item.acordo.status.toUpperCase().charAt(0) : '' }}
                  </b-badge>
                  {{ data.item.acordo ? data.item.acordo.numero_acordo : '' }}
                </b-link>
              </template>
              <template #cell(operacao)="data">
                <b-badge
                  v-if="data.item.negativacao === 1"
                  v-b-popover.hover.top="'Operação Negativada'"
                  pill
                  :style="{background: 'transparent', color: '#FF8C00'}"
                >
                  <feather-icon
                    :style=" { height: '18px', width: '18px' } "
                    icon="AlertCircleIcon"
                  />
                </b-badge>
                <div>
                  {{ data.item.operacao }}
                </div>
              </template>
              <template #cell(motivo_devolucao)="data">
                {{ data.item.motivo_devolucao ? data.item.motivo_devolucao.nome : null }}
              </template>
            </b-table>
          </div>
        </b-card>
        <b-pagination
          v-model="perPage.currentPage"
          :total-rows="perPage.totalRows"
          :per-page="perPage.selected"
          align="center"
          size="md"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

// IMmportando dependecias de estilos
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BCardText,
  BMedia,
  VBTooltip,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BButton,
  BModal,
  VBPopover,
  BLink,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import { mapState } from 'vuex'
import axios from '@/../axios-auth'
import ModalRetiradaNegativacao from '../../../financeiro/dashBoard/components/tabs/ModalRetiradaNegativacao.vue'

// Importando mecanismo de requisições

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormTextarea,
    BBadge,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BModal,
    vSelect,
    BLink,
    BCard,
    ModalRetiradaNegativacao,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    acordoProp: {
      type: Function,
      required: true,
    },
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      itemSelected: [],
      userData: localStorage.getItem('funcao'),
      reasonSelected: null,
      descriptionDevolution: null,
      reasonDevolution: [],
      rend: {
        idd: '',
        numero_operacao: '',
        operacao_id: '',
        usuario_empresa_id: '',
        observacao: '',
        status: '',
        created_at: '',
        updated_at: '',
        nome_usuario: '',
        motivo_pendencia: '',
      },

      pendenciesList: [],
      credorID: [],
      operacoes: [],
      novaPend: '',
      selectAll: false,
      select: [],
      description: null,
      items: [],
      credores: {},
      status_operacoes: {},
      totais: {
        acordos: [],
        cobranca: [],
        negociada: [],
        liquidada: [],
        negativada: [],
        devolvido: [],
      },
      perPage: {
        selected: 50,
        options: [10, 15, 20],
        currentPage: 1,
        totalRows: 1,
      },
      mtPend: {
        selected: '',
        options: [
          {
            label: 'REMOVER PENDENCIA',
            id: null,
          },
        ],
      },
      cards: [],
      fields: [
        'check',
        {
          key: 'pendencias', label: 'Pendencia', class: 'text-center',
        },
        {
          key: 'order_status', label: 'Status', class: 'text-center',
        },
        { key: 'numero_acordo', label: 'acordo', class: 'text-center' },
        {
          key: 'operacao', label: 'Operação', class: 'text-center', sortable: true,
        },
        {
          key: 'aluno', label: 'Aluno', class: 'text-left', sortable: true,
        },
        'descricao',
        {
          key: 'vencimento', label: 'Vencimento', class: 'text-center', sortable: true,
        },
        {
          key: 'data_atualizacao', label: 'Data Atualização', class: 'text-center', sortable: true,
        },
        {
          key: 'valor_nominal', label: 'Vl nominal', class: 'text-right', sortable: true,
        },
        {
          key: 'valor_atualizado', label: 'Vl Atualizado', class: 'text-right', sortable: true,
        },
        {
          key: 'data_processamento', label: 'Data importação',
        },
        {
          key: 'status_anexos', label: 'Status Anexos',
        },
        {
          key: 'motivo_devolucao', label: 'Motivo Devolução',
        },

      ],
      isCredor: localStorage.getItem('credores'),
      itemClicado: null,
      credorNovo: null,
      fisrt: true,
      credoresCobrancas: {},
      credoresNegociadas: {},
      credoresLiquidadas: [],
      credoresNegativados: {},
      // Options para tratar a pendencia
      cardData: {
        operacoes_id: [],
        observacao: '',
        status: '',
        operacao: '',
      },
      option: [
        'INICIADO',
        'EM ANDAMENTO',
        'REENVIADO',
        'CONCLUIDO',
      ],
      optionsOperacaoMotivo: [
        'DEVOLUÇÃO',
        'BAIXA ADMINSTRATIVA',
        'RETORNO PARA COBRANÇA',
      ],
      operacaoDevolvida: false,
    }
  },
  computed: {
    ...mapState({
      operacoesCliente: state => {
        const { operacoes } = state.negociacao
        return operacoes
      },
    }),

    pendencies() {
      const pendencie = []
      this.pendenciesList.map(item => {
        this.operacoes.map(op => {
          item.operacoes_id === op.id ? pendencie.push(item) : null
        })
      })
      return pendencie
    },

    onRowSelected(items) {
      this.perPage.currentPage = 1
      return items
      // this.itemSelected = items
    },

  },
  watch: {
    selectAll(n) {
      n ? this.selectAllRows(n) : this.clearSelected()
    },
    operacoesCliente() {
      this.getOperacoes()
    },
    select(n) {
      n ? this.verificarOperacao(n) : null
    },
  },
  async created() {
    await this.getStatusOperacao()
    await this.getOperacoes()
    this.setCards()
  },
  methods: {
    clearSelected() {
      this.select = []
    },

    selectAllRows(nome) {
      this.credores.map(item => {
        if (nome === item.nome) {
          item.operacoes.map(dt => {
            this.select.push(dt)
          })
        }
        // this.select.push(item)
      })
    },
    async newDevolution() {
      const { id } = this.$route.params

      const obj = []
      this.select.map(item => {
        obj.push({
          operacao: Number(item.id),
          cliente_id: id,
        })
      })

      if (this.descriptionDevolution && this.reasonSelected && this.select.length > 0) {
        const dataDev = {
          motivo_devolucao_id: this.reasonSelected.id,
          descricao: this.descriptionDevolution,
          operacoes: obj,
        }
        const headers = {
          Accept: 'Application/json',
          headers: { Authorization: 'Bearer '.concat(localStorage.getItem('token')) },
        }

        axios.post('api/v1/devolucao/store', dataDev, headers)
          .then(res => {
            if (res.status === 200) {
              this.$swal({
                title: 'sucesso',
                text: 'Devolução feita com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(error => {
            this.$swal({
              title: 'Error!',
              text: error.response.data.mensagem,
              icon: 'error',
              showConfirmButton: false,
              timer: 5000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      }

      /*
        Esse bloco  de código verifica quais as operações selecionadas e traz os
        seus respectivos JSON com dados das operações. Em seguida verifica quais
        possuem pendencias e se tiver pendencias o mesmo trata de remove-las.

        OBS.: Não foi usado o this.select porque na tabela (HTML) o value do
        select está trazendo apenas o id e como ele é usado em muitas partes do
        código, não quis refatora-lo.
      */
      const allOperationsId = []
      const operationsWithPendences = []
      this.operacoes.map(item => {
        this.select.map(sel => {
          item.id === sel ? allOperationsId.push(item) : null
        })
      })

      allOperationsId.map(item => {
        item.motivo_pendencia.id !== null ? operationsWithPendences.push(item.id) : null
      })

      if (operationsWithPendences.length > 0) {
        const body = {
          ops_ids: operationsWithPendences,
          motivo_pendencia_id: null,
          description: this.descriptionDevolution,
          motivoId: 12,
        }
        await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.$swal({
            title: 'Pendência alterada!',
            text: 'Alteração executada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }).catch(() => {
          this.$swal({
            title: 'ERROR!',
            text: 'Erro ao executar alteração!',
            icon: 'error',
            customClass: {
              comfirmButton: 'btn btn-danger',
            },
          })
        })
        await this.updateTabs()
        this.novaPend = {}
      }

      await this.updateTabs()
    },

    resetModal() {
      this.reasonSelected = []
      this.descriptionDevolution = ''
    },
    mySortCompare(itemA, itemB, key) {
      if (key === 'vencimento' || key === 'data_atualizacao') {
        let a = itemA[key]
        let b = itemB[key]
        a = a.split('/')
        b = b.split('/')
        a = (parseInt(a[2], 10) * 10000) + (parseInt(a[1], 10) * 100) + parseInt(a[0])
        b = (parseInt(b[2], 10) * 10000) + (parseInt(b[1], 10) * 100) + parseInt(b[0])
        return a - b
      }
      return false
    },

    async getMotPendencia() {
      this.mtPend.options = []
      // MOTIVO PENDENCIA
      await axios.get('api/v1/info/motivopendencia', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.mtPend.options.push({
            label: dt.nome,
            id: dt.id,
          })
        ))
      })
    },
    async getOperacoes() {
      this.operacoes = this.operacoesCliente

      await this.setOperacoes(this.operacoes)
      // await this.logPendencias(this.operacoesCliente)
    },

    limparModal() {
      this.novaPend = ''
      this.description = ''
    },

    async updatePend() {
      const operId = []
      this.select.map(item => {
        operId.push(item.id)
      })
      if (this.novaPend.id === null) {
        await this.sendStatusToBackDireto()
      }

      const body = {
        ops_ids: operId,
        motivo_pendencia_id: this.novaPend.id,
        description: this.description,
        motivoId: 12,
      }
      await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.$swal({
          title: 'Pendência alterada!',
          text: 'Alteração executada com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERROR!',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
      await this.updateTabs()
      this.novaPend = {}
      this.select = []
    },
    async updatedPendencia(observacao, status, motivoOperacao) {
      if (status === 'CONCLUIDO') {
        const opId = []
        this.select.map(item => {
          opId.push(item.id)
        })

        // this.cardData.operacoes_id = opId

        await this.sendStatusToBack()

        const body = {
          motivo_pendencia_id: null,
          ops_ids: opId,
          description: observacao,
          motivoId: 21,
          retorno: motivoOperacao,
        }
        await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          },
        }).then(() => {
          this.select = []
          this.$swal({
            title: 'SUCESSO!',
            text: 'Alteração executada com sucesso',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              comfirmButton: 'btn btn-ptimary',
            },
            buttonStyling: false,
          })
        }).catch(() => {
          this.select = []
          this.$swal({
            title: 'ERROR!',
            text: 'Erro ao executar alteração!',
            icon: 'error',
            customClass: {
              comfirmButton: 'btn btn-danger',
            },
          })
        })
        await this.updateTabs()
      } else {
        await this.sendStatusToBack()
      }
    },
    // função que altera o status do log de pendencia direto pra concluido
    async sendStatusToBackDireto() {
      const operId = []
      this.select.map(item => {
        operId.push(item.id)
      })
      let statusPendencia = ''
      this.select.map(item => {
        statusPendencia = item.status.nome
      })

      const body = {
        operacoes_id: operId,
        observacao: this.description,
        status: 'CONCLUIDO',
        motivo_pendencia: statusPendencia,
      }

      await axios.post('api/v1/log/pendencias/store', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'Pendencia modificada!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro durante o cadastro!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
      await this.updateTabs()
    },
    // função que altera o status do log pendencia
    async sendStatusToBack() {
      const opId = []
      this.select.map(item => {
        opId.push(item.id)
      })

      this.cardData.operacoes_id = opId

      await axios.post('api/v1/log/pendencias/store', this.cardData, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.select = []
        this.$swal({
          title: 'Pendencia modificada!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.select = []
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro durante o cadastro!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
      await this.updateTabs()
      this.select = []
      this.cardData = {}
    },

    async getStatusOperacao() {
      await axios.get('api/v1/info/statusoperacao', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        this.status_operacoes = {}
        const colors = {
          C: '#B22222',
          L: '#66CDAA',
          D: '#696969',
          N: '#836FFF',
        }
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(dt => {
          this.status_operacoes[dt.sigla] = {
            sigla: dt.sigla,
            color: colors[dt.sigla],
          }
        })
      })
    },

    async logPendencias(rowItem) {
      const body = {
        operacao_id: rowItem.id,
      }
      await axios.post('api/v1/operacoes/log/pendencias', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const data = res.data.dados
        this.rend = data.map(item => ({
          id: item.id,
          numero_operacao: item.numero_operacao,
          operacao_id: item.operacao_id,
          usuario_id: item.usuario_empresa_id,
          observação: item.observacao,
          status: item.status,
          data_criação: this.dataHora(item.created_at),
          data_atualização: this.dataHora(item.updated_at),
          nome_usuario: item.nome_usuario,
          motivo_pendencia: item.motivo_pendencia,
        }))
      })

      this.$refs.viewDataPendencies.show()
    },

    async setOperacoes(items) {
      this.credorNovo = items
      items.map(item => {
        const today = new Date()
        const dataItems = new Date(item.data_vencimento)

        item.dias_vencidos = Math.round((today - dataItems) / (1000 * 60 * 60 * 24)) - 1
      })

      this.items = []
      const sortKeys = ['C', 'N', 'L', 'D']
      const credores = {}
      sortKeys.map(keySort => {
        items.map(item => {
          const { sigla, color } = this.status_operacoes[item.status_operacao.sigla]
          const { credor } = item
          if (sigla === keySort) {
            this.credorID.push(credor.id)
            if (!credores[credor.id]) {
              credores[credor.id] = {}
              credores[credor.id].operacoes = []
              credores[credor.id].nome = credor.nome
            }
            switch (sigla) {
              case 'C':
                this.totais.cobranca.push(item)
                break
              case 'N':
                this.totais.negociada.push(item)
                break
              case 'L':
                this.totais.liquidada.push(item)
                break
              case 'D':
                this.totais.devolvido.push(item)
                break
              default:
                break
            }
            if (item.classificacao_negativacao.id === 1) {
              this.totais.negativada.push(item.classificacao_negativacao.id)
            }

            credores[credor.id].operacoes.push({
              sigla,
              color,
              id: item.id,
              days_due: item.dias_vencidos,
              pendencias: item.status_pendencia ? item.status_pendencia.status : null,
              status: item.motivo_pendencia,
              operacao: item.numero_operacao,
              aluno: (item.aluno),
              data_processamento: item.data_processamento ? this.dataHora(item.data_processamento, true) : '',
              vencimento: item.data_vencimento,
              data_atualizacao: item.data_atualizacao ? this.dataHora(item.data_atualizacao, true) : '',
              valor_nominal: this.valorBr(item.valor_nominal, true),
              valor_atualizado: this.valorBr(item.valor_atualizado, true),
              descricao: `${item.descricao} -${item.competencia}`,
              acordo: item.acordo,
              credor: item.credor,
              status_anexos: item.status_anexos,
              statusAcordo: item.acordo !== null ? item.acordo.status : null,
              negativacao: item.classificacao_negativacao !== null ? item.classificacao_negativacao.id : null,
              motivo_devolucao: item.motivo_devolucao !== null ? item.motivo_devolucao : null,
            })
            // Reordenação da lista pelo id do acordo
            credores[credor.id].operacoes.sort((a, b) => {
              if (a.sigla === 'N' && a.statusAcordo === 'Quebrado') return 1
              if (b.sigla === 'N' && b.statusAcordo === 'Quebrado') return -1
              if (a.sigla === 'D') return 1
              if (b.sigla === 'D') return -1
              if (a.statusAcordo === b.statusAcordo) {
                if (a.statusAcordo === b.statusAcordo) {
                  return b.operacao - a.operacao
                }
                return a.numero_acordo > b.numero_acordo ? 1 : null && (a.numero_acordo < b.numero_acordo ? -1 : 0)
              }
              return a.statusAcordo - b.statusAcordo
            })
          }
          return 123
        })
      })
      this.credores = credores
      const credoresOrdenados = []
      const credoresKeys = Object.keys(credores)
      for (let i = 0; i < credoresKeys.length; i++) {
        let temSiglaC = false
        for (let j = 0; j < credores[credoresKeys[i]].operacoes.length; j++) {
          if (credores[credoresKeys[i]].operacoes[j].sigla === 'C') {
            temSiglaC = true
            break
          }
        }
        if (temSiglaC) {
          credoresOrdenados.push(credores[credoresKeys[i]])
        }
      }
      for (let i = 0; i < credoresKeys.length; i++) {
        if (!credoresOrdenados.includes(credores[credoresKeys[i]])) {
          credoresOrdenados.push(credores[credoresKeys[i]])
        }
      }
      this.credorID = [...new Set(this.credorID)]
      this.credores = credoresOrdenados
      this.perPage.totalRows = items.length
    },

    async setCards() {
      const cards = [
        {
          color: 'light-info',
          customClass: '',
          icon: 'ActivityIcon',
          subtitle: 'Operações',
          title: this.operacoes.length,
        },
        {
          color: 'light-danger',
          customClass: '',
          icon: 'AlertTriangleIcon',
          subtitle: 'Cobrança',
          title: this.totais.cobranca.length,
        },
        {
          color: 'light-primary',
          customClass: '',
          icon: 'ClockIcon',
          subtitle: 'Negociadas',
          title: this.totais.negociada.length,
        },
        {
          color: 'light-success',
          customClass: '',
          icon: 'DollarSignIcon',
          subtitle: 'Liquidadas',
          title: this.totais.liquidada.length,
        },
        {
          color: 'light-warning',
          customClass: '',
          icon: 'AlertCircleIcon',
          subtitle: 'Negativadas',
          title: this.totais.negativada.length,
        },
        {
          color: 'light-warning',
          customClass: '',
          icon: 'RotateCcwIcon',
          subtitle: 'Devolvido',
          title: this.totais.devolvido.length,
        },
      ]
      this.cards = cards
    },

    async updateOperacoes() {
      const { id } = this.$route.params
      await axios.get(`api/v1/clientes/operacoes/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const { dados } = res.data
        this.setOperacoes(dados)
      })
    },

    openAcordo(item) {
      this.acordoProp(item)
    },

    resolveBadgeOperacao(status) {
      const response = {
        variant: '',
        color: '#fff',
      }
      if (status) {
        switch (status.toLowerCase()) {
          case 'liquidado':
            response.variant = 'success'
            break
          case 'negociado':
            response.variant = 'primary'
            break
          case 'cancelado':
            response.variant = 'danger'
            break
          case 'aguardando':
            response.variant = 'warning'
            break
          case 'quebrado':
            response.variant = 'danger'
            break
          case 'vencido':
            response.variant = 'secondary'
            break
          default:
            response.variant = 'secondary'
        }
      }
      return response
    },

    // decideClass(index) {
    //   if (index)
    // },
    itemClick(data) {
      // a partir do uso dos filtros os dados usados na table seram somente dados filtrados, exeto case 0.
      this.fisrt = false
      const k = this.credores

      const filterBySigla = sigla => {
        const filteredCredores = k.map(credor => ({
          ...credor,
          operacoes: credor.operacoes.filter(operacao => operacao.sigla === sigla) }))

        const nonEmptyOperacoes = filteredCredores.filter(item => item.operacoes.length !== 0)

        this.credorNovo = nonEmptyOperacoes
        this.perPage.totalRows = nonEmptyOperacoes.length
        this.credoresLiquidadas = nonEmptyOperacoes
        this.perPage.currentPage = 1
      }
      const filterNegat = () => {
        const filteredCredores = k.map(credor => ({
          ...credor,
          operacoes: credor.operacoes.filter(operacao => operacao.negativacao !== null) }))

        const nonEmptyOperacoes = filteredCredores.filter(item => item.operacoes.length !== 0)

        this.credorNovo = nonEmptyOperacoes
        this.perPage.totalRows = nonEmptyOperacoes.length
        this.credoresLiquidadas = nonEmptyOperacoes
        this.perPage.currentPage = 1
      }

      switch (data) {
        case 0:
          this.credorNovo = k
          this.itemClicado = 0
          this.perPage.currentPage = 1
          break

        case 1:
          filterBySigla('C')
          break

        case 2:
          filterBySigla('N')
          break

        case 3:
          filterBySigla('L')
          break

        case 5:
          filterBySigla('D')
          break

        case 4:
          filterNegat()
          break

        default:
          break
      }
    },

    openModalRetirarNegativacao() {
      this.$refs['show-modal'].show()
    },
    // função pra chamar a função dentro do componente
    updateNegativacao() {
      this.$refs.modal.updateNegativacao()
    },
    async refresh() {
      this.select = []
      await this.updateTabs()
    },
    verificarOperacao(item) {
      const itemsSelecionados = item
      itemsSelecionados.map(sigla => {
        if (sigla.sigla === 'D') {
          this.operacaoDevolvida = true
        } else if (sigla.sigla !== 'D') {
          this.operacaoDevolvida = false
        }
      })
    },
    retirarDevolucao() {
      this.$swal({
        title: 'Deseja retirar a devolução dessas operações? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desejo',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const ids = []
          this.select.map(item => {
            ids.push(item.id)
          })
          const body = {
            operacoes_id: ids,
          }
          axios.post('api/v1/devolucao/cancelar', body, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          })
            .then(async () => {
              this.$swal({
                icon: 'success',
                text: 'Retirado a devolução',
                timer: 1500,
                showConfirmButton: false,
              })
              this.select = []
              await this.updateTabs()
            })
            .catch(async () => {
              this.$swal({
                icon: 'XIcon',
                text: 'Algo deu errado ao retirar a devolução',
                timer: 1500,
                showConfirmButton: false,
              })
              this.select = []
              await this.updateTabs()
            })
        }
      })
    },
    async openModalPendencias() {
      await this.getMotPendencia()
      this.$refs.modalPendencia.show()
    },
    async getMotivosDevolucao() {
      this.reasonDevolution = []
      const reasonDevolution = []
      await axios.get('api/v1/motivo_devolucao', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
        .then(res => {
          // eslint-disable-next-line array-callback-return
          res.data.dados.map(item => {
            reasonDevolution.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
      return reasonDevolution
    },
    async openModalCriarDevolucao() {
      this.reasonDevolution = await this.getMotivosDevolucao()
      this.$refs.modalDevolucao.show()
    },
    async openModalAlterarDevolucao() {
      // os ids sao os quue podem ser alterados e para quais vao ser alterados
      const devolucoesPermitidas = [22]
      const motivoDevolucoesAlterar = [28]
      const operacoes = [...this.select]
      const operacoesDevolvidasPermitidas = operacoes.every(item => devolucoesPermitidas.includes(item.motivo_devolucao.motivo_devolucao_id))
      if (!operacoesDevolvidasPermitidas) {
        return this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Você selecionou alguma operação que não é permitida para alterar a devolução!',
        })
      }
      const response = await this.getMotivosDevolucao()
      this.reasonDevolution = response.filter(item => motivoDevolucoesAlterar.includes(item.id))
      return this.$refs.modalDevolucaoAlterar.show()
    },
    updateDevolution() {
      const body = {
        operacoes_id: this.select.map(item => item.id),
        cliente_id: parseInt(this.$route.params.id),
        motivo_devolucao_id: this.reasonSelected.id,
      }
      axios
        .put('api/v1/devolucao/alterar', body)
        .then(() => {
          this.select = []
          this.reasonSelected = []
          this.updateTabs()
          this.$swal({
            icon: 'success',
            text: 'Devolução alterada!',
            timer: 1500,
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'XIcon',
            text: 'Algo deu errado ao alterar a devolução',
            timer: 1500,
          })
        })
    },
  },
}
</script>
<style>
  .gapzim {
    gap: 7px;
  }
  .expandir {
    transition: all 0.3s ease;
  }
  .expandir:hover {
    transform: scale(1.1);
    opacity: 0.9;
  }
</style>
