<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          md="6"
          sm="12"
        >
          <label for="filtro-motivo">Filtro por Motivo</label>
          <v-select
            id="filtro-motivo"
            v-model="motivoEventos.selected"
            class="mb-1"
            multiple
            label="nome"
            :options="motivoEventos.options"
          />
        </b-col>
        <b-col
          md="2"
          sm="2"
        >
          <label for="filtro-motivo">Por página</label>
          <v-select
            id="filtro-motivo"
            v-model="eventsPerPage.selected"
            class="mb-1"
            :options="eventsPerPage.options"
            :clearable="false"
            @change="filtroEvento()"
          />
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="checked"
            :disabled="checkedAll"
          >
            <span class="vs-checkbox">
              <span
                class="vs-checkbox--check"
              />
            </span>
            <span class="vs-label">{{ 'Mostrar Eventos Ocultos' }}</span>
          </b-form-checkbox>
          <b-form-checkbox
            v-model="checkedAll"
            :disabled="checked"
          >
            <span class="vs-checkbox">
              <span
                class="vs-checkbox--check"
              />
            </span>
            <span class="vs-label">{{ 'Mostrar Todos' }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-table
            sticky-header="70vh"
            :items="checked ? ficticyOcult : checkedAll ? ficticyAll : ficticy"
            :fields="fields"
            :per-page="eventsPerPage.selected"
            :current-page="eventsPerPage.currentPage"
            :tbody-tr-class="rowClass"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            striped
            responsive
          >
            <template #cell(show_details)="row">
              <div
                v-show="row.item.fixo"
                class="mb-1"
              >
                <feather-icon
                  icon="FlagIcon"
                  size="18"
                />
              </div>
              <div
                v-show="row.item.oculto && checkedAll"
                class="mb-1"
              >
                <feather-icon
                  icon="EyeOffIcon"
                  size="18"
                />
              </div>
              <b-form-checkbox
                v-model="selectedRow"
                :value="row.item"
                class="custom-control-primary"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{ row.detailsShowing ? 'Esconder' : 'Descrição' }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col
                    md="7"
                    class="mb-1"
                  >
                    <div v-html="row.item.historico" />
                    <br>
                    <span v-show="row.item.comments !== null"><strong>OBSERVAÇÃO:</strong> <b-badge
                      class="text-wrap text-left"
                      variant="light-secondary"
                    >{{ row.item.comments }} </b-badge></span>
                  </b-col>
                </b-row>

                <div class="demo-inline-spacing">
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Esconder Descrição
                  </b-button>

                  <b-button
                    v-show="row.item.comments === null && row.item.storeObservacao && verificaDataEvento(row.item.datahora)"
                    size="sm"
                    variant="outline-primary"
                    @click="$refs.addComments.show()"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="color-icon"
                    />
                    observação
                  </b-button>
                  <b-button
                    v-if="!row.item.fixo && isExecutive === 'Supervisor(a) Cobrança'"
                    size="sm"
                    variant="outline-info"
                    @click="fixarEvento(row.item.id_row, row.item.fixo)"
                  >
                    Fixar evento
                  </b-button>
                  <b-button
                    v-show="isExecutive === 'Supervisor(a) Cobrança'"
                    v-else
                    size="sm"
                    variant="outline-info"
                    @click="fixarEvento(row.item.id_row, row.item.fixo)"
                  >
                    Desafixar evento
                  </b-button>
                  <b-button
                    v-if="!row.item.oculto && isExecutive === 'Supervisor(a) Cobrança'"
                    v-show="isExecutive === 'Supervisor(a) Cobrança'"
                    size="sm"
                    variant="outline-warning"
                    @click="ocultarEvento(row.item.id_row, row.item.oculto)"
                  >
                    Ocultar evento
                  </b-button>
                  <b-button
                    v-show="isExecutive === 'Supervisor(a) Cobrança'"
                    v-else
                    size="sm"
                    variant="outline-warning"
                    @click="ocultarEvento(row.item.id_row, row.item.oculto)"
                  >
                    Desocultar evento
                  </b-button>

                </div>

              </b-card>
            </template>

            <template #cell(nome)="data">
              <b-avatar
                :text="getInitials(data.item.nome)"
                size="32"
              />
              {{ fisrtLastName(data.item.nome) }}
            </template>
          </b-table>
          <b-pagination
            v-model="eventsPerPage.currentPage"
            :total-rows="checked ? totalRowsOcult : checkedAll ? ficticyAllRows : totalRows"
            :per-page="eventsPerPage.selected"
            align="center"
            size="md"
            class="my-0"
          />
        </b-col>
        <b-modal
          ref="addComments"
          cell="row"
          title="Adicionar observações"
          ok-only
          no-close-on-backdrop
          @ok="sendCommentsToBack"
        >
          <label for="textarea-default">Observação</label>
          <b-form-textarea
            id="textarea-default"
            v-model="commentsToEvents.observacao"
            placeholder="Textarea"
            rows="3"
          />
        </b-modal>

      </b-row>
    </b-card>
  </div>
</template>

<script>

// Importando dependencia do bootstrap
import {
  BBadge,
  BFormTextarea,
  BRow,
  BCol,
  BTable,
  BPagination,
  BAvatar,
  BFormCheckbox,
  BCard,
  BButton,
} from 'bootstrap-vue'

// Importando dependencias do Vue
import vSelect from 'vue-select'

import { mapState } from 'vuex'
// Importando mecanismo de fazer requisições
import axios from '@/../axios-auth'

export default {
  components: {
    BBadge,
    BFormTextarea,
    BRow,
    BCol,
    BTable,
    BPagination,
    BAvatar,
    BFormCheckbox,
    BCard,
    BButton,
    vSelect,
  },
  props: {
    updateTabs: {
      type: Function,
      required: true,
    },

  },
  data() {
    return {
      comments: [],
      selectedRow: [],
      checked: false,
      checkedAll: false,
      commentsToEvents: {
        observacao: '',
        evento_id: '',
      },

      newEvento: false,
      clienteId: this.$route.params.id,
      isExecutive: localStorage.getItem('funcao'),
      sortBy: 'fixo',
      sortDesc: true,
      fields: [
        { key: 'show_details', label: 'Mostrar Detalhes' },
        { key: 'datahora', label: 'Data e Hora' },
        { key: 'nome', label: 'usuário' },
        { key: 'evento', label: 'Evento' },
      ],
      // Motivo dos eventos
      motivoEventos: {
        options: [],
        selected: null,
      },
      // Quantidade de eventos por pagina
      eventsPerPage: {
        options: [100, 200, 300],
        selected: 100,
        currentPage: 1,
      },

      // Eventos manipuláveis
      items: [],
      ficticy: [],
      totalRows: 0,
      itemsOcult: [],
      ficticyOcult: [],
      totalRowsOcult: 0,
      ficticyAll: [],
      ficticyAllRows: 0,
    }
  },
  computed: {
    ...mapState({
      updatedItems: state => {
        const { eventos } = state.eventos
        return eventos
      },
    }),
  },
  watch: {
    'motivoEventos.selected': {
      handler(n) {
        if (n) {
          if (n.length > 0) {
            const { items } = this
            const list = []
            for (let i = 0; i <= n.length - 1; i++) {
              items.map(item => {
                if (item.evento === n[i].nome) {
                  list.push(item)
                }
              })
              this.ficticy = list
              this.totalRows = list.length
            }
          } else {
            this.ficticy = this.items
          }
        }
      },
      deep: true,
      immediate: true,
    },
    updatedItems(n) {
      this.setItems(n)
    },
  },
  created() {
    this.setItems(this.$store.getters['eventos/listEvents'])
    // this.$store.getters['eventos/listEvents'].map(event => {
    //   this.comments = event.observacao
    // })

    this.getMotivoEventos()
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-danger'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.evento === '11 - ATENÇÃO' || item.evento === '18 - COMUNICAÇÃO INTERNA') { return colorClass }
    },
    setItems(items) {
      const eventos = []
      const eventosOcultados = []
      const eventosAll = []
      items.map(item => {
        item.descricao = item.descricao.replace(/\\n|\\r\\n|\\n\\r|\\r/g, '\n')
        const idsAllow = [5, 9, 20, 1]
        if (idsAllow.includes(item.motivo_evento.id)) {
          if (item.parametros_contato) {
            const {
              call_id: callId,
              cpf,
              origem,
              telefone,
            } = item.parametros_contato
            const conc = `
            <br>
            <p> ========== PARAMÊTROS CONTATO ===========
              - ID DA CHAMADA: ${callId}
              - CPF: ${cpf}
              - ORIGEM: ${origem}
              - Telefone: ${telefone}
            </p>`
            item.descricao += conc
          }
        }
        if (item.oculto !== true) {
          eventos.push({
            id: item.usuario_empresas.id,
            datahora: this.dataHora(item.created_at, false),
            id_row: item.id,
            comments: item.observacao,
            // datahora: item.created_at,
            nome: item.usuario_empresas.nome,
            evento: `${item.motivo_evento.id} - ${item.motivo_evento.nome}`,
            historico: item.descricao,
            fixo: item.fixo === false ? null : item.fixo,
            motivo_evento_id: item.motivo_evento.id,
            storeObservacao: item.motivo_evento.observacao,
            tipo: item.motivo_evento.tipo,
            categoria: item.motivo_evento.categoria,
            canal: item.canal,
            origem: item.origem,
            numero: item.numero,
          })
        } else {
          eventosOcultados.push({
            id: item.usuario_empresas.id,
            datahora: this.dataHora(item.created_at, false),
            id_row: item.id,
            comments: item.observacao,
            // datahora: item.created_at,
            nome: item.usuario_empresas.nome,
            evento: `${item.motivo_evento.id} - ${item.motivo_evento.nome}`,
            historico: item.descricao,
            oculto: item.oculto,
            motivo_evento_id: item.motivo_evento.id,
            storeObservacao: item.motivo_evento.observacao,
            tipo: item.motivo_evento.tipo,
            categoria: item.motivo_evento.categoria,
            canal: item.canal,
            origem: item.origem,
            numero: item.numero,
          })
        }
        eventosAll.push({
          id: item.usuario_empresas.id,
          datahora: this.dataHora(item.created_at, false),
          id_row: item.id,
          comments: item.observacao,
          // datahora: item.created_at,
          nome: item.usuario_empresas.nome,
          evento: `${item.motivo_evento.id} - ${item.motivo_evento.nome}`,
          historico: item.descricao,
          fixo: item.fixo === false ? null : item.fixo,
          oculto: item.oculto,
          motivo_evento_id: item.motivo_evento.id,
          storeObservacao: item.motivo_evento.observacao,
          tipo: item.motivo_evento.tipo,
          categoria: item.motivo_evento.categoria,
          canal: item.canal,
          origem: item.origem,
          numero: item.numero,
        })
      })
      this.items = eventos
      this.ficticy = eventos
      this.totalRows = eventos.length

      this.itemsOcult = eventosOcultados
      this.ficticyOcult = eventosOcultados
      this.totalRowsOcult = eventosOcultados.length

      this.ficticyAll = eventosAll
      this.ficticyAllRows = eventosAll.length
    },
    async getMotivoEventos() {
      const motivos = []
      await axios.get('api/v1/motivo_evento', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
        .then(async res => {
          const { dados } = res.data
          await dados.map(item => {
            motivos.push({
              cor: item.cor,
              created_at: item.created_at,
              grupo: item.grupo,
              id: item.id,
              nome: `${item.id} - ${item.nome}`,
              status: item.status,
            })
          })
          this.motivoEventos.options = motivos
        })
    },
    onChange() {
      const list = this.motivoEventos.selected
      if (list.length === 0) {
        this.setItems()
      } else {
        const ficticy = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= list.length - 1; i++) {
          // eslint-disable-next-line array-callback-return
          this.items.map(dt => {
            if (dt.evento === list[i].nome) {
              ficticy.unshift(dt)
            }
          })
        }
        this.items = ficticy
        this.totalRows = ficticy.length
      }
    },
    updateEvents() {
      const {
        clienteId,
        setItems,
      } = this
      axios.get(`api/v1/clientes/event/${clienteId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(async res => {
        const { eventos } = res.data
        if (eventos) {
          setItems(eventos)
        }
      })
    },
    inserirNovoEvento(evento, motivo) {
      const dadosEvento = {
        id: 1001,
        evento: `${evento.motivo_evento_id} - ${motivo}`,
        historico: evento.descricao,
        nome: 'Nome Executivo',
        datahora: this.dataHoraAtual(),
      }
      this.items.unshift(dadosEvento)
    },
    dataHoraAtual() {
      const data = new Date()
      const dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = data.getFullYear()
      const hora = data.getHours()
      const min = data.getMinutes()
      return `${dia}/${mes}/${ano} ${hora}:${min}`
    },

    async sendCommentsToBack() {
      const idComment = []
      this.selectedRow.map(item => {
        idComment.push(item.id_row)
      })

      // eslint-disable-next-line prefer-destructuring
      this.commentsToEvents.evento_id = idComment[0]
      axios.post('api/v1/eventos/add/observacao', this.commentsToEvents, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.clearModalCommentsToBack()
        this.updateTabs()
        this.$swal({
          title: 'Observação adicionada!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.clearModalCommentsToBack()
        this.updateTabs()
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro durante o cadastro!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
    },
    verificaDataEvento(dataEvento) {
      const [dia, mes, ano] = dataEvento.split('/')
      const dataFormatada = new Date(`${mes}-${dia}-${ano}`)
      const twentyFourHoursAgo = Date.now() - (24 * 60 * 60 * 1000)
      return Date.parse(dataFormatada) >= twentyFourHoursAgo
    },
    async fixarEvento(idRow, status) {
      const statusFixado = status
      let body

      if (statusFixado) {
        body = {
          fixar: false,
          evento_id: idRow,
        }
      } else {
        body = {
          fixar: true,
          evento_id: idRow,
        }
      }

      axios.post('api/v1/eventos/fixar', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'Evento alterado!',
          icon: 'success',
          text: 'Evento alterado com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro ao alterar o evento!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
      await this.updateTabs()
    },

    async ocultarEvento(idRow, status) {
      const statusOculto = status
      let body

      if (statusOculto) {
        body = {
          oculto: false,
          evento_id: idRow,
        }
      } else {
        body = {
          oculto: true,
          evento_id: idRow,
        }
      }
      axios.post('api/v1/eventos/ocultar', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'Evento Alterado!',
          icon: 'success',
          text: 'Evento alterado com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro ao alterar o evento!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
      await this.updateTabs()
    },
    clearModalCommentsToBack() {
      this.selectedRow = []
      this.commentsToEvents.observacao = ''
      this.commentsToEvents.evento_id = ''
    },
  },
}
</script>
