<template>
  <b-card v-if="permissoes">
    <div v-if="permissoes['parcelas.index'].permissao">
      <b-row>
        <b-col
          v-for="(item) in cards"
          :key="item.icon"
          sm="3"
          :style="{cursor: 'pointer'}"
          @click="itemClick(item.subtitle)"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2 mb-1"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-1">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="mb-2"
        >
          <label>Filtro por status</label>
          <v-select
            v-model="statusFilter"
            :options="optionsStatus"
            multiple
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :fields="fields"
            :items="listFiltered"
            hover
            responsive
            striped
            select-mode="single"
            per-page="15"
            :current-page="currentPage"
            :total-rows="totalRows"
          >
            <template #cell(motivo_cancelamento)="data">
              {{ data.item.motivo_cancelamento.nome }}
            </template>
            <template #cell(credor)="data">
              {{ nomeParaIdCredor(data.item.credor) }}
            </template>
            <template #cell(executivo_cobranca)="data">
              <strong>{{ fisrtLastName(data.item.executivo_cobranca.nome) }}</strong>
            </template>

            <template #cell(status)="data">
              <b-badge
                :variant="data.item.variant"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <template #cell(created_at)="data">
              {{ dataHora(data.item.created_at, true) }}
            </template>
            <template #cell(valor_nominal)="data">
              {{ valorBr(parseFloat(data.item.valor_nominal), true) }}
            </template>
            <template #cell(acoes)="data">
              <!-- Vizualizar acordo -->
              <b-avatar
                :id="`vizualizar-parcela-${data.item.id}`"
                class="mr-1 cursor-pointer"
                size="32"
                :variant="`light-warning`"
              >
                <feather-icon
                  icon="EyeIcon"
                  @click="viewAcordo(data.item, data)"
                />
                <b-tooltip
                  :target="`vizualizar-parcela-${data.item.id}`"
                  placement="top-right"
                >
                  <p class="mb-0">
                    Vizualizar Acordo
                  </p>
                </b-tooltip>
              </b-avatar>
              <!-- Atualizar parcela -->
              <b-avatar
                v-if="verifyStatusAcordo(data.item)"
                :id="`atualizar-parcela-${data.item.id}`"
                class="mr-1 cursor-pointer"
                size="32"
                :variant="`light-warning`"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  @click="abrirAtualizarParcelas(data.item)"
                />
                <b-tooltip
                  :target="`atualizar-parcela-${data.item.id}`"
                  placement="top-right"
                >
                  <p class="mb-0">
                    Atualizar Parcela
                  </p>
                </b-tooltip>
              </b-avatar>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="text-center">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :per-page="15"
            :total-rows="totalRows"
            align="center"
            size="md"
            class="my-0"
          />
        </b-col>
      </b-row>

      <!-- Modal de atualização de parcelas -->
      <b-modal
        ref="atualizarParcela"
        size="xl"
        :title="`Atualizar parcelas do Acordo: ${acordoSelected.numero_acordo}`"
        no-close-on-backdrop
      >
        <AtualizarParcela />

        <template #modal-footer>
          <b-button
            variant="outline-warning"
            :disabled="!parcelasSelected.length > 0"
            @click="atualizarParcelas"
          >
            Atualizar Parcela(s)
          </b-button>

          <b-button
            variant="outline-info"
            :disabled="!parcelasSelected.length > 0"
            @click="simularAtualizacao"
          >
            Simular Atualização
          </b-button>
        </template>
      </b-modal>

      <b-modal
        ref="showAcordo"
        size="xl"
        :title="`Acordo: ${acordoSelected.numero_acordo}`"
        no-close-on-backdrop
        @hide="podeCancelar = false"
      >
        <b-tabs>
          <b-tab>
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Acordo</span>
            </template>

            <b-row class="mb-1">
              <b-col>
                <label><strong>Nº Acordo</strong></label>
                <b-form-input
                  v-model="acordoSelected.numero_acordo"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Status</strong></label>
                <b-form-input
                  v-model="acordoSelected.status"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Valor Atualizado</strong></label>
                <b-form-input
                  :value="valorBr(parseFloat(acordoSelected.valor_nominal), true)"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Valor Original</strong></label>
                <b-form-input
                  :value="acordoSelected.valor_original ? valorBr(acordoSelected.valor_original, true) : null"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Qntº Parcelas</strong></label>
                <b-form-input
                  v-model="acordoSelected.quantidade_parcelas"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Qntº Operações</strong></label>
                <b-form-input
                  :value="operacoesAcordo.length"
                  disabled
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <label><strong>Valor da Parcela</strong></label>
                <b-form-input
                  :value="valorBr(parseFloat(acordoSelected.valor_parcela), true)"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>{{ acordoSelected.juros_porcento }}% Juros</strong></label>
                <b-form-input
                  :value="valorBr(parseFloat(acordoSelected.juros), true)"
                  disabled
                />
              </b-col>

              <b-col>
                <label for=""><strong>{{ acordoSelected.multas_porcento }}% Multas</strong></label>
                <b-form-input
                  :value="valorBr(parseFloat(acordoSelected.multas), true)"
                  disabled
                />
              </b-col>

              <b-col>
                <label for=""><strong>{{ acordoSelected.honorarios_porcento }}% Hon. Variável</strong></label>
                <b-form-input
                  :value="valorBr(parseFloat(acordoSelected.honorarios), true)"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>{{ acordoSelected.comissao_porcento }}% Hon. Fixo</strong></label>
                <b-form-input
                  :value="acordoSelected.comissao ? valorBr(parseFloat(acordoSelected.comissao), true) : ''"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>{{ acordoSelected.desconto_porcento }}% Desconto</strong></label>
                <b-form-input
                  :value="valorBr(parseFloat(acordoSelected.desconto), true)"
                  disabled
                />
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col>
                <label for=""><strong>Data Acordo</strong></label>
                <b-form-input
                  :value="acordoSelected.created_at ? dataHora(acordoSelected.created_at, true) : null"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Data Cancelamento</strong></label>
                <b-form-input
                  :value="acordoSelected.data_cancelou ? formatTimezone(acordoSelected.data_cancelou) : null"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Desc. Cancelamento</strong></label>
                <b-form-input
                  v-model="acordoSelected.descricao_cancelamento"
                  disabled
                />
              </b-col>
              <b-col>
                <label for=""><strong>Usuário Cancelou</strong></label>
                <b-form-input
                  disabled
                />
              </b-col>
              <b-col md="">
                <label for=""><strong>Situação</strong></label>
                <b-form-input
                  v-model="acordoSelected.situacao"
                  disabled
                />
              </b-col>

            </b-row>

            <b-row class="mb-1">
              <!--Motivo do cancelamento-->
              <b-col md="6">
                <label><strong>Motivo cancelamento</strong></label>
                <b-row>
                  <b-col md="6">
                    <v-select
                      v-model="reasonCanceled"
                      label="nome"
                      :options="reasonCanceledOptions"
                      :disabled="!editCancelled"
                    />
                  </b-col>
                  <b-button
                    v-if="!editCancelled"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    @click="reasonCanceled.nome && isExecutive !== 'Supervisor(a) Cobrança' ? showAlert() : editCancelled = true"
                  >
                    Inserir motivo
                  </b-button>
                  <div v-else>
                    <b-button
                      v-show="acordoSelected.status.toLowerCase() === 'cancelado'"
                      class="mr-1"
                      variant="success"
                      @click="tuggleReasonCancelled"
                    >
                      Salvar
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      @click="editCancelled = false"
                    >
                      Cancelar
                    </b-button>
                  </div>
                </b-row>
                <b-alert
                  v-height-fade.appear
                  :show="dismissCountDown"
                  dismissible
                  class="mb-0"
                  variant="danger"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                >
                  <div class="alert-body">
                    <span>Acordos tabulados não podem ser alterados... {{ dismissCountDown }}</span>
                  </div>
                </b-alert>
              </b-col>
              <!--Motivo do cancelamento-->

              <b-col
                md="6"
              >
                <b-row align-h="between">
                  <label class="ml-1"><strong>Executivo {{ isExecutive }}</strong></label>
                  <label
                    v-show="solicitarTroca"
                    class="mr-5"
                  ><strong>Motivo da Troca: </strong></label>
                </b-row>
                <b-row>
                  <b-col md="8">
                    <v-select
                      v-model="executivoCobranca"
                      label="nome"
                      :options="executivos"
                      :disabled="!editExecutiv && !solicitarTroca"
                    />
                  </b-col>
                  <b-button
                    v-show="!(isExecutive === 'Executivo de Cobrança' || isExecutive === 'Credor')"
                    v-if="!editExecutiv"
                    variant="primary"
                    @click="editExecutiv = true"
                  >
                    Trocar Executivo
                  </b-button>
                  <div v-else>
                    <b-button
                      class="mr-1"
                      variant="success"
                      @click="TrocarExecutivo"
                    >
                      Salvar
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      @click="editExecutiv = false"
                    >
                      Cancelar
                    </b-button>
                  </div>
                  <b-button
                    v-show="isExecutive === 'Executivo de Cobrança'"
                    v-if="!solicitarTroca"
                    variant="primary"
                    @click="solicitarTroca = true"
                  >
                    Solicitar Troca
                  </b-button>
                  <div v-else>
                    <b-form-input
                      v-model="motivoTroca"
                      placeholder="Informe o Motivo"
                    />
                    <b-button
                      class="mr-1"
                      variant="success"
                      @click="solicitarTrocaExecutivo"
                    >
                      Solicitar
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      @click="solicitarTroca = false"
                    >
                      Cancelar
                    </b-button>
                  </div>
                </b-row>
              </b-col>
            </b-row>

          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="ClockIcon" />
              <span>Operações</span>
            </template>
            <b-table
              :fields="operacoesFields"
              :items="operacoesAcordo"
            >
              <template #cell(data_atualizacao)="data">
                {{ data.item.data_atualizacao ? dataHora(data.item.data_atualizacao, true) : '' }}
              </template>
              <template #cell(valor_nominal)="data">
                {{ valorBr(data.item.valor_nominal, true) }}
              </template>
              <template #cell(valor_atualizado)="data">
                {{ valorBr(data.item.valor_atualizado, true) }}
              </template>
              <template #cell(data_vencimento)="data">
                {{ data.item.data_vencimento }}
              </template>
            </b-table>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Parcelas</span>
            </template>
            <b-table
              :fields="parcelasAcordoFields"
              :items="parcelasAcordo"
              striped
            >
              <template #cell(data_vencimento)="data">
                {{ americaDate(data.item.data_vencimento) }}
              </template>

              <template #cell(valor_baixado)="data">
                {{ data.item.valor_baixado ? valorBr(parseFloat(data.item.valor_baixado), true) : 'PENDENTE' }}
              </template>

              <template #cell(valor_honorario)="data">
                {{ valorBr(parseFloat(data.item.valor_honorario), true) }}
              </template>

              <template #cell(valor_juros)="data">
                {{ valorBr(parseFloat(data.item.valor_juros), true) }}
              </template>

              <template #cell(valor_multa)="data">
                {{ valorBr(parseFloat(data.item.valor_multa), true) }}
              </template>

              <template #cell(valor_nominal)="data">
                {{ valorBr(parseFloat(data.item.valor_nominal), true) }}
              </template>

              <template #cell(data_baixa)="data">
                {{ data.item.data_baixa ? americaDate(data.item.data_baixa) : '' }}
              </template>

              <template #cell(status_recebimento)="data">
                {{ data.item.status_recebimento ? data.item.status_recebimento : '' }}
              </template>

              <template #cell(status)="data">
                <b-badge
                  :variant="resolveVariantStatusParcela(data.item.status)"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <b-row>
                  <div v-if="data.item.forma_pagamento !== 'cartao_credito' && !data.item.valor_baixado">
                    <!-- Atualizar parcela -->
                    <b-col>
                      <b-avatar
                        v-if="verifyVencimentoParcela(data.item.data_vencimento) && acordoSelected.status === 'Vencido'"
                        :id="`atualizar-parcela-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="UploadCloudIcon"
                          @click="abrirAtualizarParcelas(acordoSelected)"
                        />
                        <b-tooltip
                          :target="`atualizar-parcela-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Atualizar Parcela
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>

                    <b-col>
                      <!-- Reenviar boleto -->
                      <b-avatar
                        v-if="!verifyVencimentoParcela(data.item.data_vencimento) && data.item.boleto"
                        :id="`reenviar-boleto-${data.item.id}`"
                        v-b-hover="primary"
                        class="mr-1 cursor-pointer"
                        variant="light-warning"
                        size="32"
                      >
                        <feather-icon
                          icon="SendIcon"
                          @click="reenvioBoleto(data.item)"
                        />
                        <b-tooltip
                          :target="`reenviar-boleto-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Reenviar Boleto
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>

                    <!-- Reenviar SMS -->
                    <b-col>
                      <b-avatar
                        v-if="!verifyVencimentoParcela(data.item.data_vencimento) && data.item.boleto"
                        :id="`reenviar-sms-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="MailIcon"
                          @click="sendSms(data.item)"
                        />
                        <b-tooltip
                          :target="`reenviar-sms-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Reenviar SMS
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>

                    <!-- Visualizar boleto -->
                    <b-col>
                      <b-avatar
                        v-if="!verifyVencimentoParcela(data.item.data_vencimento) && (data.item.forma_pagamento === 'boleto_pix' || data.item.forma_pagamento === 'Boleto') && data.item.boleto"
                        :id="`visualizar-boleto-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          @click="visualizarBoleto(data.item.boleto, data.item.id)"
                        />
                        <b-tooltip
                          :target="`visualizar-boleto-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Visualizar Boleto
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>

                    <!-- Gerar Boleto -->
                    <b-col>
                      <b-avatar
                        v-if="!verifyVencimentoParcela(data.item.data_vencimento) && (data.item.forma_pagamento === 'boleto_pix' || data.item.forma_pagamento === 'Boleto') && !data.item.boleto"
                        :id="`gerar-boleto-${data.item.id}`"
                        :disabled="disabledBoleto === false"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          @click="gerarBoleto(data.item, data.item.id), overlayModalPortion = true"
                        />
                        <b-tooltip
                          :target="`gerar-boleto-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Gerar Boleto
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>

                    <!-- Baixa Manual -->
                    <b-col>
                      <b-avatar
                        v-if="!data.item.valor_baixado && isFinanceiro()"
                        :id="`baixa-manual-${data.item.id}`"
                        v-b-modal.modal-baixa
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="ArrowDownCircleIcon"
                          @click="setBaixa(data.item, false)"
                        />
                        <b-tooltip
                          :target="`baixa-manual-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Baixa Manual
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                  </div>

                  <!-- {{ data.ite m.forma_pagamento }} -->
                  <div
                    v-if="data.item.forma_pagamento === 'link_credor' || data.item.forma_pagamento === 'maquineta'"
                  >
                    <b-col>
                      <b-avatar
                        :id="`reenvio-link-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="SendIcon"
                          @click="reenvioLinkCredor(data.item.forma_pagamento, maquineta=true, data.item)"
                        />
                        <b-tooltip
                          :target="`reenvio-link-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Reenviar link
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                  </div>

                  <div
                    v-if="data.item.forma_pagamento === 'link_voz'"
                  >
                    <b-col>
                      <b-avatar
                        :id="`reenvio-link-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="SendIcon"
                          @click="reenvioLinkVoz(data.item)"
                        />
                        <b-tooltip
                          :target="`reenvio-link-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Reenviar link
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                  </div>

                  <div>
                    <!-- Retirar Baixa -->
                    <b-col>
                      <b-avatar
                        v-if="data.item.valor_baixado && isFinanceiro()"
                        :id="`retirar-baixa-${data.item.id}`"
                        v-b-modal.modal-baixa
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="ArrowUpCircleIcon"
                          @click="setBaixa(data.item, true)"
                        />
                        <b-tooltip
                          :target="`retirar-baixa-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Retirar Baixa
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                    <b-col>
                      <b-avatar
                        v-if="data.item.valor_baixado && isFinanceiro()"
                        :id="`Update-baixa-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="UploadCloudIcon"
                          @click="visualizarParcelaBaixa(data.item.id)"
                        />
                        <b-tooltip
                          :target="`Update-baixa-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Update Baixa
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                    <b-col>
                      <b-avatar
                        v-if="!verifyVencimentoParcela(data.item.data_vencimento) && (data.item.forma_pagamento === 'boleto_pix' || data.item.forma_pagamento === 'Boleto') && data.item.boleto"
                        :id="`copiar-linha-digitavel-${data.item.id}`"
                        :class="`copiar-linha-digitavel-${data.item.id} mr-1 cursor-pointer`"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="CopyIcon"
                          @click="copiarLinhaDigitavel(data.item.id)"
                        />
                        <b-tooltip
                          :target="`copiar-linha-digitavel-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Copiar Linha Digitavel
                          </p>
                        </b-tooltip>
                      </b-avatar>
                      <div
                        v-if="data.item.boleto"
                        class="sr-only"
                      >
                        <input
                          :id="`copiarlinhadigitavel-${data.item.id}`"
                          type="text"
                          size="20"
                          :value="data.item.boleto.linha_digitavel"
                        >
                      </div>
                    </b-col>
                    <b-col>
                      <b-avatar
                        :id="`visualizar-parcela-${data.item.id}`"
                        class="mr-1 cursor-pointer"
                        size="32"
                        :variant="`light-warning`"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          @click="visualizarParcela(data.item)"
                        />
                        <b-tooltip
                          :target="`visualizar-parcela-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Detalhar Parcela
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                    <b-col>
                      <!-- Enviar linha digitavel Whatsapp -->
                      <b-avatar
                        v-if="!verifyVencimentoParcela(data.item.data_vencimento) && data.item.boleto"
                        :id="`enviar-linha-digitavel-boleto-${data.item.id}`"
                        v-b-hover="primary"
                        class="mr-1 cursor-pointer"
                        variant="light-warning"
                        size="32"
                      >
                        <feather-icon
                          icon="SendIcon"
                          @click="openModalChooseTelefone(data.item)"
                        />
                        <b-tooltip
                          :target="`enviar-linha-digitavel-boleto-${data.item.id}`"
                          placement="top"
                        >
                          <p class="mb-0">
                            Enviar linha digitavel Whatsapp
                          </p>
                        </b-tooltip>
                      </b-avatar>
                    </b-col>
                  </div>
                </b-row>
              </template>
            </b-table>
          </b-tab>
          <b-overlay
            :show="overlayModalPortion"
            no-wrap
          />
        </b-tabs>

        <b-row>
          <b-col>
            <b-row>
              <b-col class="text-center">
                <h4>{{ progressMessage }}</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-progress
                  :key="bar.variant"
                  animated
                  :value="bar.value"
                  :variant="bar.variant"
                  :class="'progress-bar-' + bar.variant"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <template #modal-footer="{}">
          <b-button
            v-show="acordoSelected.status.toLowerCase() === 'aguardando'
              && (isExecutive === 'Supervisor(a) Cobrança' || isFinanceiro())"
            class="mr-1"
            size="md"
            variant="outline-success"
            @click="paymentConfirm"
          >
            Confirmar Pagamento
          </b-button>
          <b-button
            class="mr-1"
            size="md"
            variant="outline-info"
            @click="exportFile"
          >
            Exportar
          </b-button>
          <b-button
            class="mr-1 primary-voz text-white"
            size="md"
            variant="voz"
            @click="demonstrativoAcordo"
          >
            Demonstrativo
          </b-button>
          <b-button
            v-show="isExecutive !== 'Credor'"
            v-if="acordoSelected.status.toLowerCase() === 'aguardando' || acordoSelected.status.toLowerCase() === 'vencido' || acordoSelected.status.toLowerCase() === 'baixa administrativa'"
            size="md"
            variant="outline-danger"
            :disabled="reasonCanceled && !reasonCanceled.id"
            @click="quebrarAcordo"
          >
            {{ acordoSelected.status.toLowerCase() === 'aguardando' ? 'Cancelar Acordo' : acordoSelected.status.toLowerCase() === 'baixa administrativa' ? 'Cancelar Acordo' : 'Quebrar acordo' }}
          </b-button>
        </template>
      </b-modal>
      <b-modal
        id="modal-baixa"
        ref="modal-baixa"
        size="sm"
        title="Baixa Manual de Parcela"
        ok-only
        :ok-title="baixa_acao ? 'Retirar' : 'Baixar'"
        no-close-on-backdrop
      >
        <b-row>
          <b-col>
            <label for="example-input">Data da Ação</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                :value="dataBaixa ? americaDate(dataBaixa) : ''"
                disabled
                type="text"
                placeholder="dd/mm/yyy"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-if="!baixa_acao"
                  v-model="dataBaixa"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>

            <div v-if="!baixa_acao">
              <label for="status-input">Forma de Pagamento</label>
              <v-select
                id="baixa_manual_forma_pagamento"
                v-model="baixaManualFormaPagamento"
                :options="baixaManualFormaPagamentoOptions"
              />
            </div>
            <!--Input de status-->
            <div v-if="!baixa_acao">
              <label for="status-input">Status recebimento</label>
              <v-select
                id="status-input"
                v-model="statusReceiviment"
                :options="statusReceivimentOptions"
              />
            </div>
            <!--Input de status-->
            <div v-if="!baixa_acao">
              <label>Baixar Parcelas em Lote ?</label>
              <b-form-checkbox
                v-model="checked"
                class="mb-1"
              >
                <span class="vs-checkbox">
                  <span
                    class="vs-checkbox--check"
                  />
                </span>
              </b-form-checkbox>
            </div>
            <div v-if="!baixa_acao">
              <div v-if="checked">
                <label>Selecione as parcelas</label>
                <v-select
                  v-model="parcelasSelecionadas"
                  multiple
                  :options="optionsParcelasAcordo.options"
                />
              </div>
            </div>
            <div v-if="!baixa_acao">
              <label>Deseja adicionar a bandeira do cartão ?</label>
              <b-form-checkbox
                v-model="checkedCartao"
                class="mb-1"
              >
                <span class="vs-checkbox">
                  <span
                    class="vs-checkbox--check"
                  />
                </span>
              </b-form-checkbox>
            </div>
            <div v-if="!baixa_acao">
              <div v-if="checkedCartao">
                <label>Selecione a bandeira</label>
                <v-select
                  v-model="bandeiraSelecionada"
                  :options="optionsBandeiras"
                />
              </div>
            </div>
            <!--input de data credor de acordo com o status -->
          </b-col>
        </b-row>
        <template #modal-footer>
          <b-button
            v-if="baixa_acao"
            variant="danger"
            @click="retirarBaixa"
          >
            Retirar a Baixa
          </b-button>
          <b-button
            v-else
            variant="success"
            @click="baixarAcordo"
          >
            Baixar
          </b-button>
        </template>
      </b-modal>
      <b-modal
        id="modal-demonstrativo"
        ref="modal-demonstrativo"
        hide-footer
        size="xl"
        title=""
      >
        <b-row>
          <b-col>
            <iframe
              id="myFrame"
              :src="urlDemonstrativo"
            />
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div v-else>
      <not-authorized />
    </div>

    <b-modal
      id="modal-view"
      ref="modal-view"
      hide-footer
      size="xl"
      title=""
    >
      <b-row id="frameRow">
        <b-col>
          <iframe
            id="myFrame"
            :src="urlPreview"
          />
        </b-col>
      </b-row>
    </b-modal>
    <!-- update Parcela -->
    <b-modal
      id="update-parcela"
      ref="update-parcela"
      size="sm"
      title="Atualizar Baixa"
      ok-only
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <label for="update-baixa-day">Nova Data Baixa</label>
          <b-input-group class="mb-1">
            <b-form-input
              id="update-baixa-day"
              :value="dataBaixaUpdate ? americaDate(dataBaixaUpdate) : ''"
              disabled
              type="text"
              placeholder="dd/mm/yyy"
              autocomplete="off"
              show-decade-nav
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="dataBaixaUpdate"
                show-decade-nav
                button-only
                size="sm"
                right
                aria-controls="update-baixa-day"
              />
            </b-input-group-append>
          </b-input-group>

          <div>
            <label for="status-input">Forma de Pagamento</label>
            <v-select
              id="baixa_manual_forma_pagamento"
              v-model="baixaManualFormaPagamentoUpdate"
              :options="baixaManualFormaPagamentoOptions"
            />
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          variant="success"
          @click="updateBaixa()"
        >
          Atualizar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-if="parcelaView"
      id="parcela-view"
      ref="parcela-view"
      hide-footer
      size="xl"
      :title="parcelaView.id"
      @hide="$refs.showAcordo.toggle()"
    >
      <b-row class="mb-1">
        <b-col md="2">
          <label for="">Nº Parcela</label>
          <b-form-input
            disabled
            :value="parcelaView.numero_parcela"
          />
        </b-col>
        <b-col>
          <label for="">Status</label>
          <b-form-input
            disabled
            :value="parcelaView.status"
          />
        </b-col>
        <b-col>
          <label for="">Vencimento Original</label>
          <b-form-input
            disabled
            :value="parcelaView.vencimento_original ? americaDate(parcelaView.vencimento_original, true) : ''"
          />
        </b-col>
        <b-col>
          <label for="">Data Credor Informou</label>
          <b-form-input
            disabled
            :value="parcelaView.data_credor_informou ? americaDate(parcelaView.data_credor_informou, true) : ''"
          />
        </b-col>
        <b-col>
          <label for="">Vencimento</label>
          <b-form-input
            disabled
            :value="parcelaView.data_vencimento ? americaDate(parcelaView.data_vencimento, true) : ''"
          />
        </b-col>
        <b-col>
          <label for="">Data Pagamento</label>
          <b-form-input
            disabled
            :value="parcelaView.data_baixa ? americaDate(parcelaView.data_baixa, true) : ''"
          />
        </b-col>

        <b-col>
          <label for="">Valor Original</label>
          <b-form-input
            disabled
            :value="parcelaView.valor_nominal_original ? valorBr(parseFloat(parcelaView.valor_nominal_original), true) : ''"
          />
        </b-col>
        <b-col>
          <label for="">Valor</label>
          <b-form-input
            disabled
            :value="parcelaView.valor_nominal ? valorBr(parseFloat(parcelaView.valor_nominal), true) : ''"
          />
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col md="2">
          <label for="">Juros</label>
          <b-form-input
            disabled
            :value="parcelaView.valor_juros ? valorBr(parseFloat(parcelaView.valor_juros), true) : ''"
          />
        </b-col>
        <b-col md="2">
          <label for="">Multa</label>
          <b-form-input
            disabled
            :value="parcelaView.valor_multa ? valorBr(parseFloat(parcelaView.valor_multa), true) : ''"
          />
        </b-col>
        <b-col md="2">
          <label for="">Remuneracao</label>
          <b-form-input
            disabled
            :value="parcelaView.valor_honorario ? valorBr(parseFloat(parcelaView.valor_honorario), true) : ''"
          />
        </b-col>
        <b-col md="2">
          <label for="">Desconto</label>
          <b-form-input
            disabled
            :value="parcelaView.valor_desconto ? valorBr(parseFloat(parcelaView.valor_desconto), true) : ''"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="parcelaViewFields"
            :items="parcelaView.operacoes_parcelas || []"
          >
            <template #cell(principal)="data">
              {{ data.item.principal ? valorBr(parseFloat(data.item.principal), true) : '' }}
            </template>
            <template #cell(juros)="data">
              {{ data.item.juros ? valorBr(parseFloat(data.item.juros), true) : '' }}
            </template>
            <template #cell(multa)="data">
              {{ data.item.multa ? valorBr(parseFloat(data.item.multa), true) : '' }}
            </template>
            <template #cell(desconto)="data">
              {{ data.item.desconto ? valorBr(parseFloat(data.item.desconto), true) : '' }}
            </template>
            <template #cell(honorarios)="data">
              {{ data.item.honorarios ? valorBr(parseFloat(data.item.honorarios), true) : '' }}
            </template>
            <template #cell(taxa_cartao)="data">
              {{ data.item.taxa_cartao ? valorBr(parseFloat(data.item.taxa_cartao), true) : '' }}
            </template>
            <template #cell(comissao)="data">
              {{ data.item.comissao ? valorBr(parseFloat(data.item.comissao), true) : '' }}
            </template>
            <template #cell(repasse)="data">
              {{ data.item.repasse ? valorBr(parseFloat(data.item.repasse), true) : '' }}
            </template>
            <template #cell(realizado)="data">
              {{ data.item.realizado ? valorBr(parseFloat(data.item.realizado), true) : '' }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="modalChooseTelefone"
      title="Selecione o número para ser enviado"
      ok-title="Enviar"
      ok-variant="primary"
      cancel-variant="danger"
      cancel-title="Cancelar"
      size="lg"
      :ok-disabled="sendLinhaDigitavel.telefone === '' || sendLinhaDigitavel.telefone === null"
      @ok="sendoToLinhaDigitavel"
    >
      <b-row class="mb-1">
        <b-col>
          <label><strong>Selecione o número: </strong></label>
          <v-select
            v-model="sendLinhaDigitavel.telefone"
            :options="telefones"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label><strong>Mensagem a ser enviada: </strong></label>
          <b-form-textarea
            v-model="sendLinhaDigitavel.mensagem"
            type="text"
            disabled
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable camelcase */
// Componentes de estilzação
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BOverlay,
  BPagination,
  BProgress,
  BRow,
  BTab,
  BTable,
  BTabs,
  BTooltip,
  VBHover,
  VBModal,
} from 'bootstrap-vue'

import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import qs from 'qs'
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
// eslint-disable-next-line no-unused-vars
import md5 from 'crypto-js/md5'
import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'
import notAuthorized from '@/views/misc/notAuthorized.vue'

import api from '@/../axios-auth'
import AtualizarParcela from './components/AtualizarParcela.vue'

const ExcelJS = require('exceljs')

dayjs.extend(dayjsBusinessDays)
// import database from 'firebase'

export default {
  components: {
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BTable,
    BModal,
    BTabs,
    BTab,
    BFormInput,
    BButton,
    BAvatar,
    BTooltip,
    notAuthorized,
    vSelect,
    BPagination,
    BBadge,
    BInputGroup,
    BFormDatepicker,
    BInputGroupAppend,
    BCard,
    BCardText,
    BProgress,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    BFormTextarea,
    AtualizarParcela,
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
    'b-modal': VBModal,
    'b-hover': VBHover,
  },
  props: {
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      overlayModalPortion: false,
      parcelasVencidas: null,
      disabledBoleto: true,
      statusReceivimentOptions: [
        { label: 'Conta Voz' },
        { label: 'Conta Credor' },
        { label: 'Acordo Voz - Pgt. na Instituição' },
        { label: 'Baixa Administrativa' }],
      statusReceiviment: '',
      isExecutive: localStorage.getItem('funcao'),

      dismissSecs: 5,
      dismissCountDown: 0,

      fields: [
        {
          key: 'created_at', label: 'Data acordo',
        },
        {
          key: 'numero_acordo', label: 'Numero acordo',
        },
        {
          key: 'valor_nominal', label: 'Valor Nominal', class: 'text-center',
        },
        {
          key: 'status', label: 'status', sortable: true,
        },
        {
          key: 'motivo_cancelamento', label: 'Motivo', class: 'text-center',
        },
        {
          key: 'credor', label: 'Credor', class: 'text-center',
        },
        {
          key: 'quantidade_parcelas', label: 'Qnt. Parcelas', class: 'text-center',
        },
        {
          key: 'executivo_cobranca', label: 'executivo',
        },
        {
          key: 'acoes', label: 'Ações',
        },
      ],
      disabledButtonCancelledReason: false,
      items: [],
      acordoSelected: {},

      executivoCobranca: {
        nome: '',
        id: '',
      },
      reasonCanceled: {
        name: '',
        id: '',
      },

      baixa_acao: '',
      operacoesAcordo: [],
      parcelasAcordoFields: [
        { key: 'numero_parcela', label: 'N° Parcela', class: 'text-center' },
        { key: 'status', label: 'Status', class: 'text-center' },
        { key: 'forma_pagamento', label: 'F. Pagamento', class: 'text-center' },
        { key: 'data_vencimento', label: 'Data Vencimento', class: 'text-center' },
        { key: 'valor_nominal', label: 'Nominal', class: 'text-center' },
        { key: 'data_baixa', label: 'Data Baixa' },
        { key: 'valor_baixado', label: 'Vl. Baixado', class: 'text-center' },
        { key: 'status_recebimento', label: 'Baixa', class: 'text-center' },
        { key: 'actions', label: 'Ações', class: 'text-center' },
      ],
      operacoesFields: [
        { key: 'numero_operacao', label: 'Nº da Operação' },
        { key: 'aluno', label: 'Aluno' },
        { key: 'data_vencimento', label: 'Data Vencimento' },
        { key: 'data_atualizacao', label: 'Data Atualização' },
        { key: 'valor_nominal', label: 'Valor Nominal' },
        { key: 'valor_atualizado', label: 'Valor Atualizado' },
        { key: 'status_operacao.nome', label: 'Status' },
      ],

      podeCancelar: false,
      permissoes: null,

      editExecutiv: false,
      editCancelled: false,
      currentPage: 1,

      solicitarTroca: false,
      motivoTroca: '',

      executivos: [],
      reasonCanceledOptions: [],

      dataBaixa: null,
      dataBaixaUpdate: null,
      parcelaSelectedToUpdate: null,
      parcelaSelected: {},
      parcelaView: {},
      parcelaViewFields: [
        { key: 'numero_operacao', label: 'Nº Operação' },
        { key: 'principal', label: 'Principal' },
        { key: 'juros', label: 'Juros' },
        { key: 'multa', label: 'Multa' },
        { key: 'desconto', label: 'Desconto' },
        { key: 'honorarios', label: 'Hon. Variável' },
        { key: 'taxa_cartao', label: 'Taxa Cartão' },
        { key: 'comissao', label: 'Hon. Fixo' },
        { key: 'repasse', label: 'Repasse' },
        { key: 'realizado', label: 'Realizado' },
      ],

      urlDemonstrativo: '',
      urlPreview: '',
      dateFormat: {
        date: true,
        delimiter: '/',
        datePattern: ['d', 'm', 'Y'],
      },
      novoVencArray: [],
      atualizarParcParam: {
        juros: 1,
        multa: 2,
        honorario: 0,
        desconto: 0,
      },
      totals: {
        totalNominal: 0,
        totalJuros: 0,
        totalMulta: 0,
        totalDesconto: 0,
        totalHonorarios: 0,
      },
      id: this.$route.params.id,
      dataInfoClient: [],
      dataExport: [],
      bar: { variant: 'success', value: 0 },
      progressMessage: '',
      statusFilter: [],
      cards: [],
      countStatusAcordos: {
        baixaAdministrativa: 0,
        vencido: 0,
        cancelado: 0,
        liquidado: 0,
        negociado: 0,
        devolvido: 0,
        aguardando: 0,
        quebrado: 0,
      },
      checked: false,
      optionsParcelasAcordo: {
        options: [
          {
            label: '',
            id: null,
          },
        ],
      },
      parcelasSelecionadas: [],
      bandeiraSelecionada: null,
      checkedCartao: false,
      optionsBandeiras: [
        'MASTERCARD',
        'VISA',
        'ELO',
        'AMERICAN EXPRESS',
        'HIPERCARD',
      ],
      prazoCompensacao: 0,
      prazoCompensacaoPadrao: 5,
      telefones: [],
      sendLinhaDigitavel: {
        telefone: '',
        cliente: '',
        mensagem: '',
      },
      baixaManualFormaPagamento: '',
      baixaManualFormaPagamentoUpdate: '',
      baixaManualFormaPagamentoOptions: [
        { key: 'Boleto', label: 'Boleto' },
        { key: 'link_voz', label: 'Link Voz' },
        { key: 'link_credor', label: 'Link Credor' },
        { key: 'maquineta', label: 'Maquineta' },
        { key: 'pix', label: 'Pix' },
        { key: 'Credor', label: 'Credor' },

      ],
    }
  },
  computed: {
    ...mapState({
      operacoes: state => {
        const { operacoes } = state.negociacao
        return operacoes
      },
      parcelasAcordo: state => state.negociacao.parcelasAcordo,
      parcelasSelected: state => state.negociacao.parcelasSelected,
      acordos: state => state.negociacao.acordos,
      infoCliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
      credores: state => {
        const { credores } = state.negociacao
        return credores
      },
      emailsCliente: state => {
        const { emails } = state.negociacao
        return emails
      },
    }),
    isExecutivo() {
      const { executivo_id: executivo_cobranca_id } = localStorage
      // eslint-disable-next-line camelcase
      if (!executivo_cobranca_id || executivo_cobranca_id === 'false') {
        return false
      }
      return true
    },
    optionsStatus() {
      const { items } = this
      const status = []

      items.map(item => {
        item.status ? status.push(item.status) : null
      })

      const itemsFiltered = [...new Set(status)]

      return itemsFiltered
    },
    listFiltered() {
      const { items, statusFilter: status } = this
      let itemsList = items

      if (status.length > 0) {
        itemsList = []

        for (let i = 0; i <= status.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          items.map(item => {
            if (item.status === status[i]) {
              itemsList.push(item)
            }
          })
        }
      }

      itemsList.sort((a, b) => {
        if (a.created_at < b.created_at) {
          return 1
        }
        if (a.created_at > b.created_at) {
          return -1
        }
        return 0
      })
      this.totalRows = itemsList.length
      return itemsList
    },
  },
  watch: {
    acordos(n) {
      this.setItems(n)
    },
  },
  async created() {
    await this.setItems(this.acordos)
    const { permissoes } = localStorage
    this.permissoes = JSON.parse(permissoes).parcelas
    this.setCards()
    // await this.getInfoCliente(this.id)
    // await this.getDataReportExportedExcel()
  },
  methods: {
    nomeParaIdCredor(item) {
      const credor = []
      const credorId = item[0]?.credor_id
      this.credores.map(i => {
        if (credorId === i.id) {
          credor.push(i)
        }
      })
      return credor[0]?.nome
    },
    async getDataReportExportedExcel(id) {
      await api.get(`api/v1/acordo/negociacao/export/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.dataExport = res.data.dados.totais
      })
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const { dataExport } = this
      const workbook = new ExcelJS.Workbook()
      const worksheet1 = workbook.addWorksheet('Acordo')
      const worksheet2 = workbook.addWorksheet('Parcelas')
      const worksheet3 = workbook.addWorksheet('Operações')

      const itemsAgreements = dataExport.acordos
      const itemsInstallments = dataExport.parcelas
      const itemsOperations = dataExport.operacoes
      const { cliente } = dataExport.acordos[0]
      const { instituicao } = dataExport.acordos[0]

      worksheet1.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'CPF/CNPJ' },
        { header: 'Acordo' },
        { header: 'Data da transação' },
        { header: 'Condição' },
        { header: 'Qtd. Parcelas' },
        { header: 'Qtd. Operações' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multas' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
      ]

      worksheet2.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'CPF/CNPJ' },
        { header: 'Acordo' },
        { header: 'Liquidação' },
        { header: 'Parcela' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Forma' },
      ]

      worksheet3.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'Aluno' },
        { header: 'CPF/CNPJ' },
        { header: 'Acordo' },
        { header: 'Nº Operação' },
        { header: 'Origem' },
        { header: 'Data Vencimento', key: 'data_vencimento' },
        { header: 'Data da transação' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'valor_juros' },
        { header: 'Multa', key: 'valor_multa' },
        { header: 'Desconto', key: 'valor_desconto' },
        { header: 'Hon. Variável', key: 'valor_honorario' },
        { header: 'Realizado', key: 'valor_atualizado' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Forma' },
      ]

      const wk1Columns1 = [
        'principal',
        'juros',
        'multas',
        'desconto',
        'honorarios',
        'realizado',
        'taxa_cartao',
        'comissao',
        'repasse',
      ]
      const wk2Columns2 = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
        'taxa_cartao',
        'comissao',
        'repasse',
      ]
      const wk3Columns3 = [
        'principal',
        'valor_juros',
        'valor_multa',
        'valor_desconto',
        'valor_honorario',
        'valor_atualizado',
        'taxa_cartao',
        'comissao',
        'repasse',
      ]
      const totais1 = {
        wk1: {},
        wk2: {},
        wk3: {},
      }

      itemsAgreements.forEach(agreement => {
        worksheet1.addRow([
          agreement.instituicao,
          agreement.cliente,
          agreement.documento,
          agreement.id,
          agreement.data_processamento_cartao ? this.formatTimezone(agreement.data_processamento_cartao) : null,
          agreement.quantidade_parcelas > 1 ? 'PARCELADO' : 'A VISTA',
          agreement.quantidade_parcelas,
          agreement.quantidade_operacoes,
          parseFloat(agreement.principal),
          parseFloat(agreement.juros),
          parseFloat(agreement.multas),
          parseFloat(agreement.desconto),
          parseFloat(agreement.honorarios),
          parseFloat(agreement.realizado),
          agreement.taxa_cartao ? parseFloat(agreement.taxa_cartao) : 0,
          agreement.comissao ? parseFloat(agreement.comissao) : 0,
          parseFloat(agreement.repasse),
        ])
        wk1Columns1.map(w => {
          totais1.wk1[w] ? totais1.wk1[w] += parseFloat(agreement[w]) : totais1.wk1[w] = parseFloat(agreement[w])
        })
      })

      itemsInstallments.map(installments => {
        worksheet2.addRow([
          installments.instituicao,
          installments.cliente,
          installments.documento,
          installments.id,
          installments.data_vencimento ? this.formatTimezone(installments.data_vencimento) : null,
          installments.parcela,
          parseFloat(installments.principal),
          parseFloat(installments.juros),
          parseFloat(installments.multa),
          parseFloat(installments.desconto),
          parseFloat(installments.honorarios),
          parseFloat(installments.realizado),
          parseFloat(installments.taxa_cartao),
          parseFloat(installments.comissao),
          parseFloat(installments.repasse),
          installments.forma_pagamento,
        ])
        wk2Columns2.map(w => {
          totais1.wk2[w] ? totais1.wk2[w] += parseFloat(installments[w]) : totais1.wk2[w] = parseFloat(installments[w])
        })
      })

      itemsOperations.map(operations => {
        worksheet3.addRow([
          operations.instituicao,
          operations.cliente,
          operations.aluno,
          operations.documento,
          operations.id,
          operations.numero_operacao,
          operations.origem,
          operations.data_vencimento ? this.formatTimezone(operations.data_vencimento) : null,
          operations.data_processamento_cartao ? this.americaDate(operations.data_processamento_cartao) : null,
          parseFloat(operations.principal),
          parseFloat(operations.valor_juros),
          parseFloat(operations.valor_multa),
          parseFloat(operations.valor_desconto),
          parseFloat(operations.valor_honorario),
          parseFloat(operations.valor_atualizado),
          parseFloat(operations.taxa_cartao),
          parseFloat(operations.comissao),
          parseFloat(operations.repasse),
          operations.forma,
        ])
        wk3Columns3.map(w => {
          totais1.wk3[w] ? totais1.wk3[w] += parseFloat(operations[w]) : totais1.wk3[w] = parseFloat(operations[w])
        })
      })
      wk1Columns1.map(key => {
        worksheet1.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })
      const totalRowWk1 = worksheet1.addRow({
        principal: totais1.wk1.principal,
        juros: totais1.wk1.juros,
        multas: totais1.wk1.multas,
        desconto: totais1.wk1.desconto,
        honorarios: totais1.wk1.honorarios,
        realizado: totais1.wk1.realizado,
        taxa_cartao: totais1.wk1.taxa_cartao,
        comissao: totais1.wk1.comissao,
        repasse: totais1.wk1.repasse,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })

      worksheet1.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk2 = worksheet2.addRow({
        principal: totais1.wk2.principal,
        juros: totais1.wk2.juros,
        multa: totais1.wk2.multa,
        desconto: totais1.wk2.desconto,
        honorarios: totais1.wk2.honorarios,
        realizado: totais1.wk2.realizado,
        taxa_cartao: totais1.wk2.taxa_cartao,
        comissao: totais1.wk2.comissao,
        repasse: totais1.wk2.repasse,
      })
      totalRowWk2.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk2.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      worksheet2.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      wk2Columns2.map(key => {
        worksheet2.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })
      // operacao
      const totalRowWk3 = worksheet3.addRow({
        principal: totais1.wk3.principal,
        valor_juros: totais1.wk3.valor_juros,
        valor_multa: totais1.wk3.valor_multa,
        valor_desconto: totais1.wk3.valor_desconto,
        valor_honorario: totais1.wk3.valor_honorario,
        valor_atualizado: totais1.wk3.valor_atualizado,
        taxa_cartao: totais1.wk3.taxa_cartao,
        comissao: totais1.wk3.comissao,
        repasse: totais1.wk3.repasse,
      })
      totalRowWk3.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk3.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      worksheet3.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      wk3Columns3.map(key => {
        worksheet3.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet1.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      worksheet2.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      worksheet3.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], `${cliente} - ${instituicao} - NEGOCIAÇÃO VIA CARTÃO DE CRÉDITO`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },

    hideModal() {
      this.$refs.showAcordo.hide()
    },
    clearModals() {
      this.$refs.atualizarParcela.hide()
      this.$refs.showAcordo.hide()
      this.$refs['parcela-view'].hide()
      this.$refs['modal-demonstrativo'].hide()
      this.$refs['modal-view'].hide()
      this.$refs['modal-baixa'].hide()
    },
    async simularAtualizacao() {
      const {
        parcelasSelected,
      } = this

      const { credor: cd } = this.acordoSelected
      // eslint-disable-next-line no-unused-vars
      const { credor_id: credorId } = cd[0]
      const credor = this.credores.filter(e => e.id === credorId)[0]

      this.$store.commit('negociacao/setAttParcelaProgess', { message: 'SIMULANDO PARCELAS', value: 30 })

      await Promise.all(parcelasSelected.map(async parcela => {
        const bodyEvento = {
          descricao: `=========== ATUALIZAÇÃO DE PARCELA Nº ACORDO - ${this.acordoSelected.numero_acordo}=========== <br/>
          <strong>Nr. PARCELA </strong>: ${parcela.numero_parcela} <br/>
          <strong> </strong>: ${this.americaDate(parcela.vencimento_original)} <br/>
          <strong>VALOR NOMINAL </strong>: ${this.valorBr(parseFloat(parcela.valor_nominal_original), true)}<br/>
          <strong>-JUROS </strong>: ${parcela.parametro.juros}% ${this.valorBr(parseFloat(parcela.juros_atualizado), true)} <br/>
          <strong>-MULTA </strong>: ${parcela.parametro.multa}% ${this.valorBr(parseFloat(parcela.multa_atualizado), true)} <br/>
          <strong>-HONORÁRIO </strong>: ${parcela.parametro.honorario}% ${this.valorBr(parseFloat(parcela.honorario_atualizado))} <br/>
          <strong>-DESCONTOS </strong>: ${parcela.parametro.desconto}% ${this.valorBr(parseFloat(parcela.desconto_atualizado))} <br/>
          <strong>VENCIMENTO ATUALIZADO </strong>: ${this.americaDate(parcela.newVencimento)} <br/>
          <strong>VALOR ATUALIZADO </strong>: ${this.valorBr(parseFloat(parcela.newNominal), true)} <br/>`,
          cliente_id: this.$route.params.id,
          // eslint-disable-next-line radix
          usuario_empresas_id: parseInt(localStorage.getItem('userId')),
          motivo_evento_id: 20,
          credores_id: credor.id,
          parametros_contato: '',
        }
        await api.post('api/v1/eventos/store', bodyEvento)
      }))
      this.$store.commit('negociacao/setAttParcelaProgess', { message: 'ATUALIZANDO DADOS DO CLIENTE', value: 80 })
      await this.updateTabs()
      this.$store.commit('negociacao/setAttParcelaProgess', { message: 'EVENTO DE SIMULAÇÃO CONCLUÍDO', value: 100 })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon: 'BellIcon',
          text: 'Simulação concluída',
          variant: 'success',
        },
      })
      setTimeout(() => this.$store.commit('negociacao/setAttParcelaProgess', { message: '', value: 0 }))
    },
    setItems(items) {
      const acordos = []
      const ordering = [
        'baixa administrativa',
        'vencido',
        'cancelado',
        'liquidado',
        'negociado',
        'devolvido',
        'aguardando',
        'quebrado',
      ]
      ordering.map(key => {
        items.map(acordo => {
          const item = acordo
          if (item.status.toLowerCase() === key) {
            item.quantidade_parcelas === '1' ? item.quantidade_parcelas = 'AVISTA' : item.quantidade_parcelas
            item.executivo_cobranca.cpf = this.cpfLabel(item.executivo_cobranca.cpf)

            if (item.status) {
              switch (item.status.toLowerCase()) {
                case 'liquidado':
                  item.variant = 'success'
                  break
                case 'negociado':
                  item.variant = 'primary'
                  break
                case 'cancelado':
                  item.variant = 'danger'
                  break
                case 'aguardando':
                  item.variant = 'warning'
                  break
                case 'vencido':
                  item.variant = 'secondary'
                  break
                case 'baixa administrativa':
                  item.variant = 'info'
                  break
                case 'quebrado':
                  item.variant = 'dark'
                  break
                default:
                  item.variant = ''
              }
            }
            // condição para contar a quantidade de acordos pelo status
            if (item.status) {
              switch (item.status.toLowerCase()) {
                case 'liquidado':
                  this.countStatusAcordos.liquidado += 1
                  break
                case 'negociado':
                  this.countStatusAcordos.negociado += 1
                  break
                case 'cancelado':
                  this.countStatusAcordos.cancelado += 1
                  break
                case 'aguardando':
                  this.countStatusAcordos.aguardando += 1
                  break
                case 'vencido':
                  this.countStatusAcordos.vencido += 1
                  break
                case 'baixa administrativa':
                  this.countStatusAcordos.baixaAdministrativa += 1
                  break
                case 'devolvido':
                  this.countStatusAcordos.devolvido += 1
                  break
                case 'quebrado':
                  this.countStatusAcordos.quebrado += 1
                  break
                default:
                  break
              }
            }
            // item.parcelas.map(parcela => {
            //   this.verifyVencimentoParcela(parcela.data_vencimento) ? parcela.status = 'expired' : 'avaible'
            // })
            acordos.unshift(item)
          }
        })
      })
      this.items = acordos
    },
    getAcordoById(item) {
      this.items.map(acordo => {
        if (acordo.id === item.id) {
          this.viewAcordo(acordo)
        }
      })
    },
    async viewAcordo(item) {
      this.getDataReportExportedExcel(item.id)
      this.getExecutivos()
      const acordo = item
      this.executivoCobranca = item.executivo_cobranca
      this.reasonCanceled = item.motivo_cancelamento

      const operacoesAcordo = []
      item.operacoes.map(operacao => {
        this.operacoes.map(op => {
          if (parseInt(op.id) === parseInt(operacao.id)) {
            operacoesAcordo.unshift(op)
          }
        })
      })

      let status = []
      item.parcelas.map(parcela => {
        status.push(parcela.status)
      })
      status = [...new Set(status)]
      if (status.length === 1) {
        if (status[0] === 'avaible') {
          this.podeCancelar = false
        } else {
          this.podeCancelar = true
        }
      } if (status.length > 1) {
        this.podeCancelar = true
      } if (status.length === 0) {
        this.podeCancelar = false
      }

      this.acordoSelected = acordo

      this.operacoesAcordo = operacoesAcordo
      this.$store.commit('negociacao/setParcelasAcordo', item.parcelas)
      // Formatação da data do acordo
      operacoesAcordo.map(op => {
        op.created_at = this.acordoSelected.created_at
      })
      this.acordoSelected.created_at = operacoesAcordo[0].created_at
      // Formatação da data de cancelamento
      operacoesAcordo.map(op => {
        op.date_canceled = this.acordoSelected.data_cancelou
      })
      this.acordoSelected.data_cancelou = operacoesAcordo[0].date_canceled
      // Formatação da data de cancelamento

      this.acordoSelected.valor_original = operacoesAcordo.reduce((partialSum, a) => partialSum + parseFloat(a.valor_nominal), 0)
      await this.getPrazoCompensacao(this.acordoSelected.parcelas[0].id)
      this.getReasonCanceled()
      this.$refs.showAcordo.show()
    },
    async visualizarBoleto(boleto) {
      // const { usuarioEmpresas } = localStorage
      const data = boleto
      api.post('api/v1/boletos/viewBoleto', {
        boleto: data.id,
      }, {
        headers: {
          Accept: 'application/pdf',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
        responseType: 'arraybuffer',
      }).then(res => {
        const file = new File([res.data], 'boleto.pdf', {
          type: 'application/pdf',
        })
        const url = URL.createObjectURL(file)
        this.urlPreview = url
        this.$refs['modal-view'].show()
      })
    },
    visualizarParcelaBaixa(parcelaId) {
      this.parcelaSelectedToUpdate = null
      this.parcelaSelectedToUpdate = parcelaId
      this.$refs['update-parcela'].show()
    },
    errorBaixaUpdate() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon: 'BellIcon',
          text: 'Preencha pelo menos um campo',
          variant: 'danger',
        },
      })
    },
    async updateBaixa() {
      if (this.dataBaixaUpdate === null && this.baixaManualFormaPagamentoUpdate.length === 0) {
        this.errorBaixaUpdate()
        return
      }

      await api
        .put(`api/v1/parcelas/atualizar_baixa/${this.parcelaSelectedToUpdate}`, {
          data_baixa: this.dataBaixaUpdate,
          forma_pagamento: this.baixaManualFormaPagamentoUpdate.key,
        }).then(() => {
          this.updateTabs()
          this.$refs['update-parcela'].hide()
          this.hideModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'BellIcon',
              text: 'Baixa realizada com sucesso',
              variant: 'success',
            },
          })
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'BellIcon',
              text: 'Erro ao realizar baixa',
              variant: 'danger',
            },
          })
        })
    },
    async quebrarAcordo() {
      this.$swal({
        title: 'Você tem certeza?',
        text: 'Essa ação é incapaz de ser desfeita!!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const { reasonCanceled } = this
          await api.post(`api/v1/acordo/quebra_acordo/${this.acordoSelected.id}`, {
            motivo_cancela_acordo_id: reasonCanceled.id,
          }).catch(() => {
            this.$swal({
              title: 'Algo de errado ocorreu!',
              text: 'Contate equipe de desenvolvimento',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          this.progressMessage = 'Buscando dados do Cliente'
          this.bar.value = 75
          await this.updateTabs()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Ação realizado com sucesso!',
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
          this.progressMessage = 'Ação concluída'
          this.bar.value = 100
          setTimeout(() => {
            this.clearModals()
            this.editCancelled = false
            this.progressMessage = ''
            this.bar.value = 0
          }, 1000)
        }
      })
    },
    abrirAtualizarParcelas(item) {
      this.acordoSelected = item

      // eslint-disable-next-line consistent-return
      const parcelasAcordo = item.parcelas.map(p => {
        if (!p.data_baixa) {
          return { ...p }
        }
      }).filter(Boolean)
      this.parcelasVencidas = parcelasAcordo
      this.$store.commit('negociacao/setParcelasToUpdate', parcelasAcordo)
      this.$refs.atualizarParcela.show()
    },
    verifyVencimentoParcela(item) {
      if (item) {
        const splitDate = item.split('-')
        if (splitDate.length === 3) {
          // 2021-11-09
          const currentDate = new Date(new Date().setHours(0, 0, 0, 0))
          const dataVencimento = new Date(`${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`)
          if (dataVencimento.getTime() < currentDate.getTime()) {
            return true
          }
          return false
        }
      }
      return false
    },
    verifyStatusAcordo(item) {
      const statusPermitidos = ['Negociado', 'Vencido']
      return item.quantidade_parcelas !== 'AVISTA' && statusPermitidos.includes(item.status)
    },
    atualizarParcelas() {
      const {
        parcelasSelected,
        parcelasVencidas,
      } = this

      this.$swal({
        title: 'Atualizar Parcela(s)?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await Promise.all(parcelasSelected.map(async parcela => {
            this.$store.commit('negociacao/setAttParcelaProgess', { message: `ATUALIZANDO  PARCELAS 1/${parcelasSelected.length}`, value: 75 / parcelasSelected.length })
            const {
              juros_atualizado,
              multa_atualizado,
              honorario_atualizado,
              desconto_atualizado,
              newVencimento,
              newNominal,
            } = parcela
            const getValorOperacaoParcela = (valor, index, qnt) => {
              const rat = (parseFloat(valor) / qnt).toFixed(2)
              if (index + 1 === qnt) {
                const total = parseFloat(rat) * qnt
                if (total === parseFloat(valor)) {
                  return rat
                }
                const diff = parseFloat(valor) - total
                return (parseFloat(rat) + diff).toFixed(2)
              }
              return rat
            }
            let acumuladoRealizado = 0
            const getValorRealizado = (principal, juros, multa, honorarios, desconto, index, quantidade, nominal) => {
              const total = principal + juros + multa + honorarios - desconto
              if (index + 1 === quantidade) {
                const totalRealizadoAtual = (total + acumuladoRealizado).toFixed(2)
                if (totalRealizadoAtual !== nominal) {
                  const diferenca = (nominal - parseFloat(totalRealizadoAtual)).toFixed(2)
                  return total + parseFloat(diferenca)
                }
              }
              return total
            }
            const operacoesParcelas = parcela.operacoes_parcelas.map((po, index) => {
              let juros = parseFloat(getValorOperacaoParcela(juros_atualizado, index, parcela.operacoes_parcelas.length)) + parseFloat(po.juros)
              const multa = parseFloat(getValorOperacaoParcela(multa_atualizado, index, parcela.operacoes_parcelas.length)) + parseFloat(po.multa)
              let honorarios = parseFloat(getValorOperacaoParcela(honorario_atualizado, index, parcela.operacoes_parcelas.length)) + parseFloat(po.honorarios)
              const desconto = parseFloat(getValorOperacaoParcela(desconto_atualizado, index, parcela.operacoes_parcelas.length)) + parseFloat(po.desconto)
              const realizado = getValorRealizado(parseFloat(po.principal), parseFloat(juros), parseFloat(multa), parseFloat(honorarios), parseFloat(desconto), index, parcela.operacoes_parcelas.length, newNominal)
              const repasse = parseFloat(realizado) - parseFloat(honorarios)

              if (parseFloat(po.comissao) > 0 && parseFloat(honorarios) > 0) {
                juros = parseFloat(juros) + parseFloat(honorarios)
                honorarios = 0
              }

              acumuladoRealizado += parseFloat(realizado)

              return {
                id: po.id,
                juros: juros.toFixed(2),
                multa: multa.toFixed(2),
                desconto: desconto.toFixed(2),
                honorarios: honorarios.toFixed(2),
                vencimento: newVencimento,
                realizado: realizado.toFixed(2),
                repasse: repasse.toFixed(2),
              }
            })

            const bodyParcela = {
              valor_nominal: newNominal,
              data_vencimento: newVencimento,
              valor_juros: parcela.juros_atualizado,
              valor_multa: parcela.multa_atualizado,
              valor_honorario: parcela.honorario_atualizado,
              valor_desconto: parcela.desconto_atualizado,
              operacoesParcelas,
            }

            const { credor: cd } = this.acordoSelected
            // eslint-disable-next-line no-unused-vars
            const { credor_id: credorId } = cd[0]
            const credor = this.credores.filter(e => e.id === credorId)[0]

            // eslint-disable-next-line no-unused-vars
            const bodyEvento = {
              descricao: `=========== ATUALIZAÇÃO DE PARCELA Nº ACORDO - ${this.acordoSelected.numero_acordo}=========== <br />
              <strong>Nr. PARCELA: </strong> ${parcela.numero_parcela} <br />
              <strong>: </strong> ${this.americaDate(parcela.vencimento_original)} <br />
              <strong>VALOR NOMINAL: </strong> ${this.valorBr(parseFloat(parcela.valor_nominal_original), true)} <br />
              <strong>-JUROS: </strong> ${parcela.parametro.juros}% ${this.valorBr(parseFloat(parcela.juros_atualizado), true)} <br />
              <strong>-MULTA: </strong> ${parcela.parametro.multa}% ${this.valorBr(parseFloat(parcela.multa_atualizado), true)} <br />
              <strong>-HONORÁRIO: </strong> ${parcela.parametro.honorario}% ${this.valorBr(parseFloat(parcela.honorario_atualizado))} <br />
              <strong>-DESCONTOS: </strong> ${parcela.parametro.desconto}% ${this.valorBr(parseFloat(parcela.desconto_atualizado))} <br />
              <strong>VENCIMENTO ATUALIZADO: </strong> ${this.americaDate(newVencimento)} <br />
              <strong>VALOR ATUALIZADO: </strong> ${this.valorBr(parseFloat(newNominal), true)} <br />
              <strong>ENVIADO PARA OS E-MAILS: </strong> ${this.emailsCliente.map(email => `${email}, `)} <br />`,
              cliente_id: this.$route.params.id,
              // eslint-disable-next-line radix
              usuario_empresas_id: parseInt(localStorage.getItem('userId')),
              motivo_evento_id: 6,
              credores_id: credor.id,
              parametros_contato: null,
            }

            await api.put(`api/v1/parcelas/update/${parcela.id}`, bodyParcela)
            this.$store.commit('negociacao/setAttParcelaProgess', { message: 'GERANDO BOLETO', value: 75 / parcelasSelected.length })
            await this.gerarBoleto(parcela, parcela.id, parcela.newVencimento, parcela.newNominal)

            await api.post('api/v1/eventos/store', bodyEvento)

            if (parcelasSelected.length === parcelasVencidas.length) {
              await api.put(`api/v1/acordo/update/${this.acordoSelected.id}`, {
                status: 'Negociado',
              })
            }
          }))
          this.$store.commit('negociacao/setAttParcelaProgess', { message: 'ATUALIZANDO DADOS DO CLIENTE', value: 80 })
          await this.updateTabs()
          this.$store.commit('negociacao/setAttParcelaProgess', { message: 'ATUALIZAÇÃO DE PARCELAS CONCLUÍDA', value: 100 })
          setTimeout(() => this.clearModals(), 1000)
        }
      })
    },
    getExecutivos() {
      api.get('api/v1/executivo', {
        headers: {
          Accept: 'application/pdf',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      }).then(res => {
        const executivos = []
        res.data.map(executivo => {
          executivos.push(executivo)
        })
        this.executivos = executivos
      })
    },

    async getReasonCanceled() {
      this.reasonCanceledOptions = []

      const dataHoje = dayjs()
      const vencimentoPrimeiraParcela = dayjs(this.acordoSelected.parcelas[0].data_vencimento)

      const res = await api.get('api/v1/motivo/cancela/acordo', {
        headers: {
          Accept: 'application/pdf',
          Authorization: `Bearer ${localStorage.token}`,
        },
      })

      const { dados } = res.data
      if (this.acordoSelected.status === 'Aguardando' && this.isExecutive === 'Executivo de Cobrança') {
        if (dataHoje.isBefore(vencimentoPrimeiraParcela, 'day') || dataHoje.isSame(vencimentoPrimeiraParcela, 'day')) {
          dados.forEach(reason => {
            if (reason.id !== 5) {
              this.reasonCanceledOptions.push(reason)
            }
          })
        } else {
          const prazo = this.acordoSelected.parcelas[0].forma_pagamento === 'boleto_pix' ? this.prazoCompensacao : this.prazoCompensacaoPadrao
          const dataLimiteCompensacao = vencimentoPrimeiraParcela.add(prazo, 'day')

          if (dataHoje.isBefore(dataLimiteCompensacao, 'day')) {
            dados.forEach(reason => {
              if (reason.id === 5) {
                this.reasonCanceledOptions.push(reason)
              }
            })
          }
        }
      } else {
        dados.forEach(reason => {
          this.reasonCanceledOptions.push(reason)
        })
      }
    },

    async TrocarExecutivo() {
      const { executivoCobranca, acordoSelected } = this
      api.post(`api/v1/executivo/trocarExecutivo/${acordoSelected.id}`, {
        executivo_cobranca_id: executivoCobranca.id,
      }, {
        headers: {
          Accept: 'application/pdf',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificação',
            icon: 'BellIcon',
            text: 'Alterado executivo do acordo!',
            variant: 'success',
          },
        }, {
          position: 'top-center',
        })
        this.clearModals()
        this.updateTabs()
        this.editExecutiv = false
      })
    },

    // Troca o motivo do cancelamento
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    async tuggleReasonCancelled() {
      const { acordoSelected, reasonCanceled } = this
      api.post('api/v1/acordo/motivo/cancela/acordo/', {
        acordo_id: acordoSelected.id,
        motivo_cancela_acordo_id: reasonCanceled.id,
      }, {
        headers: {
          Accept: 'application/pdf',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      }).then(() => {
        this.clearModals()
        this.updateTabs()
        this.editCancelled = false
      })
    },
    // Troca o motivo do cancelamento

    async reenvioBoleto(item) {
      const { boleto } = item
      if (boleto) {
        this.progressMessage = 'BUSCANDO EMAILS'
        this.bar.value = 25
        const emails = await api.get(`api/v1/clientes/emails/${this.$route.params.id}`, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => res.data.dados.emails.map(dt => dt.email))

        // ------ Gerando PDF do Boleto --------

        // eslint-disable-next-line no-unused-vars
        this.progressMessage = 'GERANDO PDF DO BOLETO E DEMONSTRATIVO'
        this.bar.value = 50
        const file = await api.post('api/v1/boletos/viewBoleto', {
          boleto: boleto.id,
        }, {
          responseType: 'arraybuffer',
        }).then(async res => new File([res.data], 'boleto.pdf', {
          type: 'application/pdf',
        }))

        const {
          nome,
        } = this.infoCliente

        const demonstrativo = await api.post(`api/v1/acordo/viewDemoSimples/${this.acordoSelected.id}`, null, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.token),
          },
          responseType: 'arraybuffer',
        }).then(rs => new File([rs.data], 'demonstrativo.pdf', {
          type: 'application/pdf',
        }))

        this.progressMessage = 'ENVIANDO BOLETO PARA O CLIENTE'
        this.bar.value = 80

        const body = new FormData()
        body.append('name', `${this.fisrtLastName(nome)}`)
        body.append('to', JSON.stringify(emails.map(email => ({
          name: this.fisrtLastName(nome), address: email,
        }))))
        body.append('idAcordo', this.acordoSelected.id)
        body.append('boleto', file)
        body.append('demonstrativo', demonstrativo)

        const configEmail = {
          method: 'post',
          url: 'http://192.168.254.219:3000/api/v1/boleto/',
          data: body,
        }

        await api(configEmail).then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sucesso',
                icon: 'CheckIcon',
                text: 'Boleto Reenviado 🚀 📪',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
        }).catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        const { credor: cd } = this.acordoSelected
        // eslint-disable-next-line no-unused-vars
        const { credor_id: credorId } = cd[0]
        const credor = this.credores.filter(e => e.id === credorId)[0]
        const bodyEvento = {
          descricao: `=========== REENVIO DE BOLETO Nº ACORDO - ${this.acordoSelected.numero_acordo}=========== <br/>
          <strong>Nr. PARCELA </strong>: ${item.numero_parcela} <br/>
          <strong>VALOR NOMINAL </strong>: ${this.valorBr(parseFloat(item.valor_nominal), true)}<br/>`,
          cliente_id: this.$route.params.id,
          // eslint-disable-next-line radix
          usuario_empresas_id: parseInt(localStorage.getItem('userId')),
          motivo_evento_id: 22,
          credores_id: credor.id,
          parametros_contato: null,
        }
        await api.post('api/v1/eventos/store', bodyEvento)
        this.progressMessage = 'ENVIADO'
        this.bar.value = 100

        setTimeout(() => {
          this.progressMessage = ''
          this.bar.value = 0
        })
      }
    },
    async sendSms(item) {
      if (item.boleto) {
        this.$swal({
          text: 'Deseja renviar Boleto por SMS?',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          confirmButtonText: 'Sim, enviar!',
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.bar.value = 25
            this.progressMessage = 'BUSCANDO DADOS DO CLIENTE'
            const phones = []
            await api.get(`/api/v1/clientes/telefones/${this.$route.params.id}`, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                accept: 'application/json',
              },
            }).then(res => {
              res.data.dados.telefones.map(dt => {
                phones.push(dt.telefone)
              })
            })

            this.bar.value = 75
            this.progressMessage = 'ENVIANDO SMS...'

            await Promise.all(phones.map(async cell => {
              const body = {
                celular: cell,
                mensagem: `Segue seu boleto para quitação de débitos ${item.boleto.linha_digitavel}`,
              }
              await api.post('api/v1/sms/enviar', body, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.token),
                  Accept: 'application/json',
                },
              })
            }))

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'Operação executada',
                text: '',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })

            this.bar.value = 100
            this.progressMessage = 'ENVIADO'

            setTimeout(() => {
              this.bar.value = 0
              this.progressMessage = ''
            }, 2000)
          }
        })
      }
    },

    baixarAcordo() {
      const item = this.parcelaSelected
      const acordo = this.acordoSelected
      const status_receivment = this.statusReceiviment.label
      const remuneracao = status_receivment === 'Baixa Administrativa' ? 'Comissão' : null

      let valorComissao = null
      const parcelasEmLote = []
      if (this.parcelasSelecionadas.length > 0) {
        this.parcelasSelecionadas.map(parcelas => {
          parcelasEmLote.push(parcelas.id)
        })
      }

      if (remuneracao === 'Comissão' && status_receivment === 'Baixa Administrativa') {
        const { credor: cd } = this.acordoSelected
        // eslint-disable-next-line no-unused-vars
        const { credor_id: credorId } = cd[0]
        const credor = this.credores.filter(e => e.id === credorId)[0]

        const { default: dft } = credor.parametros

        switch (status_receivment) {
          case 'Baixa Administrativa':
            valorComissao = dft.comissao_baixa_credor || null
            break
          default:
            break
        }
      }

      const { id: idParcela, valor_nominal } = item
      if (idParcela) {
        // eslint-disable-next-line no-unused-vars
        const data = {
          ids: parcelasEmLote.length > 0 ? parcelasEmLote : [idParcela],
          valor_comissao: valorComissao,
          data_baixa: this.americaDate(this.dataBaixa),
          valor_pago: valor_nominal,
          status_recebimento: status_receivment,
          data_credor_informou: null,
          remuneracao,
          numero_acordo: acordo.numero_acordo,
          bandeira_cartao: this.bandeiraSelecionada,
          forma: this.baixaManualFormaPagamento.key,
        }

        api.post('api/v1/parcelas/incluirBaixa', data, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.token),
            Accept: 'application/json',
          },
        }).then(() => {
          this.parcelaSelected = {}
          this.parcelasSelecionadas = []
          this.bandeiraSelecionada = null
          this.baixaManualFormaPagamento = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Parcela Baixada',
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
          this.updateTabs()
        }).catch(error => {
          this.$swal({
            title: 'Error!',
            text: JSON.stringify(error.response.data),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
    },
    retirarBaixa() {
      const item = this.parcelaSelected
      const { id: idParcela } = item
      if (idParcela) {
        const data = {
          ids: [idParcela],
        }
        api.post('api/v1/parcelas/retirarBaixa', data, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.token),
            Accept: 'application/json',
          },
        }).then(() => {
          this.parcelaSelected = {}
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Retirada Baixada',
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
          this.clearModals()
          this.updateTabs()
        }).catch(error => {
          this.$swal({
            title: 'Error!',
            text: JSON.stringify(error.response.data),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
    },

    setBaixa(item, acao) {
      // limpando as variaveis do modal baixa
      this.dataBaixa = null
      this.statusReceiviment = ''
      this.checked = false
      this.optionsParcelasAcordo.options = [
        {
          label: '',
          id: null,
        },
      ]

      this.parcelaSelected = item
      this.baixa_acao = acao

      if (acao) {
        this.dataBaixa = new Date().toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' })
      }

      this.acordoSelected.parcelas.map(parc => {
        if (parc.status !== 'Liquidado') {
          this.optionsParcelasAcordo.options.push({
            id: parc.id,
            label: parc.numero_parcela,
          })
        }
      })
    },

    async gerarBoleto(item, idParcela, vencimentoAtt = false, valorAtualizado = false) {
      this.bar.value = 25
      this.progressMessage = 'BUSCANDO DADOS DO CREDOR'
      const { credor: cd } = this.acordoSelected
      // eslint-disable-next-line no-unused-vars
      const { credor_id: credorId } = cd[0]
      const credor = this.credores.filter(e => e.id === credorId)[0]
      if (credor) {
        const { default: dft } = credor.parametros

        let banco
        let convenio

        const { convenio: cv } = dft

        if (cv) {
          banco = cv.codigo_banco
          convenio = cv.convenio
        }

        let vencimento = item.data_vencimento.split('-')
        if (vencimentoAtt) {
          vencimento = vencimentoAtt.split('-')
        }
        let valorBoleto = 0

        const {
          nome,
          endereco,
          cpf_cnpj,
        } = this.infoCliente

        if (valorAtualizado) {
          valorBoleto = valorAtualizado * 100
        } else {
          valorBoleto = item.valor_nominal * 100
        }

        this.bar.value = 50
        this.progressMessage = 'GERANDO BOLETO'

        const body = qs.stringify({
          token: '43241324',
          banco,
          convenio,
          numero_acordo: this.acordoSelected.id,
          cpf_cnpj,
          nome,
          endereco,
          bairro: 'Três Poderes',
          cidade: 'IMPERATRIZ',
          uf: 'MA',
          cep: '65901150',
          data_vencimento: `${vencimento[2]}-${vencimento[1]}-${vencimento[0]}`,
          valor: valorBoleto,
        })

        // const teste = (parseFloat(valor_atualizado) || parseFloat(item.valor_nominal)) * 100
        // eslint-disable-next-line no-unused-vars
        const config = {
          method: 'post',
          url: 'http://192.168.254.205:9000/boleto',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: body,
        }

        await api(config).then(async res => {
          if (!item.boleto) {
            this.bar.value = 75
            this.progressMessage = 'GERANDO PDF...'
            await this.gerarPdf(res.data, idParcela, item, vencimento)

            this.bar.value = 100
            this.progressMessage = 'ENVIADO'
          } else {
            await this.attBoleto(res.data, item.boleto, idParcela, item, vencimento)
          }
        })

        setTimeout(() => {
          this.bar.value = 0
          this.progressMessage = ''
        }, 2000)
      }
      this.disabledBoleto = false
    },

    async gerarPdf(boleto, idParcela, item, vencimento) {
      const { credor: cd } = this.acordoSelected
      // eslint-disable-next-line no-unused-vars
      const { credor_id: credorId } = cd[0]
      const credor = this.credores.filter(e => e.id === credorId)[0]

      if (credor) {
        const {
          codigo_barras,
          nosso_numero,
          numero_linha_digitavel,
          pix_copia_cola,
          txid,
          mensagem,
        } = boleto

        if (!numero_linha_digitavel) {
          this.$swal({
            title: 'Erro ao gerar boleto!',
            text: `${mensagem || 'Contate a equipe de desenvolvimento e tente novamente...'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          const { default: dft } = credor.parametros

          let nome_beneficiario
          let cnpj_beneficiario
          let endereco_beneficiario
          let carteira
          let agencia
          let banco
          let convenio

          const { convenio: cv } = dft

          if (cv) {
            nome_beneficiario = cv.nome_beneficiario
            cnpj_beneficiario = cv.cnpj_beneficiario
            endereco_beneficiario = cv.endereco_beneficiario
            carteira = cv.carteira
            agencia = cv.agencia
            banco = cv.codigo_banco
            convenio = cv.convenio
          }

          const {
            nome,
            cpf_cnpj,
          } = this.infoCliente

          const {
            valor_nominal,
          } = item

          const { usuarioEmpresas: userId } = localStorage

          const data = {
            codigo_banco: banco,
            convenio,
            linha_digitavel: this.linhaDigitavel(numero_linha_digitavel),
            codigo_barra: codigo_barras,
            chave_pix: pix_copia_cola,
            nome_beneficiario,
            cnpj_beneficiario,
            endereco_beneficiario,
            vencimento: `${vencimento[0]}-${vencimento[1]}-${vencimento[2]}`,
            valor: valor_nominal,
            nosso_numero,
            carteira,
            agencia,
            data_documento: `${vencimento[2]}/${vencimento[1]}/${vencimento[0]}`,
            numero_documento: nosso_numero,
            desconto: 0,
            deducoes: 0,
            juros: 0,
            acrescimos: 0,
            // valor_cobrado: 3.50,
            valor_cobrado: valor_nominal,
            nome_pagador: nome,
            cpf_pagador: this.cpfCNPJ(cpf_cnpj),
            nome_avaliador: nome,
            codigo_credor: credor.id,
            parcelas_id: idParcela,
            id_pix: txid,
            usuario_empresas_id: userId,
          }

          await api.post('api/v1/boletos/store', data, {
            headers: {
              Accept: 'application/json',
            },
          }).then(async res => {
            const idBoleto = res.data.dados.boleto.id
            const boletoFile = await api.post('api/v1/boletos/viewBoleto', {
              boleto: idBoleto,
            }, {
              responseType: 'arraybuffer',
            }).then(ress => new File([ress.data], 'Boleto.pdf', {
              type: 'application/pdf',
            }))

            const demonstrativo = await api.post(`api/v1/acordo/viewDemoSimples/${this.acordoSelected.id}`, null, {
              responseType: 'arraybuffer',
            }).then(rs => new File([rs.data], 'Demonstrativo.pdf', {
              type: 'application/pdf',
            }))

            this.bar.value = 90
            this.progressMessage = 'ENVIADO PARA O CLIENTE'

            const body = new FormData()
            body.append('name', `${this.fisrtLastName(nome)}`)
            body.append('to', JSON.stringify(this.emailsCliente.map(email => ({
              name: this.fisrtLastName(nome), address: email,
            }))))
            body.append('idAcordo', this.acordoSelected.id)
            body.append('boleto', boletoFile)
            body.append('demonstrativo', demonstrativo)

            const configEmail = {
              method: 'post',
              url: 'http://192.168.254.219:3000/api/v1/boleto/',
              data: body,
            }
            await api(configEmail).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificação',
                  icon: 'BellIcon',
                  text: 'Email Reenviado!',
                  variant: 'success',
                },
              }, {
                position: 'top-center',
                timeout: 1500,
              })
              this.$refs.showAcordo.hide()

              this.overlayModalPortion = false
            })

            this.bar.value = 100
            this.progressMessage = 'ENVIADO PARA O CLIENTE'
          })
        }
      }
      await this.updateTabs()
    },

    envioEmail(data) {
      const body = new FormData()
      switch (data.dest) {
        case 'Boleto':
          body.append('name', `${this.fisrtLastName(data.nome)}`)
          body.append('devedor', `R$ ${data.devedor}`)
          body.append('file', data.file)
          body.append('to', 'jairorodrigues.sousa@gmail.com')
          body.append('reason', data.reason)
          body.append('subject', 'Boleto')
          break
        default:
          break
      }
      const configEmail = {
        method: 'post',
        url: `http://192.168.254.216:3333/${data.url}`,
        data: body,
      }

      api(configEmail)
    },

    demonstrativoAcordo() {
      this.$swal({
        title: 'Selecione modelo do demonstrativo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Resumido',
        cancelButtonText: 'Detalhado',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          api.post(`api/v1/acordo/viewDemoSimples/${this.acordoSelected.id}`, null, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.token),
            },
            responseType: 'arraybuffer',
          }).then(res => {
            const file = new File([res.data], 'boleto.pdf', {
              type: 'application/pdf',
            })
            const url = URL.createObjectURL(file)
            this.urlDemonstrativo = url
            this.$refs['modal-demonstrativo'].show()
          })
        } else if (result.dismiss === 'cancel') {
          api.post(`api/v1/acordo/viewDemoDetalhado/${this.acordoSelected.id}`, null, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.token),
            },
            responseType: 'arraybuffer',
          }).then(res => {
            const file = new File([res.data], 'boleto.pdf', {
              type: 'application/pdf',
            })
            const url = URL.createObjectURL(file)
            this.urlDemonstrativo = url
            this.$refs['modal-demonstrativo'].show()
          })
        }
      })
    },
    replicarDatas() {
      const primeiraData = this.novoVencArray[0]
      for (let i = 0; i < this.novoVencArray.length; i++) {
        this.novoVencArray[i] = primeiraData
      }
    },
    visualizarParcela(item) {
      this.parcelaView = item
      this.$refs.showAcordo.toggle()
      this.$refs['parcela-view'].show()
    },

    async reenvioMaquineta(demonstrativo, itemParcela) {
      const { credor_id: credorId } = this.acordoSelected.credor[0]

      const credor = this.credores.filter(e => e.id === credorId)[0]

      this.bar.value = 50
      this.progressMessage = 'BUSCANDO CONTATOS DE CREDORES'

      const reprentantes = await api.get(`api/v1/credor_contatos/${credor.id}`).then(rep => rep.data.dados)
      // eslint-disable-next-line consistent-return
      const to = reprentantes.map(rep => {
        if (rep.relatorios) {
          const resp = (rep.relatorios.filter(rel => rel.title.includes('Maquineta'))).length > 0 ? {
            name: rep.nome,
            address: rep.email,
          } : null
          return resp
        }
      }).filter(Boolean)

      if (to.length > 0) {
        this.bar.value = 90
        this.progressMessage = 'ENVIANDO...'

        const cartaoBody = new FormData()

        cartaoBody.append('to', JSON.stringify(to))
        cartaoBody.append('demonstrativo', demonstrativo)
        cartaoBody.append('name', this.infoCliente.nome)
        cartaoBody.append('credor', credor.nome)
        cartaoBody.append('cpf', this.infoCliente.cpf_cnpj)
        cartaoBody.append('idAcordo', this.acordoSelected.numero_acordo)
        cartaoBody.append('vencimento', this.americaDate(this.acordoSelected.parcelas[0].data_vencimento))

        await api.post('http://192.168.254.219:3000/api/v1/maquineta', cartaoBody, {}).then(async () => {
          this.bar = {
            variant: 'success',
            value: 100,
          }
          this.progressMessage = ''
          this.$refs.showAcordo.hide()
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sucesso',
                icon: 'CheckIcon',
                text: 'Link reenviado 🔗',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
        this.setEventoReenvio(this.acordoSelected, itemParcela, credorId)

        setTimeout(() => {
          this.bar.value = 0
          this.progressMessage = ''
        }, 2000)
      }
    },

    // eslint-disable-next-line consistent-return
    async reenvioLinkCredor(formaPagamento, maquineta = false, itemParcela) {
      this.bar.value = 25
      this.progressMessage = 'GERANDO LINK'

      const demonstrativo = await api.post(`api/v1/acordo/viewDemoDetalhado/${this.acordoSelected.id}`, null, {
        responseType: 'arraybuffer',
      }).then(res => new File([res.data], 'Demonstrativo.pdf', {
        type: 'application/pdf',
      }))

      if (maquineta) {
        this.reenvioMaquineta(demonstrativo, itemParcela)
        return null
      }

      const { credor_id: credorId } = this.acordoSelected.credor[0]

      const credor = this.credores.filter(e => e.id === credorId)[0]

      this.bar.value = 50
      this.progressMessage = 'BUSCANDO CONTATOS DE CREDORES'

      const reprentantes = await api.get(`api/v1/credor_contatos/${credor.id}`).then(rep => rep.data.dados)
        .catch(() => {
          this.progressMessage = 'NENHUM EMAIL DE CREDOR CADASTRADO PARA ESSA FUNÇÃO'
          this.bar.value = 0
          this.$refs.showAcordo.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'AlertOctagonIcon',
              text: 'Email não cadastrado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
        })
      const to = reprentantes.map(rep => {
        // eslint-disable-next-line consistent-return
        if (rep.relatorios) {
          const resp = (rep.relatorios.filter(rel => rel.title.includes(`${formaPagamento === 'cartao_credor' ? 'LInk' : 'Maquineta'}`))).length > 0 ? {
            name: rep.nome,
            address: rep.email,
          } : null
          return resp
        }
        return null
      }).filter(Boolean)

      if (to.length > 0) {
        this.bar.value = 90
        this.progressMessage = 'ENVIANDO...'
        const cartaoBody = new FormData()
        cartaoBody.append('to', JSON.stringify(to))
        cartaoBody.append('demonstrativo', demonstrativo)
        cartaoBody.append('emails', JSON.stringify(this.emailsCliente.map(email => email)))
        cartaoBody.append('name', this.infoCliente.nome)
        cartaoBody.append('credor', credor.nome)
        cartaoBody.append('cpfCliente', this.infoCliente.cpf_cnpj)
        cartaoBody.append('telefones', JSON.stringify([]))
        cartaoBody.append('idAcordo', this.acordoSelected.numero_acordo)

        await api.post('http://192.168.254.219:3000/api/v1/cartao/credor', cartaoBody, {}).then(() => {
          this.bar = {
            variant: 'success',
            value: 100,
          }
          this.progressMessage = ''
          this.$refs.showAcordo.hide()
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sucesso',
                icon: 'CheckIcon',
                text: 'Link reenviado 🔗',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
        })

        setTimeout(() => {
          this.bar.value = 0
          this.progressMessage = ''
        }, 2000)
      } else {
        this.progressMessage = 'NENHUM EMAIL DE CREDOR CADASTRADO PARA ESSA FUNÇÃO'
      }
      setTimeout(() => {
        this.bar.value = 0
        this.progressMessage = ''
      }, 2000)
    },
    async setEventoReenvio(acordo, parcela, credores) {
      const bodyEvento = {
        descricao: `=========== REENVIO DE LINK REFERENTE AO ACORDO Nº: ${acordo.numero_acordo} =========== <br/>
        <strong>Nr. PARCELA </strong>: ${parcela.numero_parcela} <br/>
        <strong>VALOR NOMINAL </strong>: ${parcela.valor_nominal}<br/>`,

        cliente_id: this.$route.params.id,
        usuario_empresas_id: parseInt(localStorage.getItem('userId')),
        motivo_evento_id: 122,
        credores_id: `${credores}`,
        parametros_contato: null,
      }

      await api.post('api/v1/eventos/store', bodyEvento)
      this.updateTabs()
    },
    async reenvioLinkVoz(parcela) {
      const { acordoSelected: acordo } = this

      this.bar.value = 25
      this.progressMessage = 'GERANDO LINK'

      // eslint-disable-next-line no-unused-vars
      const demonstrativo = await api.post(`api/v1/acordo/viewDemoSimples/${acordo.id}`, null, {
        responseType: 'arraybuffer',
      }).then(res => new File([res.data], 'Demonstrativo.pdf', {
        type: 'application/pdf',
      }))

      const { nome } = this.infoCliente
      const hash = md5(acordo.numero_acordo).toString()

      this.bar.value = 75
      this.progressMessage = 'ENVIANDO PARA O CLIENTE'

      const cartaoBody = new FormData()
      cartaoBody.append('name', nome)
      cartaoBody.append('link', `https://fatura.grupovoz.com.br/cartao/${hash}`)
      cartaoBody.append('demonstrativo', demonstrativo)
      cartaoBody.append('idAcordo', acordo.id)
      cartaoBody.append('to', JSON.stringify(this.emailsCliente.map(email => ({
        name: this.fisrtLastName(nome), address: email,
      }))))

      const configEmail = {
        method: 'post',
        url: 'http://192.168.254.219:3000/api/v1/cartao/voz',
        data: cartaoBody,
      }
      await api(configEmail)

      this.bar.value = 100
      this.progressMessage = 'LINK ENVIADO'
      const credoresId = acordo.credor.map(cred => cred.credor_id)

      this.setEventoReenvio(acordo, parcela, credoresId)

      setTimeout(() => {
        this.bar.value = 0
        this.progressMessage = ''
      }, 2000)
    },
    async paymentConfirm() {
      api.get(`api/v1/acordo/modificar/status/${this.acordoSelected.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Ação concluída!',
            text: 'Pagamento Confirmado!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.clearModals()
          this.updateTabs()
        })
        .catch(() => {
          this.$swal({
            title: 'Algo de errado ocorreu!',
            text: 'Contate equipe de desenvolvimento',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async attBoleto(boleto, oldBoleto, idParcela, item, vencimento) {
      const { credor: cd } = this.acordoSelected
      // eslint-disable-next-line no-unused-vars
      const { credor_id: credorId } = cd[0]
      const credor = this.credores.filter(e => e.id === credorId)[0]

      if (credor) {
        const {
          codigo_barras,
          nosso_numero,
          numero_linha_digitavel,
          pix_copia_cola,
          txid,
          mensagem,
        } = boleto

        if (!numero_linha_digitavel) {
          this.$swal({
            title: 'Erro ao gerar boleto!',
            text: `${mensagem || 'Contate a equipe de desenvolvimento e tente novamente...'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return null
        }

        const { default: dft } = credor.parametros

        let nome_beneficiario
        let cnpj_beneficiario
        let endereco_beneficiario
        let carteira
        let agencia
        let banco
        let convenio

        const { convenio: cv } = dft

        if (cv) {
          nome_beneficiario = cv.nome_beneficiario
          cnpj_beneficiario = cv.cnpj_beneficiario
          endereco_beneficiario = cv.endereco_beneficiario
          carteira = cv.carteira
          agencia = cv.agencia
          banco = cv.codigo_banco
          convenio = cv.convenio
        }

        const {
          nome,
          cpf_cnpj,
        } = this.infoCliente

        const {
          valor_nominal,
        } = item

        const { usuarioEmpresas: userId } = localStorage

        const data = {
          codigo_banco: banco,
          convenio,
          linha_digitavel: this.linhaDigitavel(numero_linha_digitavel),
          codigo_barra: codigo_barras,
          chave_pix: pix_copia_cola,
          nome_beneficiario,
          cnpj_beneficiario,
          endereco_beneficiario,
          vencimento: `${vencimento[0]}-${vencimento[1]}-${vencimento[2]}`,
          valor: valor_nominal,
          nosso_numero,
          carteira,
          agencia,
          data_documento: `${vencimento[2]}/${vencimento[1]}/${vencimento[0]}`,
          numero_documento: nosso_numero,
          desconto: 0,
          deducoes: 0,
          juros: 0,
          acrescimos: 0,
          // valor_cobrado: 3.50,
          valor_cobrado: valor_nominal,
          nome_pagador: nome,
          cpf_pagador: this.cpfCNPJ(cpf_cnpj),
          nome_avaliador: nome,
          codigo_credor: credor.id,
          parcelas_id: idParcela,
          id_pix: txid,
          usuario_empresas_id: userId,
        }

        await api.put(`api/v1/boletos/update/${oldBoleto.id}`, data).then(async () => {
          const boletoFile = await api.post('api/v1/boletos/viewBoleto', {
            boleto: oldBoleto.id,
          }, {
            responseType: 'arraybuffer',
          }).then(ress => new File([ress.data], 'Boleto.pdf', {
            type: 'application/pdf',
          }))

          const demonstrativo = await api.post(`api/v1/acordo/viewDemoSimples/${this.acordoSelected.id}`, null, {
            responseType: 'arraybuffer',
          }).then(rs => new File([rs.data], 'Demonstrativo.pdf', {
            type: 'application/pdf',
          }))

          const body = new FormData()
          body.append('name', `${this.fisrtLastName(nome)}`)
          body.append('to', JSON.stringify(this.emailsCliente.map(email => ({
            name: this.fisrtLastName(nome), address: email,
          }))))
          body.append('idAcordo', this.acordoSelected.id)
          body.append('boleto', boletoFile)
          body.append('demonstrativo', demonstrativo)

          const configEmail = {
            method: 'post',
            url: 'http://192.168.254.219:3000/api/v1/boleto/',
            data: body,
          }

          await api(configEmail)
        })
      }
      return null
    },
    async setCards() {
      const cards = [
        {
          color: 'light-secondary',
          customClass: '',
          icon: 'ActivityIcon',
          subtitle: 'Acordo',
          title: this.items.length,
        },
        {
          color: 'light-warning',
          customClass: '',
          icon: 'LoaderIcon',
          subtitle: 'Aguardando',
          title: this.countStatusAcordos.aguardando,
        },
        {
          color: 'light-warning',
          customClass: '',
          icon: 'RotateCcwIcon',
          subtitle: 'Devolvido',
          title: this.countStatusAcordos.devolvido,
        },
        {
          color: 'light-danger',
          customClass: '',
          icon: 'AlertTriangleIcon',
          subtitle: 'Cancelado',
          title: this.countStatusAcordos.cancelado,
        },
        {
          color: 'light-primary',
          customClass: '',
          icon: 'ClockIcon',
          subtitle: 'Negociado',
          title: this.countStatusAcordos.negociado,
        },
        {
          color: 'light-success',
          customClass: '',
          icon: 'DollarSignIcon',
          subtitle: 'Liquidado',
          title: this.countStatusAcordos.liquidado,
        },
        {
          color: 'light-info',
          customClass: '',
          icon: 'CheckCircleIcon',
          subtitle: 'Baixa Administrativa',
          title: this.countStatusAcordos.baixaAdministrativa,
        },
        {
          color: 'light-secondary',
          customClass: '',
          icon: 'AlertCircleIcon',
          subtitle: 'Vencido',
          title: this.countStatusAcordos.vencido,
        },
        {
          color: 'light-secondary',
          customClass: '',
          icon: 'TrendingDownIcon',
          subtitle: 'Quebrado',
          title: this.countStatusAcordos.quebrado,
        },
      ]
      this.cards = cards
    },
    itemClick(status) {
      if (status === 'Acordo') {
        this.statusFilter = []
      } else {
        this.statusFilter = [status]
        this.currentPage = 1
        this.totalRows = this.statusFilter.length
      }
    },
    resolveVariantStatusParcela(itemStatus) {
      if (itemStatus) {
        const letter = itemStatus.toLowerCase()
        const variants = {
          liquidado: 'success',
          liquidada: 'success',
          cancelado: 'danger',
          vencida: 'secondary',
          'a vencer': 'warning',
        }
        if (variants[letter]) {
          return variants[letter]
        }
      }
      return 'info'
    },
    solicitarTrocaExecutivo() {
      const { executivoCobranca, acordoSelected } = this
      api.post(`api/v1/troca_executivo/solicitar/${acordoSelected.id}`, {
        executivo_cobranca_novo_id: executivoCobranca.id,
        user_id: localStorage.getItem('userId'),
        descricao: this.motivoTroca.toUpperCase(),
      }, {
        headers: {
          Accept: 'application/pdf',
          Authorization: 'Bearer '.concat(localStorage.token),
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificação',
            icon: 'BellIcon',
            text: 'Solicitação de troca feita com sucesso!',
            variant: 'success',
          },
        }, {
          position: 'top-center',
        })
        this.clearModals()
        this.updateTabs()
        this.solicitarTroca = false
        this.motivoTroca = ''
      })
    },
    async getPrazoCompensacao(parcelaID) {
      const response = await api.get(`api/v1/parcelas/prazo_compensacao/${parcelaID}`)
      this.prazoCompensacao = response.data.dados
    },
    copiarLinhaDigitavel(parcelaId) {
      // eslint-disable-next-line
      const elementId = `copiarlinhadigitavel-${parcelaId}`
      const testingCodeToCopy = document.querySelector(`#${elementId}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      document.execCommand('copy')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Copiado',
          icon: 'CheckIcon',
          text: 'Copiado com sucesso',
          variant: 'success',
        },
      },
      {
        position: 'top-center',
      })
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    async openModalChooseTelefone(item) {
      this.clearSendLinhaDigitavel()
      this.telefones = []
      this.sendLinhaDigitavel.mensagem = this.getMensagemEnvioLinhaDigitavel(item)
      await api.get(`/api/v1/clientes/telefones/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.telefones.map(dt => {
          this.telefones.push(dt.telefone)
        })
      })
      this.$refs.modalChooseTelefone.show()
    },
    sendoToLinhaDigitavel() {
      this.$swal({
        title: 'Confirmação',
        text: `Deseja enviar a linha digitavel para o telefone 55${this.sendLinhaDigitavel.telefone} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, enviar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const { nome } = this.infoCliente
            this.sendLinhaDigitavel.cliente = `${this.$route.params.id} ${this.fisrtLastName(nome)}`
            api.post('api/v1/whatsapp/enviar', this.sendLinhaDigitavel, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.token),
                Accept: 'application/json',
              },
            })
              .then(() => {
                this.clearSendLinhaDigitavel()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Iniciado',
                    icon: 'CheckIcon',
                    text: 'Linha Digitavel enviada com sucesso!',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
              })
              .catch(error => {
                this.clearSendLinhaDigitavel()
                this.$swal({
                  title: 'Erro',
                  text: `Ops, algo deu errado: ${error.data.response.mensagem}`,
                  icon: 'danger',
                  showCancelButton: true,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
    },
    clearSendLinhaDigitavel() {
      this.sendLinhaDigitavel.telefone = ''
      this.sendLinhaDigitavel.cliente = ''
      this.sendLinhaDigitavel.mensagem = ''
    },
    getMensagemEnvioLinhaDigitavel(data) {
      const { nome } = this.infoCliente
      return `${this.firstAndSecondName(nome)},
Aqui está o código de barras solicitado referente ao boleto ${data.numero_parcela}/${this.acordoSelected.quantidade_parcelas} do Acordo ${this.acordoSelected.numero_acordo}:

${data.boleto.linha_digitavel}

Vencimento: ${this.americaDate(data.boleto.vencimento)}
Valor: ${this.valorBr(parseFloat(data.boleto.valor), true)}

Caso precise de algo mais, volte falar conosco por aqui ou pelos números 0800 591 6011 ou 0800 940 6011. Estamos à disposição para lhe atender e dar toda atenção que você merece. 🤞🏼 🧡`
    },
  },
}
</script>

<style>
.primary-voz {
  background-color: #fd8426;
}
</style>
