<template>
  <div>
    <b-row>
      <b-col>
        <h4>
          Endereço
        </h4>
      </b-col>
    </b-row>
    <hr>
    <b-row class="align-center">
      <b-col>
        <b-card-text>
          <b-row>
            <b-col>
              <label>CEP:</label>
              <b-input-group>
                <cleave
                  v-model="dt.cep"
                  :disabled="editar"
                  size="sm"
                  class="form-control"
                  :raw="false"
                  :options="maskCep"
                  placeholder="00000-000"
                  @keyup.enter="buscarCep"
                />
                <b-input-group-append>
                  <b-button
                    v-show="!editar"
                    size="sm"
                    variant="outline-primary"
                    @click="buscarCep"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <label>Cidade:</label>
              <b-form-input
                v-model="dt.cidade.nome"
                class="text-uppercase"
                :disabled="true"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <label>Endereço:</label>
              <b-form-input
                v-model="dt.endereco"
                class="text-uppercase"
                :disabled="editar"
                size="sm"
              />
            </b-col>
            <b-col md="3">
              <label>Nº:</label>
              <b-form-input
                v-model="dt.numero"
                class="text-uppercase"
                :disabled="editar"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-row class=" mt-1">
            <b-col>
              <label>Bairro:</label>
              <b-form-input
                v-model="dt.bairro"
                class="text-uppercase"
                :disabled="editar"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-button
          v-show="editar && showButtonEdit"
          variant="outline-primary"
          @click="editar=false"
        >
          Editar
        </b-button>

        <!--Botão de confirmação de cadastro-->
        <div
          v-if=" canChange === false"
          class="mt-2"
        >
          <b-badge
            variant="warning"
          >
            Cadastro confirmado em {{ formatTimezone(cliente.data_cadastro_confirmado) }}
          </b-badge>
        </div>
        <b-button
          v-else
          v-show="editar && showButtonEdit"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          @click="confirmCadastro"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Confirmar</span>
        </b-button>
        <!--Botão de confirmação de cadastro-->

        <b-button
          v-show="!editar"
          variant="outline-secondary"
          @click="resetInfo"
        >
          Cancelar
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-show="!editar"
          variant="primary"
          class="ml-1"
          @click="updateCliente"
        >
          Salvar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Importando dependecias de estilos
import {
  BCol,
  BRow,
  BCardText,
  BButton,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// Importando component da notificação
import Cleave from 'vue-cleave-component'

// Importando mecânico de requisição
import { mapState } from 'vuex'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BCardText,
    BButton,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    Cleave,
  },

  directives: {
    Ripple,
  },
  props: {
    infoCliente: {
      type: Object,
      required: true,
    },
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      editar: true,
      showButtonEdit: true,
      dt: {},
      perservaEndereço: {
      },
      maskCep: {
        delimiters: ['-'],
        blocks: [5, 3],
      },
      canChange: false,
    }
  },

  computed: {
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
    }),
  },
  watch: {
    editar(n) {
      if (!n) {
        const keys = Object.keys(this.dt)
        keys.map(item => {
          this.perservaEndereço[item] = this.dt[item]
        })
      }
    },
  },
  created() {
    this.dt = this.cliente
    this.verifyDateCadastro()
  },
  methods: {
    resetInfo() {
      const keys = Object.keys(this.perservaEndereço)
      keys.map(item => {
        this.dt[item] = this.perservaEndereço[item]
      })
      this.editar = true
    },
    verifyDateCadastro() {
      const dataConfirmada = this.cliente.data_cadastro_confirmado

      const dataConfirmadaFormatada = new Date(dataConfirmada)
      const dataFinal = dataConfirmadaFormatada.setDate(dataConfirmadaFormatada.getDay() + 34)
      const dataFinalFormatada = new Date(dataFinal)
      const dataAtual = new Date()

      if (dataAtual < dataFinalFormatada) {
        this.canChange = false
      } else {
        this.canChange = true
      }
    },
    updateCliente() {
      // eslint-disable-next-line no-unused-vars
      const formData = {
        nome: this.dt.nome,
        cpf_cnpj: this.dt.cpf_cnpj,
        endereco: this.dt.endereco ? this.dt.endereco.toUpperCase() : null,
        bairro: this.dt.bairro ? this.bairro.toUpperCase() : null,
        cidades_id: this.dt.cidade.id,
        numero: this.dt.numero,
        cep: this.dt.cep,
        observacoes: this.dt.observacoes,
      }

      axios.put(`api/v1/clientes/update/${this.$route.params.id}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.editar = true
        this.$swal({
          icon: 'success',
          title: 'Atualizado',
          text: 'Informaçẽs do cliente atualizados com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
        this.updateTabs()
      }).catch(() => {
        this.$swal({
          title: 'ERROR',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },

    confirmCadastro() {
      // eslint-disable-next-line no-unused-vars
      const confirm = {
        confirmado: true,
      }
      axios.post(`api/v1/clientes/update_data_cadastro_confirmado/${this.$route.params.id}`, confirm, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          icon: 'success',
          title: 'Atualizado',
          text: 'Informaçẽs do cliente atualizados com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
        this.updateTabs()
      }).catch(() => {
        this.$swal({
          title: 'ERROR',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
    async buscarCep() {
      if (this.dt.cep) {
        const cep = this.dt.cep.replace(/[^0-9]/g, '')
        if (cep.length === 8) {
          let info = {
            cep: '',
            cidade: {},
            uf: '',
            endereco: '',
            bairro: '',
          }
          await axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
            const { data } = res
            if (data.erro) {
              this.$swal({
                title: 'ERROR',
                text: 'CEP não encontrado',
                icon: 'error',
                customClass: {
                  comfirmButton: 'btn btn-danger',
                },
              })
            } else {
              info = {
                cep: data.cep.replace(/[^0-9]/g, ''),
                cidade: {
                  id: null,
                  nome: this.removerAcentos(data.localidade),
                  uf: data.uf,
                },
                endereco: data.logradouro.toUpperCase(),
                bairro: data.bairro.toUpperCase(),
              }
              axios.post('api/v1/cidades/filtro', {
                nome: info.cidade.nome,
              }, {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }).then(response => {
                const { cidade } = response.data.dados
                if (cidade) {
                  cidade.filter(e => e.nome === info.cidade && e.uf === info.uf)
                }
                if (cidade.length > 0) {
                  info.cidade.id = cidade[0].id
                }
                const keys = Object.keys(info)
                keys.map(key => {
                  this.dt[key] = info[key]
                })
              })
            }
          }).catch(() => {
            this.$swal({
              title: 'ERROR',
              text: 'CEP não encontrado',
              icon: 'error',
              customClass: {
                comfirmButton: 'btn btn-danger',
              },
            })
          })
        }
      }
      return null
    },
  },
}
</script>
