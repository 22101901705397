<template>
  <div>
    <b-row class="mb-1">
      <b-col
        md="3"
      >
        <label>Filtro por vencimento</label>
        <v-select
          v-model="vencimentoFilter"
          :options="filtroVencimento"
          multiple
        />
      </b-col>
      <b-col
        v-show="showFiltersAlunos === true"
        md="3"
      >
        <label>Filtro por aluno</label>
        <v-select
          v-model="alunoFilter"
          :options="filtroAluno"
          multiple
        />
      </b-col>
      <b-col
        md="3"
      >
        <label>Filtro por credor</label>
        <v-select
          v-model="credorFilter"
          :options="filtroCredor"
          multiple
        />
      </b-col>

      <b-col md="2">
        <label>Eliminar pendencias</label>
        <b-form-checkbox
          v-model="filterPendencies"
        />
      </b-col>

      <b-col
        v-show="clienteInativo"
        md="3"
      >
        <b-alert
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            Cliente Inativo
          </h4>
        </b-alert>
      </b-col>

      <b-col
        md="3"
        sm="12"
      >
        <b-alert
          variant="info"
          show
        >
          <h4 class="alert-heading">
            Operações: {{ operacoes.length }}
          </h4>
        </b-alert>
      </b-col>

      <b-col
        v-if="tituloHon"
        md="3"
        sm="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Títulos com menos de {{ diasCalc }} dias
          </h4>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          ref="selectableTable"
          striped
          selectable
          responsive
          sticky-header="500px"
          select-mode="multi"
          :items="operacoes"
          :fields="fields"
          @row-selected="onRowSelected"
        >
          <template #head(selected)>
            <b-form-checkbox
              v-model="selectAll"
              class="custom-control-primary"
            />
          </template>

          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <i class="feather icon-disc primary" />
            </template>

            <template v-else>
              <i class="feather icon-circle" />
            </template>
          </template>

          <template #cell(numero_operacao)="data">
            <b-row>
              <b-col
                md="2"
              >
                <b-badge
                  v-if="data.item.motivo_pendencia.nome"
                  id="popover"
                  v-b-popover.hover.top="`${data.item.motivo_pendencia.nome}`"
                  pill
                  :style="{background: 'transparent', color: '#FF8C00'}"
                >
                  <feather-icon
                    :style=" { height: '18px', width: '18px' } "
                    icon="AlertTriangleIcon"
                  />
                </b-badge>
              </b-col>
              <b-col
                md="2"
              >
                <b-badge
                  v-if="data.item.ativo"
                  id="popover"
                  v-b-popover.hover.left="`${'INATIVO'}`"
                  pill
                  :style="{background: 'transparent', color: !data.rowSelected ? '#7B68EE': '#fff',}"
                >
                  <feather-icon
                    :style=" { height: '18px', width: '18px' } "
                    icon="ClockIcon"
                  />
                </b-badge>
              </b-col>
              <b-col>
                {{ data.item.numero_operacao }} <br>
                <span
                  v-if="data.item.not_debito"
                  class="text-danger"
                >Not. Débito</span>
              </b-col>
            </b-row>
          </template>

          <template #cell(aluno)="data">
            {{ data.item.aluno }}
          </template>

          <template #cell(data_vencimento)="data">
            {{ data.value }}
          </template>

          <template #cell(valor_nominal)="data">
            {{ valorBr(data.value, true) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BAlert,
  VBPopover,
  BBadge,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BTable,
    BFormCheckbox,
    BAlert,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      alunoFilter: [],
      vencimentoFilter: [],
      credorFilter: [],
      filterPendencies: false,
      selectedCredor: null,
      selectAll: false,
      diasCalc: 120,
      tituloHon: false,

      itemSelected: [],
      fields: [
        { key: 'selected', label: '' },
        { key: 'numero_operacao', label: 'operacao', class: 'text-center table-adjust' },
        { key: 'aluno', label: 'aluno', class: 'text-center' },
        { key: 'data_vencimento', label: 'vencimento', class: 'text-center' },
        { key: 'diasVenc', label: 'D. Venc', class: 'text-center' },
        { key: 'valor_nominal', label: 'Dívida', class: 'text-center' },
        { key: 'taxaJuros', label: 'juros', class: 'text-center' },
        { key: 'taxaMulta', label: 'multa', class: 'text-center' },
        { key: 'taxaHonorarios', label: 'Hon. Variável', class: 'text-center' },
        { key: 'taxaDesconto', label: 'Desc', class: 'text-center' },
        { key: 'total', label: 'total' },
      ],
    }
  },
  computed: {
    ...mapState({
      credorSelected: state => {
        const { credorSelected } = state.negociar
        return credorSelected
      },
      operacoesCobranca: state => {
        const { operacoesCredor } = state.negociar
        return operacoesCredor
      },
      dataEntrada: state => state.negociar.negociacao.dataEntrada,
      juros: state => state.negociar.negociacao.juros,
      multa: state => state.negociar.negociacao.multa,
      honorarios: state => state.negociar.negociacao.honorarios,
      desconto: state => state.negociar.negociacao.desconto,
      clienteInativo: state => state.negociar.clienteInativo,
      honorariosChecked: state => state.negociar.honorariosChecked,
      honorariosCash: state => state.negociar.honorariosCash,
      descontoChecked: state => state.negociar.descontoChecked,
      descontoCash: state => state.negociar.descontoCash,
      operacoesSelected: state => state.negociar.operacoesSelected,
      descOp: state => state.negociar.descOp,
      isSimulacao: state => state.negociar.isSimulacao,
    }),
    filtroVencimento() {
      const { operacoesCobranca: items } = this
      const list = items.map(item => {
        const date = item.data_vencimento.split('/')[2]
        return date
      })
      const uniq = [...new Set(list)]
      return uniq
    },
    showFiltersAlunos() {
      const { operacoesCobranca: items } = this
      const alunos = []
      items.map(item => {
        item.aluno ? alunos.push(item.aluno) : null
      })
      // const filtrado = alunos.filter((elem, pos, arr) => arr.indexOf(elem) === pos)
      return alunos.length > 1
    },
    filtroAluno() {
      const { operacoesCobranca: items } = this
      const alunos = []
      items.map(item => {
        item.aluno ? alunos.push(item.aluno) : null
      })
      const uniq = [...new Set(alunos)]
      return uniq
    },
    filtroCredor() {
      const { operacoesCobranca: items } = this
      const credores = []
      items.map(item => {
        item.credor ? credores.push(item.credor.nome) : null
      })
      const uniq = [...new Set(credores)]
      return uniq
    },

    operacoes() {
      const { operacoesCobranca: items } = this

      const {
        juros,
        multa,
        desconto,
        honorarios,
        vencimentoFilter,
        filterPendencies,
        alunoFilter,
        credorFilter,
      } = this

      let result = items.map(item => {
        item.diasVenc = this.diasVencidos(
          item.data_vencimento,
        )

        if (parseInt(item.diasVenc) < 0) {
          item.taxaJuros = 0
          item.taxaMulta = 0
          item.taxaDesconto = 0
          item.taxaHonorarios = 0
          item.total = 0
          return item
        }

        item.taxaJuros = parseFloat(this.valorJuros(item.valor_nominal, item.diasVenc, juros))
        item.taxaMulta = parseFloat(this.valorMulta(item.valor_nominal, multa))
        item.taxaDesconto = parseFloat(this.valorDesconto(
          item.valor_nominal,
          item.taxaJuros,
          item.taxaMulta,
          desconto,
          item.diasVenc,
          item.desconto,
          item.id,
        ))
        item.taxaHonorarios = parseFloat(this.valorHonorarios(
          item.valor_nominal,
          item.taxaJuros,
          item.taxaMulta,
          honorarios,
          desconto,
          item.id,
          this.descOp ? item.desconto : false,
          item.diasVenc,
        ))
        item.total = (
          item.valor_nominal + parseFloat(item.taxaJuros) + parseFloat(item.taxaMulta) + parseFloat(item.taxaHonorarios) - parseFloat(item.taxaDesconto)).toFixed(2)
        return item
      })

      let totalNominal = 0
      let totalJuros = 0
      let totalMulta = 0
      let totalHonorario = 0
      let totalDesconto = 0
      let valorTotal = 0

      this.operacoesSelected.map(item => {
        totalNominal += parseFloat(item.valor_nominal)
        totalJuros += parseFloat(item.taxaJuros)
        totalMulta += parseFloat(item.taxaMulta)
        totalHonorario += parseFloat(item.taxaHonorarios)
        totalDesconto += parseFloat(item.taxaDesconto)
        valorTotal += parseFloat(item.total)
      })

      totalNominal = parseFloat(totalNominal.toFixed(2))
      totalJuros = parseFloat(totalJuros.toFixed(2))
      totalMulta = parseFloat(totalMulta.toFixed(2))
      totalHonorario = parseFloat(totalHonorario.toFixed(2))
      totalDesconto = parseFloat(totalDesconto.toFixed(2))
      valorTotal = parseFloat(valorTotal.toFixed(2))

      this.$store.commit('negociar/setTotais', {
        totalNominal,
        totalJuros,
        totalMulta,
        totalHonorario,
        totalDesconto,
        valorTotal,
      })

      if (this.honorariosChecked) {
        const resp = (this.honorariosCash / (valorTotal - totalHonorario)) * 100
        this.$store.commit('negociar/setParamKey', {
          key: 'honorarios',
          val: resp === Infinity ? 0 : resp.toFixed(2),
        })
      }

      if (this.descontoChecked) {
        const valorToPercent = parseFloat(totalNominal.toFixed(2)) + parseFloat(totalJuros.toFixed(2)) + parseFloat(totalMulta.toFixed(2))
        const resp = parseFloat((parseFloat(this.descontoCash) / valorToPercent).toFixed(5)) * 100
        this.$store.commit('negociar/setParamKey', {
          key: 'desconto',
          val: resp === Infinity || Number.isNaN(resp)
            ? 0
            : resp.toFixed(2),
        })
      }

      if (vencimentoFilter.length > 0) {
        result = result.filter(e => vencimentoFilter.includes(e.data_vencimento.split('/')[2]))
      }

      if (filterPendencies) {
        result = result.filter(e => !e.motivo_pendencia.nome)
      }
      if (alunoFilter.length > 0) {
        result = result.filter(e => alunoFilter.includes(e.aluno))
      }
      if (credorFilter.length > 0) {
        result = result.filter(e => credorFilter.includes(e.credor.nome))
      }

      const response = result.sort((a, b) => b.diasVenc - a.diasVenc)

      return response
    },
  },
  watch: {
    selectAll(n) {
      n ? this.selectAllRows() : this.clearSelected()
    },
  },
  mounted() {
    this.diasCalc = this.credorSelected.prazo_honorario
    // this.diasCalc = 340
    this.setGetRemuneracao()
  },
  methods: {
    diasVencidos(dataVencimento) {
      const dtv = dataVencimento.split('/')
      const dataV = `${dtv[1]}/${dtv[0]}/${dtv[2]}`
      const dte = this.dataEntrada.split('-')
      const dataE = `${dte[1]}/${dte[2]}/${dte[0]}`
      const dataInicialMilissegundos = new Date(dataV).getTime()
      const dataFinalMilissegundos = new Date(dataE).getTime()
      const umDiaMilissegundos = 1000 * 60 * 60 * 24
      const diferencaMilissegundos = dataFinalMilissegundos - dataInicialMilissegundos
      const diferencaData = Math.round(
        diferencaMilissegundos / umDiaMilissegundos,
      )
      return diferencaData
    },
    valorJuros(valorNominal, diasVencidos, taxa) {
      const tJuros = taxa / 3000
      const vlJuros = valorNominal * parseFloat(tJuros) * diasVencidos
      return vlJuros.toFixed(2)
    },

    valorHonorarios(valorNominal, juros, multa, taxa, taxaDesconto, id, descontoConcedido = false, diasVenc) {
      const { honorariosChecked, honorariosCash } = this
      if (parseInt(diasVenc) < this.diasCalc) {
        this.tituloHon = true
        return 0.00
      }

      if (honorariosChecked) {
        const { operacoesSelected: itemSelected } = this
        const valor = parseFloat(honorariosCash) / parseInt(itemSelected.length)
        let response = 0.0
        // eslint-disable-next-line consistent-return
        itemSelected.map(item => {
          if (item.id === id) {
            if (parseFloat(parseFloat(valor.toFixed(2)) * itemSelected.length) !== parseFloat(honorariosCash)) {
              const diff = parseFloat(honorariosCash) - parseFloat(parseFloat(valor.toFixed(2)) * itemSelected.length)
              if (itemSelected[0].id === id) {
                response = parseFloat(valor) + diff
                return response.toFixed(2)
              }
            }
            response = valor
          }
        })
        return response.toFixed(2)
      }

      const tHonorarios = taxa / 100
      const tDsc = descontoConcedido || taxaDesconto
      const tDesconto = tDsc / 100

      const soma = valorNominal + parseFloat(juros) + parseFloat(multa)
      const vlHonorarios = soma * parseFloat(tHonorarios)

      const vlDesconto = vlHonorarios * parseFloat(tDesconto)
      const vlFinal = vlHonorarios - vlDesconto
      return vlFinal.toFixed(2)
    },
    valorMulta(valorNominal, taxa) {
      const tMulta = taxa / 100
      const vlMulta = valorNominal * parseFloat(tMulta)
      return vlMulta.toFixed(2)
    },

    valorDesconto(valorNominal, juros, multa, desc, diasVenc, descontoItem, id) {
      let taxa = 0
      if (this.descOp && !this.isSimulacao) {
        taxa = descontoItem
        if (parseFloat(diasVenc) > 0 && parseFloat(diasVenc) <= 60) {
          const descPorcento = descontoItem / 100
          const soma = valorNominal + parseFloat(juros) + parseFloat(multa)
          const tDesconto = soma * descPorcento
          return parseFloat(tDesconto.toFixed(2))
        }
      } else {
        taxa = desc
      }

      const { descontoChecked, descontoCash } = this

      if (descontoChecked) {
        const { operacoesSelected: itemSelected } = this
        const valor = parseFloat(descontoCash) / parseInt(itemSelected.length)
        let response = 0.0
        // eslint-disable-next-line consistent-return
        itemSelected.map(item => {
          if (item.id === id) {
            if (parseFloat(parseFloat(valor.toFixed(2)) * itemSelected.length) !== parseFloat(descontoCash)) {
              const diff = parseFloat(descontoCash) - parseFloat(parseFloat(valor.toFixed(2)) * itemSelected.length)
              if (itemSelected[0].id === id) {
                response = parseFloat(valor) + diff
                return response.toFixed(2)
              }
            }
            response = valor
          }
        })
        return response.toFixed(2)
      }

      const tDesconto = taxa / 100
      const soma = valorNominal + parseFloat(juros) + parseFloat(multa)
      const vlDesconto = soma * parseFloat(tDesconto)
      return vlDesconto.toFixed(2)
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected(items) {
      // const verifyOpSelected = items.length === items.filter(item => item.diasVenc < this.diasCalc).length

      // if (items.length === 0) {
      //   this.setGetRemuneracao()
      // } else if (verifyOpSelected) {
      //   this.$store.commit('negociar/setGetRemuneracao', false)
      // }
      this.$store.commit('negociar/setOperacoesSelected', items)
    },
    setGetRemuneracao() {
      const { operacoes } = this
      const verifyOpSelected = operacoes.length === operacoes.filter(item => item.diasVenc < this.diasCalc).length
      if (verifyOpSelected) {
        this.$store.commit('negociar/setGetRemuneracao', false)
      } else {
        this.$store.commit('negociar/setGetRemuneracao', true)
      }
    },
  },
}
</script>
