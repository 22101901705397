<template>
  <div>
    <div v-if="cliente.roteiro && (cliente.roteiro.id || cliente.roteiro.titulo || cliente.roteiro.mensagem)">
      <b-modal
        v-model="showAlert"
        size="lg"
        bg-variant="warning"
        centered
        :title="cliente.roteiro.titulo"
      >
        <b-row class="mb-1">
          <b-alert
            v-show="cliente.roteiro"
            variant="warning"
            show
          >
            <div class="alert-body">
              <span> {{ cliente.roteiro.mensagem }} </span>
            </div>
          </b-alert>
        </b-row>
      </b-modal></div>
    <b-row>
      <b-col md="3">
        <b-row>
          <b-col>
            <!-- Search Bar -->
            <SearchBar />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <info-header
                :header-info-cliente="headerInfoCliente"
                :update-header="getDataHeaderCliente"
              />
            </b-card>
          </b-col>
        </b-row>
        <div v-if="loadNegociacao">
          <b-row>
            <b-col>
              <b-card>
                <Info
                  ref="infoClienteComp"
                  :tab="tab"
                  :negociar="negociar"
                  :update-tabs="updateTabs"
                />
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <Telefones
                  :telefones="telefones"
                  :update-tabs="updateTabs"
                  :info-cliente="infoCliente"
                />
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <Emails
                  :info-cliente="infoCliente"
                  :update-tabs="updateTabs"
                />
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <Endereco
                  :info-cliente="infoCliente"
                  :update-tabs="updateTabs"
                />
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        v-if="loadNegociacao"
        md="9"
      >
        <div v-show="!telaNegociar">
          <b-tabs>
            <b-tab>
              <template #title>
                <feather-icon icon="AlignCenterIcon" />
                <span>Eventos</span>
              </template>
              <Eventos
                ref="eventosP"
                :eventos="eventos"
                :update-tabs="updateTabs"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="LayersIcon" />
                <span>Operações</span>
              </template>
              <Operacoes
                ref="operacoes"
                :acordo-prop="callAcordoProp"
                :update-tabs="updateTabs"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SmileIcon" />
                <span>Acordos</span>
              </template>
              <Acordos
                ref="acordoTab"
                :update-tabs="updateTabs"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="ExternalLinkIcon" />
                <span>Propostas</span>
              </template>
              <Propostas :update-tabs="updateTabs" />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="ListIcon" />
                <span>Parametros</span>
              </template>
              <Parametros />
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="FileIcon" />
                <span>Anexos</span>
              </template>
              <Anexos
                :id-cliente="parseInt(id)"
                :update-tabs="updateTabs"
              />
            </b-tab>
            <b-tab
              v-show="isCredor"
              lazy
            >
              <template #title>
                <feather-icon icon="BriefcaseIcon" />
                <span>Ações Cobranças</span>
              </template>
              <AcoesCobrancas />
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="RewindIcon" />
                <span>Eventos Antigos</span>
              </template>
              <EventosAntigos2 />
            </b-tab>
          </b-tabs>
        </div>
        <b-card v-if="telaNegociar">
          <b-tabs>
            <b-tab>
              <template #title>
                <feather-icon icon="AlignCenterIcon" />
                <span>Negociar</span>
              </template>
              <Negociar
                :toggle-negociar="negociar"
                :update-tabs="updateTabs"
                :inseri-evento="inseriEvento"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="LayersIcon" />
                <span>Operações</span>
              </template>
              <Operacoes
                ref="operacoes"
                :acordo-prop="callAcordoProp"
                :update-tabs="updateTabs"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SmileIcon" />
                <span>Acordos</span>
              </template>
              <Acordos
                :update-tabs="updateTabs"
              />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="ExternalLinkIcon" />
                <span>Propostas</span>
              </template>
              <Propostas />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AlignCenterIcon" />
                <span>Eventos</span>
              </template>
              <Eventos ref="eventosComp" />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="ListIcon" />
                <span>Parametros</span>
              </template>
              <Parametros />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Importando componetes de estilo
import { BRow, BCol, BCard, BTabs, BTab, BAlert } from 'bootstrap-vue'

// Importando mecanismo de requisições
import { mapState } from 'vuex'
import axios from '@/../axios-auth'
// Importando Componentes da tela
import InfoHeader from './components/InfoHeader.vue'
import Info from './components/Info.vue'
import Telefones from './components/Telefones.vue'
import Emails from './components/Emails.vue'
import Negociar from './negociar/Negociar.vue'
import SearchBar from './components/SearchBar.vue'
import Endereco from './components/Endereco.vue'

// Importando tabs
import Eventos from './tabs/Eventos.vue'
import Operacoes from './tabs/Operacoes.vue'
import Parametros from './tabs/Parametros.vue'
import Anexos from './tabs/Anexos.vue'
import Acordos from './tabs/Acordos/Acordos.vue'
import Propostas from './tabs/Proposta/Propostas.vue'
import EventosAntigos2 from './tabs/EventosAntigos2.vue'
import AcoesCobrancas from './tabs/AcoesCobrancas.vue'

export default {
  components: {
    InfoHeader,
    Info,
    BRow,
    BCol,
    BCard,
    BAlert,
    Telefones,
    Emails,
    Endereco,
    BTabs,
    BTab,
    Eventos,
    Operacoes,
    Parametros,
    Negociar,
    Anexos,
    Acordos,
    SearchBar,
    Propostas,
    EventosAntigos2,
    AcoesCobrancas,
  },
  data() {
    return {
      showAlert: true,
      alertMessage: 'Esta é uma mensagem de alerta!',
      tab: '',
      id: this.$route.params.id,

      infoCliente: {},
      operacoes: [],
      eventos: [],
      acordos: [],
      credores: [],
      emails: [],
      telefones: [],

      filtro: '',

      telaNegociar: false,

      operacoesEmCobranca: [],

      load: false,
      isCredor: localStorage.getItem('credores'),
      loadNegociacao: false,
      headerInfoCliente: {},
    }
  },
  computed: {
    filtrado() {
      const { filtro } = this
      const a = parseInt(filtro)
      if (a.isNan) {
        return filtro
      }
      this.replace(`/negociacao/${a}`)
      return null
    },
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
    }),
  },
  async created() {
    await this.$store.commit(
      'verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED',
      true,
    )
    this.loadNegociacao = false
    await this.getDataHeaderCliente()
    await this.updateTabs()
    this.loadNegociacao = true
    // this.$store.dispatch('eventos/getEvent', this.id)
    // const { permissoes } = localStorage
  },
  methods: {
    async updateTabs() {
      await this.$store.dispatch(
        'negociacao/getInfoCliente',
        this.$route.params.id,
      )
    },
    async negociar(status) {
      this.telaNegociar = status
      this.$refs.infoClienteComp.setButtonVoltar(status)
    },

    callAcordoProp(item) {
      this.$refs.acordoTab.getAcordoById(item)
    },
    inseriEvento(evento, motivo) {
      this.$refs.eventosP.inserirNovoEvento(evento, motivo)
      this.$refs.eventosComp.inserirNovoEvento(evento, motivo)
    },
    getDataHeaderCliente() {
      axios
        .get(`api/v1/acordo/negociacao/header/${this.$route.params.id}`)
        .then(res => {
          [this.headerInfoCliente] = res.data.dados
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#alerta {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: none;
}
#alerta.show {
  display: block;
}
#alerta .b-card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
