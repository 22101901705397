<template>
  <div>
    <b-button
      v-show="!activeSearchBar || isCredor"
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-success"
      @click="returnPageClientList"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      Voltar
    </b-button>

    <b-input-group
      class="mb-1"
    >
      <b-form-input
        v-show="activeSearchBar && !isCredor"
        v-model="filtro"
        class="text-uppercase"
        placeholder="Nome ou CPF do cliente"
        @keyup.enter="getCliente"
      />
      <b-input-group-append>
        <b-button
          v-show="activeSearchBar && !isCredor"
          variant="outline-primary"
          @click="getCliente"
        >
          <feather-icon
            icon="SearchIcon"
          />
        </b-button>
        <b-button
          v-show="activeSearchBar && !isCredor"
          variant="outline-primary"
          @click="$refs.pesquisaAvancada.show()"
        >
          <feather-icon
            icon="FilterIcon"
          />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-modal
      ref="pesquisaAvancada"
      title="Pesquisa Avançada"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
        <b-col md="5">
          <v-select
            v-model="avancado.campo"
            :options="opcoes"
          />
        </b-col>
        <b-col md="7">
          <b-input-group>
            <cleave
              v-model="avancado.valor"
              class="form-control"
              :raw="false"
              :options="avancado.mask"
              :disabled="!avancado.campo"
              :placeholder="avancado.placeholder"
              @keyup.native.enter="pesquisaAvancada"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="!avancado.campo"
                @click="pesquisaAvancada"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <span class="text-danger">{{ avancadaError }}</span>
          <b-table
            sticky-header
            bordered
            small
            hover
            :items="items"
            :fields="fields"
            @row-clicked="setCliente"
          >
            <template #cell(cpf)="data">
              {{ cpfLabel(data.item.cpf) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {
  BCol,
  BRow,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BFormInput,
  BModal,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'

import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormInput,
    BModal,
    BTable,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'código' },
        'nome',
        'cpf',
      ],
      items: [],
      avancado: {
        valor: null,
        campo: null,
        formatText: 'text',
        mask: {
          delimiters: [''],
          blocks: [],
          uppercase: true,
        },
        placeholder: '',
      },
      opcoes: [
        { label: 'CPF', value: 'cpf', type: 'text' },
        { label: 'Nome', value: 'nome', type: 'text' },
        { label: 'Email', value: 'email', type: 'text' },
        { label: 'Telefone', value: 'telefone', type: 'text' },
        { label: 'Operação', value: 'operacao', type: 'text' },
        { label: 'Aluno', value: 'aluno' },
      ],
      filtro: '',
      errorClienteMessage: '',
      avancadaError: '',
      // Esconde a SearchBar
      activeSearchBar: true,
      // Esconde a SearchBar se for credor
      isCredor: localStorage.getItem('credores'),
    }
  },
  watch: {
    'avancado.campo': {
      handler(n) {
        if (n) {
          if (n.value) {
            setTimeout(() => {
              this.avancado.formatText = n.type
            })
          }
          switch (n.value) {
            case 'cpf':
              this.avancado.mask = {
                delimiters: ['.', '.', '-'],
                blocks: [3, 3, 3, 2],
                uppercase: true,
                numericOnly: true,
              }
              this.avancado.placeholder = '000.000.000-00'
              break

            case 'telefone':
              this.avancado.mask = {
                delimiters: ['(', ')', ' ', '-', '-'],
                blocks: [0, 2, 0, 5, 4],
                uppercase: true,
                numericOnly: true,
              }
              this.avancado.placeholder = '(99) 91234-5678'
              break

            case 'email':
              this.avancado.mask = {
                delimiters: [''],
                blocks: [54],
                uppercase: false,
              }
              this.avancado.placeholder = 'teste@grupovoz.com.br'
              break

            default:
              setTimeout(() => {
                this.avancado.mask = {
                  delimiters: [''],
                  blocks: [64],
                  uppercase: true,
                }
                this.items = []
                this.avancado.placeholder = ''
                this.avancado.valor = ''
              })
              break
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
    async setCliente(item) {
      const routerPush = id => {
        const path = `/negociacao/${id}`
        if (this.$router.currentRoute.path !== path) {
          this.$router.push({ name: 'negociacao-cliente', params: { id } })
        }
      }
      routerPush(item.id)
      this.$router.go()
    },

    async pesquisaAvancada() {
      let body = {
        campo: this.avancado.campo.value,
        valor: this.avancado.valor,
      }

      this.items = []
      if (this.avancado.campo.value === 'cpf') {
        body = {
          campo: this.avancado.campo.value,
          valor: this.avancado.valor.replace(/[^0-9]/g, ''),
        }
      }

      if (this.avancado.campo.value === 'telefone') {
        body = {
          campo: this.avancado.campo.value,
          valor: this.avancado.valor.replace(/[^0-9]/g, ''),
        }
      }

      axios.post('api/v1/acordo/pesquisa/avancada', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        const { dados } = res.data
        if (dados.length > 0) {
          this.avancadaError = ''
          res.data.dados.map(dt => {
            this.items.push({
              id: dt.id,
              nome: dt.nome,
              cpf: dt.cpf_cnpj,
            })
          })
        } else {
          this.avancadaError = 'Nenhum Cliente encontrado'
        }
      }).catch(() => {
        this.$swal({
          title: 'ERRO',
          icon: 'error',
          text: 'Verifique se os campos de busca foram preenchidos corretamete, e tente novamente',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      })
    },

    async getCliente() {
      const routerPush = async id => {
        const path = `/negociacao/${id}`
        if (this.$router.currentRoute.path !== path) {
          await this.$router.replace({ name: 'negociacao-cliente', params: { id } })
          this.$router.go()
        }
      }

      const getId = ftr => {
        axios.get('api/v1/clientes/filtro', {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          params: {
            filtro: ftr,
            items_pag: 1,
            pag: 1,
          },
        }).then(res => {
          const { dados } = res.data
          if (dados.length === 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'AlertOctagonIcon',
                text: 'Cliente não Encontrado',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
              timeout: 2000,
            })
          } else {
            const { id } = dados[0]
            this.storeSearch(id)
            routerPush(id)
          }
        })
      }

      const filtro = this.filtro.toUpperCase()
      const verify = parseInt(filtro)
      const checkIfNumber = Number.isNaN(verify)

      if (checkIfNumber) {
        filtro.length > 0 ? getId(filtro.toUpperCase()) : null
      } else {
        const replaced = filtro.replace(/[^a-zA-Z0-9]/g, '')
        const verifyCpf = () => replaced.length === 11 && !Number.isNaN(parseInt(replaced))
        verifyCpf() ? getId(replaced.toUpperCase()) : axios.get(`api/v1/clientes/edit/${filtro}`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }).then(() => {
          this.storeSearch(filtro)
          routerPush(filtro)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'AlertOctagonIcon',
              text: 'Cliente não Encontrado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
            timeout: 2000,
          })
        })
      }
      // else {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Notificação',
      //       icon: 'AlertOctagonIcon',
      //       text: 'Não deixe o campo de pesquisa vazio!',
      //       variant: 'danger',
      //     },
      //   },
      //   {
      //     position: 'top-center',
      //     timeout: 2000,
      //   })
      // }
    },

    returnPageClientList() {
      this.$router.push({ name: 'credor.cliente' })
    },
    storeSearch(item) {
      const maxSearchHistory = 10
      // eslint-disable-next-line
      let search = JSON.parse(localStorage.getItem('buscasRecentes')) || []
      const isSearchMaxed = search.length === maxSearchHistory
      const workingSearch = isSearchMaxed ? search.splice(1) : search
      const updatedSearch = workingSearch.concat(item)
      localStorage.setItem('buscasRecentes', JSON.stringify(updatedSearch))
    },
  },
}
</script>
