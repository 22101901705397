<template>
  <div>
    <b-row>
      <b-col align-h="between">
        <h5>
          Emails
        </h5>
      </b-col>
      <b-col cols="end">
        <feather-icon
          v-show="showIconsEmail"
          v-b-modal.modalEmail
          size="20"
          icon="PlusCircleIcon"
          class="mr-2 cursor-pointer color-icon"
        />
        <feather-icon
          v-show="showIconsEmail"
          size="20"
          icon="SlashIcon"
          class="mr-2 cursor-pointer color-icon"
          @click="openModalBlackEmail"
        />
      </b-col>
    </b-row>
    <hr>

    <div
      v-for="item in emails"
      :key="item.email"
      class="d-flex justify-content-between align-items-center mt-1"
    >
      <div class="profile-user-info">
        <h6 class="mb-0">
          {{ item.email }}
        </h6>
      </div>
      <div class="profile-star ml-auto">
        <feather-icon
          v-show="showIconsEmail"
          icon="MailIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="openEmailModal(item.email)"
        />
        <feather-icon
          v-show="showIconsEmail"
          icon="XIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="addBlackList(item.id)"
        />
      </div>
    </div>
    <b-modal
      id="modalEmail"
      title="Cadastrar Email"
      cancel-variant="danger"
      :ok-disabled="save"
      ok-title="Salvar"
      cancel-title="Cancelar"
      @show="limparModal"
      @ok="storeEmail"
    >
      <b-row>
        <b-col cols="12">
          <label for="email">Email:</label>
          <b-form-input
            v-model="email"
            class="text-lowercase"
            autofocus
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="blackEmail"
      ref="modalBlackEmail"
      title="Emails na Blacklist"
      cancel-variant="danger"
      cancel-title="Cancelar"
    >
      <div
        v-for="item in emailsBlackList"
        :key="item.email"
        class="d-flex justify-content-between align-items-center mt-1"
      >
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ item.email }}
          </h6>
        </div>
        <div class="profile-star ml-auto">
          <feather-icon
            icon="XIcon"
            size="18"
            class="ml-1 cursor-pointer color-icon"
            @click="removeBlackList(item.id)"
          />
        </div>
      </div>
    </b-modal>
    <b-modal
      id="openEmailModal"
      ref="sendEmailModal"
      :title="`Enviar Email - ${bodyEmail.email}`"
      ok-title="Enviar"
      ok-only
      @ok="sendEmail(bodyEmail.email)"
    >
      <b-row>
        <b-col>
          <label for="">Template</label>
          <b-form-select
            v-model="selectedTemplate"
            :options="templateOptions"
            class="mb-1"
            @change="changeTemplate"
          />
          <label for="">Mensagem</label>
          <b-form-textarea
            v-model="bodyEmail.message"
            disabled
            rows="6"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BFormTextarea,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BModal,
    BFormTextarea,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    updateTabs: {
      type: Function,
      required: true,
    },
    // emails: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      showIconsEmail: true,
      email: '',
      emails: [],
      emailsBlackList: [],
      bodyEmail: {
        email: '',
        message: '',
      },
      selectedTemplate: null,
      templateOptions: [],
    }
  },
  computed: {
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
      credores: state => {
        const { credores } = state.negociacao
        return credores
      },
    }),
    save() {
      const { email } = this
      if (email.length > 0) {
        return false
      }
      return true
    },
  },
  created() {
    this.getEmails()
  },
  methods: {
    changeTemplate() {
      this.bodyEmail.message = this.selectedTemplate
    },
    async storeEmail() {
      const emailsBlack = []
      this.emailsBlackList.map(item => {
        emailsBlack.push(item.email)
      })

      if (emailsBlack.includes(this.email.toLowerCase())) {
        this.$swal({
          title: 'Error!',
          text: 'Esse email já se encontra na BlackList',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        const body = {
          email: this.email.toLowerCase(),
          cliente_id: this.$route.params.id,
        }
        await axios.post('api/v1/clientes/emails/store', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'CheckIcon',
              text: 'Email adicionado',
              variant: 'success',
            },
          }, {
            position: 'top-center',
            timeout: 1500,
          })
          this.getEmails()
        }).catch(() => {
          this.$swal({
            title: 'Error!',
            text: 'Erro ao cadastrar email',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.getEmails()
        })
      }
    },
    limparModal() {
      this.email = ''
    },
    async getEmails() {
      this.emails = await axios.get(`api/v1/clientes/emails/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => res.data.dados.emails.map(dt => (
        {
          id: dt.id,
          email: dt.email,
        }
      )))

      this.$store.commit('negociacao/setEmails', this.emails.map(email => email.email))
    },
    async getEmailTemplate() {
      await axios.get('/api/v1/template_email/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.TemplateEmail.map(template => {
          this.templateOptions.push({
            value: this.replaceTemplate(template.mensagem),
            text: template.titulo,
          })
        })
      })
    },
    async openEmailModal(mail) {
      this.bodyEmail = {
        email: mail,
        message: '',
      }
      await this.getEmailTemplate()
      this.$refs.sendEmailModal.show()
    },
    replaceTemplate(rawText) {
      return rawText
        .replace('{{PNOME}}', this.cliente.nome.split(' ')[0])
        .replace('{{NOME}}', this.cliente.nome)
        .replace('{{CPF}}', this.cliente.cpf_cnpj)
        .replace('{{CREDOR}}', this.credores[0].nome)
        .replace('{{CODIGO}}', this.cliente.id)
    },
    async openModalBlackEmail() {
      await this.getEmailsBlack()
      this.$refs.modalBlackEmail.show()
    },
    async getEmailsBlack() {
      this.emailsBlackList = []
      await axios.get(`api/v1/clientes/emails/black/list/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.emailsBlackList.push({
            id: dt.id,
            email: dt.email,
          })
        })
      })
    },
    async sendEmail(mail) {
      this.$swal({
        text: `Deseja enviar email para ${mail} ?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: 'Sim, enviar!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const body = {
            contatos: [
              {
                email: mail,
                nome: this.cliente.nome,
              },
            ],
            parametros:
              {
                mensagem: this.bodyEmail.message,
              },
          }
          axios.post('api/v1/email/enviar', body, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.token),
              Accept: 'application/json',
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sucesso',
                icon: 'Operação realizada com sucesso',
                text: 'Email enviado!',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
    addBlackList(id) {
      this.$swal({
        title: '',
        text: 'Confirma adicionar esse email para a blacklist?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`api/v1/clientes/emails/adicionar_blacklist/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'BellIcon',
                text: 'Email adicionado com sucesso para a blacklist!',
                variant: 'success',
              },
            }, {
              position: 'top-center',
              timeout: 1500,
            })
            this.getEmails()
            this.getEmailsBlack()
          }).catch(error => {
            this.$swal({
              title: 'Error! Algo inesperado aconteceu',
              text: error.response.data.mensage,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.getEmails()
          })
        }
      })
    },
    removeBlackList(id) {
      this.$swal({
        title: '',
        text: 'Confirma retirar esse email da blacklist?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`/api/v1/clientes/emails/retirar_blacklist/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'BellIcon',
                text: 'Email removido da blacklist com sucesso!',
                variant: 'success',
              },
            }, {
              position: 'top-center',
              timeout: 1500,
            })
            this.$bvModal.hide('blackEmail')
            this.getEmails()
          }).catch(error => {
            this.$swal({
              title: 'Error! Algo inesperado aconteceu',
              text: error.response.data.mensage,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.getEmails()
          })
        }
      })
    },
  },
}
</script>
