<template>
  <div>
    <b-row class="ml-1">
      <b-button
        v-show="isCredor ? null : activeButtonNegotiate"
        v-if="telaNegociar === false"
        class="mt-1 mb-1 mr-1"
        size="md"
        variant="gradient-success"
        @click="setNegociar"
      >
        <feather-icon
          icon="SmileIcon"
          class="mr-50"
        />
        <span
          class="align-middle"
        >Negociar</span>
      </b-button>

      <b-button
        v-else
        variant="gradient-danger"
        class="mt-1 mb-1 mr-1"
        size="md"
        @click="setNegociar"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          class="mr-50"
        />
        <span
          class="align-middle"
        >Voltar</span>
      </b-button>

      <b-button
        v-show="isCredor ? null : activeButtonNegotiate"
        class="mt-1 mb-1"
        size="md"
        variant="gradient-info"
        @click="newEvento"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Evento</span>
      </b-button>
    </b-row>

    <b-row>
      <b-col>
        <strong><label><b-badge variant="light-danger">{{ tabCancelled === true ? 'Existem acordos sem tabulação' : '' }}</b-badge></label></strong>
        <b-badge :variant="`${resolveVariantLocalizacao(dt.localizacao)} text-wrap`">
          {{ dt.localizacao }}
        </b-badge>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col v-if="dt.classificacao_cliente">
        <label><strong>Classificação:</strong> <span>{{ dt.classificacao_cliente.title }}</span></label>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col>
        <label>Observações</label>
        <b-form-textarea
          v-model="dt.observacoes"
          :disabled="editar"
          class="text-uppercase"
          style="overflow-y: hidden;"
          placeholder=""
          rows="2"
          max-rows="4"
        />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-alert
        v-if="cliente.roteiro && (cliente.roteiro.id || cliente.roteiro.titulo || cliente.roteiro.mensagem)"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          Roteiro
        </h4>
        <div class="alert-body">
          <span> {{ cliente.roteiro.mensagem }} </span>
          <b-button
            v-if="!isExecutive && !isCredor"
            style="position: absolute; bottom: 0; right: 0; display: flex; justify-content: flex-end;"
            @click="removeRoteiro"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-button>
        </div>
      </b-alert>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <b-button
          v-show="editar && showButtonEdit"
          variant="outline-primary"
          @click="editar=false"
        >
          Editar Observação
        </b-button>
        <b-col cols="auto">
          <b-button
            v-show="!editar"
            variant="outline-secondary"
            @click="resetInfo"
          >
            Cancelar
          </b-button>
          <b-button
            v-show="!editar"
            variant="primary"
            class="ml-1"
            @click="updateObservacao"
          >
            Salvar
          </b-button>
        </b-col>
      </b-col>
    </b-row>
    <b-row>
      <feather-icon
        v-show="isSupervisor"
        icon="UsersIcon"
        size="20"
        class="ml-2 cursor-pointer"
        @click="openModalRepresentantes()"
      />
    </b-row>

    <b-modal
      id="criarEvento"
      ref="criarEvento"
      title="Criar novo Evento"
      ok-title="Criar"
      :ok-disabled="newEv.desc && newEv.mt_selected ? false : true"
      cancel-title="Cancelar"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      size="lg"
      @ok="submitEvent"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Motivo do evento"
          label-for="motivo_evento"
          class="h5 font-weight-bold"
        >
          <v-select
            id="motivo_evento"
            v-model="newEv.mt_selected"
            label="nome"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="newEv.motivos"
          />
        </b-form-group>
        <b-form-goup>
          <label class="font-weight-bold h5">Descrição</label>
          <b-form-textarea
            v-if="!Estilizar"
            v-model="newEv.desc"
            class="text-uppercase"
            style="overflow-y: hidden;"
            placeholder=""
            rows="3"
            max-rows="8"
          />
          <quill-editor
            v-else
            v-model="newEv.desc"
            theme="snow"
          />
        </b-form-goup>
        <b-form-group
          class="mt-1 d-flex justify-content-end"
        >
          <b-form-checkbox
            v-model="Estilizar"
            switch
            inline
          />
        </b-form-group>
        <div v-if="checkEventosAndCredores">
          <label>Selecione o credor</label>
          <v-select
            v-model="selectedCredor"
            label="nome"
            :options="credores"
          />
        </div>
      </form>
    </b-modal>
    <b-modal
      ref="modalSelecionarCredorRepresentantes"
      cancel-variant="secondary"
      ok-only
      ok-title="Selecionar"
      centered
      size="md"
      no-close-on-backdrop
      :title="`cliente com ${credores.length} credores`"
    >
      <label>Selecione o credor</label>
      <v-select
        label="nome"
        :value="selectedCredor"
        :options="credores"
        :clearable="false"
        @input="getItem"
      />
    </b-modal>
    <b-modal
      ref="modalRepresentantes"
      title="Representantes"
      cancel-title="Fechar"
      cancel-variant="danger"
      size="xl"
    >
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="representantes"
      >
        <template #cell(relatorios)="data">
          <div
            v-for="relatorio in data.item.relatorios"
            :key="relatorio.id"
          >
            {{ relatorio.title }}
          </div>
        </template>
        <template #cell(telefone)="data">
          <div
            v-for="phone in JSON.parse(data.item.telefones)"
            :key="phone"
          >
            {{ phone }}
          </div>
        </template>
      </b-table>
      <b-overlay :show="load" />
    </b-modal>
  </div>
</template>
<script>
// Importando dependecias de estilos
import {
  BCol,
  BRow,
  BButton,
  BBadge,
  // BAvatar,
  BModal,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BAlert,
  BTable,
  BOverlay,
} from 'bootstrap-vue'

// importando vSelect
import vSelect from 'vue-select'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

// Importando component da notificação
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

// Importando mecânico de requisição
import { mapState, mapGetters } from 'vuex'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BFormCheckbox,
    BRow,
    BButton,
    BBadge,
    // BAvatar,
    BModal,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BAlert,
    BTable,
    BOverlay,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    tab: {
      type: Boolean,
      required: true,
    },
    negociar: {
      type: Function,
      required: true,
    },
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      Estilizar: false,
      tabCancelled: this.tab,
      editar: true,
      showButtonEdit: true,
      dt: {},
      telaNegociar: false,
      id_cliente: this.$route.params.id,
      newEv: {
        motivos: [],
        mt_selected: null,
        desc: '',
      },
      perservaEndereço: {
      },
      maskCep: {
        delimiters: ['-'],
        blocks: [5, 3],
      },
      activeButtonNegotiate: true,
      canChange: false,
      roteiro: '',
      representantes: [],
      fields: [
        { key: 'departamento', label: 'Departamento' },
        { key: 'nome', label: 'Nome' },
        { key: 'telefone', label: 'Telefone' },
        { key: 'email', label: 'Email' },
        { key: 'observacoes', label: 'Observações' },
        { key: 'relatorios', label: 'Receber Emails' },
      ],
      selectedCredor: null,
      load: false,
      eventosIDChangeClassificao: [7, 13, 14, 15, 33, 9, 21, 49, 48, 47, 46, 44, 43, 120, 16, 128, 130, 131, 132, 133],
    }
  },

  computed: {
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
      credores: state => {
        const { credores } = state.negociacao
        return credores
      },
    }),
    ...mapGetters({
      operacoesCobranca: 'negociacao/listOperacoesCobranca',
    }),
    isCredor() {
      const cred = JSON.parse(localStorage.getItem('credores'))
      if (cred) {
        return cred
      }
      return false
    },
    verificacaoCredores() {
      if (this.isCredor) {
        const credorCliente = this.credores.map(nome => nome.nome)
        const credoresUsuario = this.isCredor.map(nome => nome.nome)
        // verificando se o credor do cliente existe no acesso do usuario
        if (credoresUsuario.some(el => credorCliente.includes(el))) {
          return true
        }
      }
      return false
    },
    isSupervisor() {
      const supervisor = localStorage.getItem('funcao')
      if (supervisor === 'Supervisor(a) Cobrança') { return true }

      return false
    },
    isExecutive() {
      // eslint-disable-next-line camelcase
      const { executivo_id: executivo_cobranca_id } = localStorage
      // eslint-disable-next-line camelcase
      if (!executivo_cobranca_id || executivo_cobranca_id === 'false') {
        return false
      }
      return true
    },
    checkEventosAndCredores() {
      return !!(this.credores.length > 1
          && this.newEv.mt_selected
          && this.eventosIDChangeClassificao.includes(this.newEv.mt_selected.id))
    },
  },
  watch: {
    editar(n) {
      if (!n) {
        const keys = Object.keys(this.dt)
        keys.map(item => {
          this.perservaEndereço[item] = this.dt[item]
        })
      }
    },
    cliente(n) {
      this.dt = n
    },
  },
  created() {
    if (this.isCredor) {
      if (this.verificacaoCredores) {
        if (this.credores.length === 0) {
          this.$swal({
            title: 'Erro, cliente não possui credor!',
            text: 'Impossível negociar.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$router.replace({ name: 'negociacao' })
        } else {
          this.dt = this.cliente
          this.negociar(false)
        }
      } else {
        this.$swal({
          title: 'Você não tem permissão para ver essa negociação!',
          text: 'Impossível visualizar',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.$router.replace({ name: 'credor.cliente' })
      }
    } else if (!this.isCredor) {
      if (this.credores.length === 0) {
        this.$swal({
          title: 'Erro, cliente não possui credor!',
          text: 'Impossível negociar.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.$router.replace({ name: 'negociacao' })
      } else {
        this.dt = this.cliente
        this.negociar(false)
      }
    }
  },

  methods: {
    resetInfo() {
      const keys = Object.keys(this.perservaEndereço)
      keys.map(item => {
        this.dt[item] = this.perservaEndereço[item]
      })
      this.editar = true
    },
    async newEvento() {
      this.newEv.mt_selected = null
      this.newEv.desc = null
      this.selectedCredor = null
      await this.getMotivosEventos()
      this.$refs.criarEvento.toggle()
    },
    // Função para verificar se na descrição do evento existe uma url
    descriptionWithUrl(string) {
      const lower = string.toLowerCase()
      // eslint-disable-next-line
      const res = lower.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
      if (res !== null) {
        // eslint-disable-next-line
        const url = lower.replace(/[^\s]+/g, function (match) {
          return match.indexOf('https') === 0 ? match : match.toUpperCase()
        })
        return url
      }
      return string.toUpperCase()
    },
    async submitEvent() {
      const body = {
        descricao: this.descriptionWithUrl(this.newEv.desc),
        cliente_id: this.id_cliente,
        // eslint-disable-next-line radix
        usuario_empresas_id: parseInt(localStorage.getItem('userId')),
        motivo_evento_id: this.newEv.mt_selected.id,
        credores_id: this.selectedCredor ? this.selectedCredor.id : this.credores[0].id,
      }
      await this.$store.dispatch('eventos/createEvent', body).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sucesso',
            icon: 'CheckIcon',
            text: 'Evento criado com sucesso',
            variant: 'success',
          },
        },
        {
          position: 'top-right',
        })
      })
      this.updateTabs()
    },

    async sendLogEvent() {
      const body = {
        cliente_id: this.id_cliente,
        classificacao_cliente: this.newEv.mt_selected.nome.replace(/\d/g, '').substr(3),
        credor: this.credores.length === 1 ? this.credores[0].nome : null,
        usuario: localStorage.getItem('userName'),
      }
      axios.post('api/v1/log/cliente/classificacao', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.$swal({
          title: 'Evento modificado!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro durante o cadastro!',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      })
    },
    async getMotivosEventos() {
      await axios.get('api/v1/motivo_evento', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const mtEventos = []
        res.data.dados.map(item => {
          item.nome = `${item.id} - ${item.nome}`
          mtEventos.push(item)
        })
        this.newEv.motivos = mtEventos
      })
    },
    setNegociar() {
      const status = !this.telaNegociar
      if (this.operacoesCobranca.length > 0) {
        this.negociar(status)
        this.telaNegociar = status
      } else {
        this.$swal({
          title: 'Aviso!',
          text: ' Este Cliente não possui operações em Cobrança',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },

    setButtonVoltar() {
      this.telaNegociar = false
      this.$store.commit('negociar/setIsSimulacao', false)
    },
    resolveVariantLocalizacao(status) {
      if (status) {
        const letter = status.toLowerCase()
        const variants = {
          localizado: 'light-success',
          'nao localizado': 'light-danger',
          'em processo de localizacao': 'light-warning',
        }
        if (variants[letter]) {
          return variants[letter]
        }
      }
      return 'light-danger'
    },
    getClassificoesCliente() {
      ''
    },
    removeRoteiro() {
      axios.put(`api/v1/roteiro/retirar/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.updateTabs()
        this.$swal({
          icon: 'success',
          title: 'Atualizado',
          text: 'Roteiro removido com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERROR',
          text: 'Erro ao remover roteiro!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
    openModalRepresentantes() {
      if (this.credores.length > 1) {
        this.$refs.modalSelecionarCredorRepresentantes.show()
      } else {
        const credorID = this.credores.map(cred => cred.id)
        this.getRepresentantes(credorID)
        this.$refs.modalRepresentantes.show()
      }
    },
    getItem(item) {
      this.selectedCredor = item
      this.getRepresentantes(item.id)
      this.$refs.modalSelecionarCredorRepresentantes.hide()
      this.$refs.modalRepresentantes.show()
    },
    async getRepresentantes(id) {
      this.representantes = []
      this.load = true
      axios.get(`api/v1/credor_contatos/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        this.load = false
        this.representantes = res.data.dados
      })
    },
    updateObservacao() {
      // eslint-disable-next-line no-unused-vars
      const formData = {
        nome: this.dt.nome,
        cpf_cnpj: this.dt.cpf_cnpj,
        endereco: this.dt.endereco ? this.dt.endereco.toUpperCase() : null,
        bairro: this.dt.bairro ? this.dt.bairro.toUpperCase() : null,
        cidades_id: this.dt.cidade.id,
        cep: this.dt.cep,
        observacoes: this.dt.observacoes,
      }
      axios.put(`api/v1/clientes/update/${this.$route.params.id}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.editar = true
        this.$swal({
          icon: 'success',
          title: 'Atualizado',
          text: 'Informaçẽs do cliente atualizados com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
        this.updateTabs()
      }).catch(() => {
        this.$swal({
          title: 'ERROR',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
  },
}
</script>
<style>
  .star {
    color: yellow;
  }
  form .position {
    position: relative;
    left: 70%;
    top: 40%;
  }
</style>
