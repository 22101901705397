<template>
  <div>
    <b-row align-h="between">
      <b-col>
        <h4>
          Telefones
        </h4>
      </b-col>
      <b-col cols="end">
        <feather-icon
          v-show="showIcons"
          size="20"
          icon="PhoneOffIcon"
          class="mr-2 cursor-pointer color-icon"
          @click="getTelefonesBlack(), blackList()"
        />
      </b-col>
      <b-col cols="end">
        <feather-icon
          v-show="showIcons"
          size="20"
          icon="PlusCircleIcon"
          class="mr-2 cursor-pointer color-icon"
          @click="addTelefone"
        />
      </b-col>
      <b-col cols="end">
        <feather-icon
          v-show="selectedPhoneIds.length > 1"
          size="20"
          icon="TrashIcon"
          class="mr-2 cursor-pointer color-icon"
          @click="motivosBlackList()"
        />
      </b-col>
    </b-row>
    <hr>

    <div
      v-for="(item, index) in phones"
      :key="item.telefone"
      class="d-flex justify-content-between align-items-center mt-1"
    >
      <div
        v-if="item.whatsapp"
        class="mr-1"
      >
        <WhatsappSVG />
      </div>
      <b-form-checkbox
        v-model="selectedPhoneIds"
        :value="item.id"
      />
      <div class="profile-user-info">
        <h6 class="mb-0">
          {{ phoneLabel(item.telefone) }}
          <!-- arranjo tecnico para a função copiar funcionar no servidor -->
          <div class="sr-only">
            <input
              :id="`text-box${index}`"
              type="text"
              size="20"
              :value="item.telefone"
            >
          </div>
          <feather-icon
            icon="CopyIcon"
            class="ml-0.5 cursor-pointer color-icon"
            size="15"
            variant="gradient-info"
            @click="copiar(index)"
          />
          <span
            class="align-middle"
          />
        </h6>
      </div>
      <div class="profile-star ml-auto">
        <b-badge
          v-if="item.statusTelefone"
          pill
          variant="success"
        >
          <feather-icon
            v-show="showIcons"
            icon="CheckIcon"
            size="18"
            class="ml-1 cursor-pointer color-icon"
            @click="updatePhone(item.id, item.telefone, item.statusTelefone)"
          />
        </b-badge>
        <feather-icon
          v-else
          v-show="showIcons"
          icon="CheckIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="updatePhone(item.id, item.telefone, item.statusTelefone)"
        />
        <feather-icon
          v-show="showIcons"
          icon="MessageCircleIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="openSmsModal(item.telefone)"
        />
        <feather-icon
          v-show="showIcons"
          icon="PhoneIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="callTo(item.telefone)"
        />
        <feather-icon
          v-if="showIcons"
          v-b-tooltip.hover.top="`${item.favorito ? 'DESFAVORITAR' : 'FAVORITAR'}`"
          icon="StarIcon"
          :class="`ml-2 cursor-pointer ${item.favorito ? 'star' : ''}`"
          size="15"
          @click="telefoneFavorito(item.id, item.favorito)"
        />
        <feather-icon
          v-show="showIcons"
          icon="XIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="motivosBlackList(item.id)"
        />
      </div>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="addTelefoneModal"
      title="Cadastrar telefone"
      cancel-title="Cancelar"
      ok-title="Salvar"
      cancel-variant="outline-secondary"
      :ok-disabled="approved"
      @show="limparModal"
      @ok="storeTelefone"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Telefone"
          label-for="telefone"
        >
          <cleave
            id="telefone"
            v-model="telefone"
            class="form-control"
            :raw="false"
            :options="mask.telefone"
            placeholder="(99) 99999-9999"
          />
        </b-form-group>
        <b-form-group
          label="Tags"
          label-for="Informe as tags"
        >
          <v-select
            v-model="selectedTags"
            label="title"
            :options="optionTags"
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      ref="chooseSmsModal"
      size="lg"
      :title="`Escolha o que fazer com o número: ${sms.phone}`"
      ok-title="Enviar"
      ok-only
      :ok-disabled="sms.message === '' && whatsapp.message === ''"
      @ok="sms.message !== '' ? sendSms(sms.phone) : startChatGuru(whatsapp.phone)"
    >
      <b-tabs
        horizontal
        content-class="col-12 col-md-12 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-12 col-12"
        justified
      >
        <b-tab @click="clearTabsSMS">
          <!-- title -->
          <template #title>
            <feather-icon
              icon="AirplayIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Whatsapp</span>
          </template>
          <b-row>
            <b-col>
              <label for="">Template</label>
              <b-form-select
                v-model="selectedTemplateWhats"
                :options="templateWhats"
                class="mb-1"
                @change="changeTemplateWhats"
              />
              <label for="">Mensagem</label>
              <b-form-textarea
                v-model="whatsapp.message"
                disabled
                rows="3"
              />
            </b-col>
          </b-row>

        </b-tab>
        <!-- general tab -->
        <b-tab @click="clearTabsSMS">
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">SMS</span>
          </template>
          <b-row>
            <b-col>
              <label for="">Template</label>
              <b-form-select
                v-model="selectedTemplate"
                :options="templateOptions"
                class="mb-1"
                @change="changeTemplate"
              />
              <label for="">Mensagem</label>
              <b-form-textarea
                v-model="sms.message"
                disabled
                rows="3"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal
      id="modal-prevent-closing"
      ref="sendSmsModal"
      :title="`Enviar SMS - ${sms.phone}`"
      ok-title="Enviar"
      ok-only
      @ok="sendSms(sms.phone)"
    >
      <b-row>
        <b-col>
          <label for="">Template</label>
          <b-form-select
            v-model="selectedTemplate"
            :options="templateOptions"
            class="mb-1"
            @change="changeTemplate"
          />
          <label for="">Mensagem</label>
          <b-form-textarea
            v-model="sms.message"
            disabled
            rows="3"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-prevent-closing"
      ref="blackListModal"
      title="Números na BlackList"
      cancel-title="Cancelar"
    >
      <div
        v-for="item in phonesBlackList"
        :key="item.telefone"
        class="d-flex justify-content-between align-items-center mt-1"
      >
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ phoneLabel(item.telefone) }}
          </h6>
          <h6 class="mb-0">
            Motivo: {{ item.motivoBlackList }}
          </h6>
        </div>
        <div class="profile-star ml-auto">
          <feather-icon
            v-show="showIcons"
            icon="XIcon"
            size="18"
            class="ml-1 cursor-pointer color-icon"
            @click="removeBlackList(item.id)"
          />
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-prevent-closing"
      ref="motivosBlackList"
      title="Motivos BlackList"
      cancel-title="Cancelar"
      @ok="selectedPhoneIds.length > 1 ? addBlackListLote() : addBlackList(phone_id)"
    >
      <div>
        <b-form-group
          label="Motivo"
          label-for="Informe o motivo"
        >
          <v-select
            v-model="selectedMotivo"
            label="title"
            :options="optionMotivos"
          />
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      ref="modalSelecionarCredor"
      cancel-variant="secondary"
      ok-only
      ok-title="Selecionar"
      centered
      size="md"
      no-close-on-backdrop
      :title="`cliente com ${credores.length} credores`"
    >
      <label>Selecione o credor</label>
      <v-select
        label="nome"
        :value="selectedCredor"
        :options="credores"
        :clearable="false"
        @input="getItem"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BModal,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BBadge,
  VBTooltip,
  BTabs,
  BTab,
  BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapState } from 'vuex'
import axios from '@/../axios-auth'
import WhatsappSVG from '../../../../components/WhatsappSVG.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    vSelect,
    Cleave,
    BFormTextarea,
    BFormSelect,
    BBadge,
    BTabs,
    BTab,
    BFormCheckbox,
    WhatsappSVG,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    updateTabs: {
      type: Function,
      required: true,
    },
    infoCliente: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showIcons: true,
      phones: [],
      phonesBlackList: [],
      clientId: this.$route.params.id,
      status: null,
      telefone: '',
      optionTags: [{ title: 'Móvel' }, { title: 'Móvel/Whatsapp' }, { title: 'Fixo' }],
      selectedTags: null,
      mask: {
        telefone: {
          delimiters: ['(', ')', ' ', '-', '-'],
          blocks: [0, 2, 0, 5, 4],
          uppercase: true,
        },
      },
      sms: {
        phone: '',
        message: '',
      },
      selectedTemplate: null,
      templateOptions: [],
      isExecutivo: true,
      urlDiscador: process.env.VUE_APP_ROOT_FASTWAY,
      optionMotivos: [
        { title: 'INEXISTENTE' },
        { title: 'INSERIDO INDEVIDAMENTE' },
        { title: 'DESCONHECE O CLIENTE' },
        { title: 'CLIENTE INFORMA QUE NÃO LHE PERTENCE' },
      ],
      selectedMotivo: null,
      phone_id: null,
      templateWhats: [],
      whatsapp: {
        phone: '',
        message: '',
      },
      selectedTemplateWhats: null,
      selectedCredor: null,
      selectedPhoneIds: [],
    }
  },
  computed: {
    ...mapState({
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
      credores: state => {
        const { credores } = state.negociacao
        return credores
      },
    }),
    approved() {
      const { telefone, selectedTags } = this
      if (telefone.length > 0 && selectedTags) {
        return false
      }
      return true
    },
  },
  created() {
    this.getTelefones()
    // this.getSmsTemplate()
    // this.getWhatsTemplate()
    const { executivo_id: execId } = localStorage
    if (!execId || execId === 'false') {
      this.isExecutivo = false
    }
  },
  methods: {
    changeTemplate() {
      this.sms.message = this.selectedTemplate
    },
    limparModal() {
      this.telefone = ''
      this.selectedTags = ''
    },
    addTelefone() {
      this.$refs.addTelefoneModal.show()
    },
    blackList() {
      this.$refs.blackListModal.show()
    },
    motivosBlackList(item = false) {
      if (item) {
        this.phone_id = item
      }
      this.$refs.motivosBlackList.show()
    },
    changeTemplateWhats() {
      this.whatsapp.message = this.selectedTemplateWhats
    },

    getTelefones() {
      this.phones = []
      axios.get(`/api/v1/clientes/telefones/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.telefones.map(dt => {
          this.phones.push({
            id: dt.id,
            telefone: dt.telefone,
            statusTelefone: dt.status,
            favorito: dt.favorito,
            whatsapp: dt.whatsapp,
          })
        })
      })
    },
    getTelefonesBlack() {
      this.phonesBlackList = []
      axios.get(`/api/v1/clientes/telefones/black/list/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.phonesBlackList.push({
            id: dt.id,
            telefone: dt.telefone,
            motivoBlackList: dt.motivo_black_list,
          })
        })
      })
    },
    getSmsTemplate() {
      this.selectedTemplate = null
      this.templateOptions = []
      axios.get('/api/v1/template_sms/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.TemplateSms.map(template => {
          if (template.id === 20 || template.id === 12 || template.id === 29) {
            this.templateOptions.push({
              value: this.replaceTemplate(template.mensagem),
              text: `${template.id} - ${template.titulo}`,
            })
          }
        })
      })
    },
    getWhatsTemplate() {
      this.selectedTemplateWhats = null
      this.templateWhats = []
      axios.get('/api/v1/template_whatsapp/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(temp => {
          this.templateWhats.push({
            value: this.replaceTemplate(temp.mensagem),
            text: `${temp.id} - ${temp.titulo}`,
          })
        })
      })
    },
    replaceTemplate(rawText) {
      return rawText
        .replace('{{PNOME}}', this.cliente.nome.split(' ')[0])
        .replace('{{NOME}}', this.cliente.nome)
        .replace('{{CREDOR}}', this.selectedCredor !== null ? this.selectedCredor : this.credores[0].nome)
        .replace('{{CODIGO}}', this.cliente.id)
    },
    async storeTelefone() {
      const {
        // eslint-disable-next-line camelcase
        telefone, selectedTags: tags, clientId: cliente_id, status,
      } = this
      const body = {
        telefone: telefone.replace(/[^\w\s]/gi, '').replace(/\s/g, ''),
        cliente_id,
        tags: tags.title,
        status,
        origem: 'Atualização Cadastral',
      }
      await axios.post('api/v1/clientes/telefones/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          Accept: 'application/json',
        },
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Novo telefone cadastrado',
            icon: 'CheckIcon',
            text: res.data.mensagem,
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$swal({
          title: 'Error!',
          text: error.response.data.mensagem ? error.response.data.mensagem : error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      this.getTelefones()
    },
    async openSmsModal(cell) {
      this.sms = {
        phone: cell,
        message: '',
      }
      this.whatsapp = {
        phone: cell,
        message: '',
      }
      if (this.credores.length > 1) {
        this.$refs.modalSelecionarCredor.show()
      } else {
        await this.getSmsTemplate()
        await this.getWhatsTemplate()
        this.$refs.chooseSmsModal.show()
      }
      // this.$refs.sendSmsModal.show()

      // this.$refs.chooseSmsModal.show()
    },
    async getItem(item) {
      this.selectedCredor = null

      this.selectedCredor = item.nome

      this.$refs.modalSelecionarCredor.hide()
      await this.getSmsTemplate()
      await this.getWhatsTemplate()
      this.$refs.chooseSmsModal.show()
    },
    async sendSms(cell) {
      this.$swal({
        text: `Desejar enviar sms para ${cell}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: 'Sim, enviar!',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const body = {
            celular: cell,
            mensagem: this.sms.message,
          }
          axios.post('api/v1/sms/enviar', body, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.token),
              Accept: 'application/json',
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'Operação executada',
                text: 'SMS Enviado!',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
    addBlackList(id) {
      const idPhone = id
      const body = {
        id: idPhone,
        motivo_black_list: this.selectedMotivo.title,
      }
      axios.post('api/v1/clientes/telefones/adicionar_blacklist/', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificação',
            icon: 'BellIcon',
            text: 'Telefone adicionado na BlackList',
            variant: 'success',
          },
        }, {
          position: 'top-center',
          timeout: 1500,
        })
        this.getTelefones()
      }).catch(error => {
        this.$swal({
          title: 'Error! Algo inesperado aconteceu',
          text: error.response.data.mensagem,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    removeBlackList(id) {
      this.$swal({
        title: '',
        text: 'Deseja remover esse telefone da blacklist?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`api/v1/clientes/telefones/retirar_blacklist/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificação',
                icon: 'BellIcon',
                text: 'Telefone retirado da blacklist',
                variant: 'success',
              },
            }, {
              position: 'top-center',
              timeout: 1500,
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
              this.getTelefones()
            })
          }).catch(error => {
            this.$swal({
              title: 'Error! Algo inesperado aconteceu',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    async callTo(telefone) {
      this.$swal({
        title: 'Error!',
        text: `Discador ${telefone} fora do modo manual ou desconectado`,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    updatePhone(id, phone, status) {
      this.telefone = phone
      this.statusTelefone = status
      let body
      const {
        // eslint-disable-next-line camelcase
        telefone, clientId: cliente_id,
      } = this
      if (this.statusTelefone) {
        body = {
          telefone: telefone.replace(/[^\w\s]/gi, '').replace(/\s/g, ''),
          cliente_id,
          status: false,
        }
      } else {
        body = {
          telefone: telefone.replace(/[^\w\s]/gi, '').replace(/\s/g, ''),
          cliente_id,
          status: true,
        }
      }
      axios.put(`api/v1/clientes/telefones/update/${id}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          Accept: 'application/json',
        },
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualização feita com sucesso!',
            icon: 'CheckIcon',
            text: res.data.mensagem,
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$swal({
          title: 'Error!',
          text: error.response.data.mensagem ? error.response.data.mensagem : error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      this.getTelefones()
    },
    // função para copiar para area de transferencia do usuario utilizando metodos antigos
    // pelo fato do servidor não possuir certificado https. Refatorar quando estiver com o certificado https
    copiar(index) {
      // eslint-disable-next-line
      let testingCodeToCopy = document.querySelector(`#text-box${index}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copiado',
            icon: 'CheckIcon',
            text: 'Copiado com sucesso',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
        /* unselect the range */
        testingCodeToCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'erro',
            icon: 'XIcon',
            text: 'Erro ao copiar',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    async startChatGuru(phone) {
      this.$swal({
        title: 'Confirmação',
        text: `Deseja iniciar chat com o telefone 55${phone} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, iniciar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const body = {
              telefone: phone,
              cliente: `${this.$route.params.id} ${this.cliente.nome}`,
              mensagem: this.whatsapp.message,
            }
            axios.post('api/v1/whatsapp/enviar', body, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.token),
                Accept: 'application/json',
              },
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Iniciado',
                    icon: 'CheckIcon',
                    text: 'Chat cadastrado com sucesso!',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                this.clearTabsSMS()
              })
              .catch(error => {
                this.$swal({
                  title: 'Erro',
                  text: `Ops, algo deu errado: ${error.data.response.mensagem}`,
                  icon: 'danger',
                  showCancelButton: true,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                  buttonsStyling: false,
                })
                this.clearTabsSMS()
              })
          }
        })
    },
    clearTabsSMS() {
      this.whatsapp.message = ''
      this.sms.message = ''
      this.selectedTemplate = null
      this.selectedTemplateWhats = null
    },
    telefoneFavorito(id, favorito) {
      const body = {
        favorito: favorito !== true,
      }
      axios
        .put(`api/v1/clientes/telefones/favorito/${id}`, body)
        .then(() => {
          this.updateTabs()
          this.getTelefones()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: 'Ação realizada com sucesso!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        })
        .catch(error => {
          this.getTelefones()
          this.$swal({
            title: 'Erro',
            text: `Ops, algo deu errado: ${error.data.response.mensagem}`,
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        })
    },
    addBlackListLote() {
      const body = {
        phones: this.selectedPhoneIds,
        motivo_black_list: this.selectedMotivo.title,
      }
      axios.post('api/v1/clientes/telefones/adicionar_blacklist/lote', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.selectedPhoneIds = []
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificação',
            icon: 'BellIcon',
            text: 'Telefones adicionados na BlackList',
            variant: 'success',
          },
        }, {
          position: 'top-center',
          timeout: 1500,
        })
        this.updateTabs()
        this.getTelefones()
      }).catch(error => {
        this.$swal({
          title: 'Error! Algo inesperado aconteceu',
          text: error.response.data.mensagem,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
