<template>
  <div>
    <b-row>
      <b-col
        md="3"
        sm="12"
      >
        <div class="d-flex justify-content-start mb-1">
          <b-avatar
            size="50px"
            :text="getInitials(credorSelected.nome)"
          />
          <div class="row d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0 text-wrap">
                <strong>
                  {{ credorSelected.nome }}
                </strong>
              </h4>
              <b-badge variant="light-danger text-wrap">
                Código: #{{ credorSelected.id }}
              </b-badge>
            </div>
          </div>
          <div
            class="row d-flex flex-column ml-2"
          >
            <feather-icon
              v-if="alert"
              icon="AlertTriangleIcon"
              color="#DAA520"
              size="38"
            />
          </div>
        </div>
        <b-row>
          <b-col md="5">
            <div class="mb-1">
              <label>Parametros</label>
              <v-select
                v-if="isSituacao"
                label="label"
                :value="parametroKey.split('.')[1] === '1' ? 'ATIVO' : 'INTV'"
                :clearable="false"
                disabled
              />
              <v-select
                v-else
                label="label"
                :value="parametroKey"
                :clearable="false"
                disabled
              />
            </div>
          </b-col>
          <b-col md="7">
            <div class="mb-1">
              <label>Condição</label>
              <v-select
                label="label"
                :options="condicoes"
                :value="condicaoSelected"
                :clearable="false"
                @input="setParametro"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <b-card-text class="mb-0">
                Simulação
              </b-card-text>
              <b-form-checkbox
                v-model="isSimulacao"
                class="custom-control-danger"
                name="check-button"
                switch
              />
            </div>
          </b-col>
          <b-col v-if="isSupervisor">
            <div>
              <b-card-text class="mb-0">
                Baixa Credor
              </b-card-text>
              <b-form-checkbox
                v-model="isBaixaCredor"
                class="custom-control-danger"
                name="check-button"
                switch
              />
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        md="9"
      >
        <b-row class="mr-1">
          <b-col sm="2">
            <b-form-group
              label="N Parc"
              label-for="n-parcela"
            >
              <!-- <b-form-input
                v-model="nParcelas"
                :disabled="parceladoDisabled"
                type="number"
                :state="paramsValidate['nParcelas']"
                placeholder=""
              /> -->
              <b-form-spinbutton
                id="sb-maxmin"
                v-model="nParcelas"
                :disabled="parceladoDisabled"
                min="1"
                :max="nParcelas_max"
              />
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group
              label="Intervalo"
              label-for="intervalor"
            >
              <b-form-input
                v-model="intervalo"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <div class="mb-1">
              <label>Forma de pagamento</label>
              <v-select
                label="label"
                :disabled="isBaixaCredor"
                :clearable="false"
                :options="formasPagemento"
                :value="isBaixaCredor ? 'Credor' : formaPagamento"
                @input="setFormPagamento"
              />
            </div>
          </b-col>

          <b-col sm="2">
            <b-form-group
              label="Entrada"
              label-for="entrada"
            >
              <money
                v-model="entrada"
                class="form-control"
                v-bind="money"
                :disabled="verifyEntrada"
                :state="paramsValidate['entrada']"
              />
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <div>
              <label for="example-datepicker"> Data de Entrada</label>
              <b-input-group class="mb-1">
                <b-form-input
                  disabled
                  type="text"
                  show-decade-nav
                  :value="dataEntradaFormat"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    id="date-picker"
                    v-model="dataEntrada"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    local="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col
            v-if="isSimulacao || isBaixaCredor"
            sm="3"
          >
            <div>
              <label for="example-datepicker"> Data Credor informou</label>
              <b-input-group class="mb-1">
                <b-form-input
                  disabled
                  type="text"
                  show-decade-nav
                  :value="dataRetroFormat"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    id="date-picker"
                    v-model="dataRetro"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    local="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="2">
            <b-form-group
              label="Juros %"
              label-for="juros"
            >
              <b-form-input
                v-model="juros"
                type="number"
                :state="paramsValidate['juros']"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group
              label="Multa %"
              label-for="multa"
            >
              <b-form-input
                id="multa"
                v-model="multa"
                :state="paramsValidate['multa']"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="getRemuneracao"
            sm="2"
          >
            <b-form-group>
              <b-row>
                <b-col md="7">
                  <label for="">Hon. Variável</label>
                </b-col>
                <b-col md="5">
                  <b-form-checkbox
                    v-model="honorariosChecked"
                    checked="false"
                    class="custom-control-secondary"
                    name="check-button"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="DollarSignIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="PercentIcon" />
                    </span>
                  </b-form-checkbox>
                </b-col>
                <b-form-input
                  v-if="!honorariosChecked"
                  id="honorarios"
                  v-model="honorarios"
                  :state="paramsValidate['honorarios']"
                  type="number"
                  placeholder=""
                />
                <b-form-input
                  v-else
                  id="honorarios"
                  v-model="honorariosCash"
                  :state="paramsValidate['honorarios']"
                  type="number"
                  placeholder=""
                />
              </b-row>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group>
              <b-row>
                <b-col md="7">
                  <label for="desconto">Descontos</label>
                </b-col>
                <b-col md="5">
                  <b-form-checkbox
                    v-model="descontoChecked"
                    class="custom-control-secondary"
                    name="check-button"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="DollarSignIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="PercentIcon" />
                    </span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2">
                  <b-form-checkbox
                    v-model="descOp"
                    :disabled="descOpDisabled"
                  />
                </b-col>
                <b-col>
                  <b-form-input
                    v-if="!descontoChecked"
                    id="desconto"
                    v-model="desconto"
                    :state="paramsValidate['desconto']"
                    type="number"
                    placeholder=""
                  />
                  <b-form-input
                    v-else
                    id="desconto"
                    v-model="descontoCash"
                    :state="paramsValidate['desconto']"
                    type="number"
                    placeholder=""
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col>
            <label for="">Parcelas</label>
            <b-form-textarea
              id="descricao_evento"
              class="text-uppercase"
              readonly
              :value="parcelasDemonstrativo"
              placeholder=""
              rows="2"
              max-rows="3"
            />
          </b-col>
        </b-row>
        <b-row
          v-show="isSimulacao"
        >
          <b-col md="4">
            <b-alert
              variant="danger"
              show
            >
              <h4 class="alert-heading">
                MODO SIMULAÇÃO ATIVO
              </h4>
            </b-alert>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
  BCardText,
  BFormCheckbox,
  BAlert,
  BFormSpinbutton,
} from 'bootstrap-vue'
import { Money } from 'v-money'

import vSelect from 'vue-select'

import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BAvatar,
    vSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    BCardText,
    BFormCheckbox,
    BAlert,
    BFormSpinbutton,
    Money,
  },

  data() {
    return {
      parceladoDisabled: false,
      parametro: null,
      entradaDisabled: false,
      nParcelas_max: 1,
      lastHon: '0',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },

  computed: {
    isSupervisor() {
      const { funcao } = localStorage
      if (funcao === 'Supervisor(a) Cobrança') {
        return true
      }
      return false
    },
    verifyEntrada() {
      const { nParcelas, formaPagamento } = this
      if (nParcelas === '1' || nParcelas === 1 || formaPagamento.key === 'cartao_voz' || formaPagamento.key === 'cartao_credor') {
        setTimeout(() => {
          this.$store.commit('negociar/setParamKey', {
            key: 'entrada',
            val: '0',
          })
        })
        return true
      }
      return false
    },
    calcLimitar() {
      const baseMonth = 12

      const d = new Date(`${this.dataEntrada} 00:00`)

      const value = baseMonth - d.getMonth()
      return value
    },
    soAvista() {
      const { limitarBoleto, limitarCartao, parcelado } = this.parametroCompleto
      const { formasPagemento } = this
      const { id: credorId } = this.credorSelected

      const IMA_LIST = [
        161,
        112,
        140,
        139,
        115,
        105,
        76,
        126,
      ]

      if (this.calcLimitar <= 1 && limitarBoleto && limitarCartao) {
        return true
      }

      if (this.operacoesSelected === 1 && IMA_LIST.includes(credorId)) {
        return true
      }

      if (parcelado) {
        const keys = Object.keys(parcelado).filter(e => e !== 'avaible')
        if (this.calcLimitar <= 1 && limitarCartao && !keys.includes('boleto_pix')) {
          return true
        }
        const cartaoKeys = keys.filter(e => e.includes('cartao_'))
        if (this.calcLimitar <= 1 && limitarBoleto && cartaoKeys.length === 0) {
          return true
        }
      }

      if (formasPagemento.length === 0) {
        return true
      }
      return false
    },
    condicoes() {
      const keys = Object.keys(this.parametroCompleto).filter(e => e !== 'remuneracao')
      const condicoesKeys = {
        avista: { key: 'avista', label: 'À vista' },
        parcelado: { key: 'parcelado', label: 'Parcelado' },
      }
      if (this.isSimulacao) {
        return Object.keys(condicoesKeys).map(key => condicoesKeys[key])
      }
      let condicoes = keys.map(item => {
        if (Object.keys(this.parametroCompleto[item]).filter(e => e !== 'avaible').length > 0) {
          return condicoesKeys[item]
        }
        return null
      }).filter(Boolean)
      if (this.soAvista) {
        condicoes = condicoes.filter(e => e.key === 'avista')
        this.$store.commit('negociar/setCondicao', null)
      }
      if (!this.condicaoSelected && condicoes.length > 0) {
        this.$store.commit('negociar/setCondicao', condicoes[0])
      }
      return condicoes
    },
    formasPagemento() {
      if (this.isSimulacao) {
        return [
          {
            label: 'Boleto/PIX',
            key: 'boleto_pix',
          },
          {
            label: 'Link Voz',
            key: 'cartao_voz',
          },
          {
            label: 'Link Credor',
            key: 'cartao_credor',
          },
        ]
      }

      const { condicaoSelected, parametro } = this

      if (condicaoSelected) {
        const { key } = condicaoSelected
        if (key === 'avista') {
          this.parceladoDisabled = true
        } if (key === 'parcelado') {
          this.parceladoDisabled = false
        }
      }

      if (!parametro) {
        return []
      }

      let getKeys = Object.keys(parametro).filter(
        e => e !== 'avaible' && e !== 'portal',
      )

      if (this.condicaoSelected && this.condicaoSelected.key === 'parcelado') {
        const { limitarCartao, limitarBoleto } = this.parametroCompleto

        const cartaoAvaible = !limitarCartao || (limitarCartao && this.calcLimitar > 1)
        const boletoAvaible = !limitarBoleto || (limitarBoleto && this.calcLimitar > 1)

        if (!cartaoAvaible) {
          getKeys = getKeys.filter(e => !e.includes('cartao'))
        } else if (!boletoAvaible) {
          getKeys = getKeys.filter(e => !e.includes('boleto'))
        }
      }

      const options = getKeys.map(item => {
        const obj = {
          label: parametro[item].label,
          key: item,
        }
        return obj
      })

      return options
    },
    parcelasDemonstrativo() {
      let parcelasShow = ''
      const totalParcela = i => this.$store.getters['negociar/totalParcela'](i)
      const setDataVencimento = i => this.$store.getters['negociar/setDataVencimento'](i)
      const {
        nParcelas,
      } = this
      for (let i = 1; i <= nParcelas; i++) {
        parcelasShow += `${i}º - ${this.valorBr(parseFloat(totalParcela(i)), true)} -- ${this.americaDate(setDataVencimento(i))}\n`
      }
      return parcelasShow
    },
    paramsValidate() {
      let parametro = null
      if (this.isSimulacao) {
        this.parceladoDisabled = false
        this.nParcelas_max = 100
        return {}
      }

      const { key } = this.condicaoSelected

      if (key === 'avista') {
        if (parseFloat(this.nParcelas) !== 1) {
          setTimeout(() => {
            this.$store.commit('negociar/setnParcelas', 1)
          })
        }
      }

      if (this.parametro && this.formaPagamento) {
        parametro = this.parametro[this.formaPagamento.key]
      }
      const resp = {}
      const { remuneracao } = this.parametroCompleto
      if (parametro) {
        const {
          nParcelas,
          honorarios,
          desconto,
          entrada: entradaVal,
        } = this
        const keys = Object.keys(parametro)
          .filter(e => e !== 'avaible')
          .filter(e => e !== 'label')
          .filter(e => !e.includes('_max'))
          .filter(e => e !== 'desconto_concedido')

        if (parametro.desconto_concedido.key === 'obrigatorio') {
          this.$store.commit('negociar/setDescOp', true)
          this.$store.commit('negociar/setDescOpDisabled', true)
        } if (parametro.desconto_concedido.key === 'nao_conceder') {
          this.$store.commit('negociar/setDescOp', false)
          this.$store.commit('negociar/setDescOpDisabled', true)
        } if (parametro.desconto_concedido.key === 'nao_obrigatorio') {
          this.$store.commit('negociar/setDescOp', false)
          this.$store.commit('negociar/setDescOpDisabled', null)
        }

        if (remuneracao) {
          if (remuneracao.key !== 'honorario') {
            setTimeout(() => {
              this.$store.commit('negociar/setGetRemuneracao', false)
            })
          } else {
            this.$store.commit('negociar/setGetRemuneracao', true)
          }
        }

        keys.map(item => {
          if (
          // eslint-disable-next-line no-mixed-operators
            (parseFloat(this[item]) < parseFloat(parametro[item]) || parseFloat(this[item]) > parseFloat(parametro[`${item}_max`])) && item !== 'entrada' && item !== 'nParcelas' && item !== 'desconto' || this[item] === ''
          ) {
            resp[item] = false
          }

          if (item === 'nParcelas') {
            if (key === 'parcelado') {
              if (parseFloat(this.nParcelas) === 1) {
                setTimeout(() => {
                  this.$store.commit('negociar/setnParcelas', 2)
                })
              }
              if (parseFloat(nParcelas) > parseFloat(this.nParcelas_max) || parseFloat(nParcelas) < 0) {
                // this.alert = true
                resp[item] = false
              }
            }
          }

          if (item === 'entrada') {
            const total = parseFloat(this.totalNegociado)
            const percent = parseFloat(parametro[item]) / 100
            const entrada = parseFloat((total * percent).toFixed(2))
            if (parseFloat(entradaVal) < entrada || parseFloat(entradaVal) > total || entradaVal === '') {
              // this.alert = true
              resp[item] = false
            }

            const paramsKeys = this.parametro[this.formaPagamento.key]

            const entradaValor = (parseFloat(this.totalNegociado) * (parseFloat(paramsKeys.entrada) / 100)).toFixed(2)

            this.$store.commit('negociar/setParamKey', {
              key: 'entrada',
              val: entradaValor,
            })
          }

          if (item === 'remuneracao') {
            if (remuneracao.key === 'honorario') {
              const valor = parseFloat(honorarios)

              if (valor < parseFloat(parametro[item]) || valor > parseFloat(parametro[`${item}_max`]) || Number.isNaN(valor)) {
                // this.alert = true
                resp.honorarios = false
              }
            }
          }

          if (item === 'desconto') {
            if (parseFloat(desconto) < parseFloat(parametro[item]) || parseFloat(desconto) > parseFloat(parametro[`${item}_max`]) || this.desconto === '') {
              // this.alert = true
              resp.desconto = false
            }
          }
        })
      }
      if (Object.keys(resp).length > 0) {
        this.$store.commit('negociar/setAlert', true)
      } else {
        this.$store.commit('negociar/setAlert', false)
      }
      return resp
    },
    isSituacao() {
      const { id } = this.credorSelected
      if (id) {
        // const { id } = credor
        if (id === parseInt(process.env.VUE_APP_ROOT_FAFIRE) || id === parseInt(process.env.VUE_APP_ROOT_EDUCA_MAIS) || id === parseInt(process.env.VUE_APP_ROOT_i9)) {
          return true
        }
      }
      return false
    },
    ...mapState({
      credorSelected: state => {
        const { credorSelected } = state.negociar
        return credorSelected
      },
      operacoesSelected: state => state.negociar.operacoesSelected.length,
      parametroKey: state => state.negociar.parametroKey,
      parametroCompleto: state => state.negociar.parametroCompleto,
      parametroDefault: state => state.negociar.parametroDefault,
      condicaoSelected: state => state.negociar.condicaoSelected,
      formaPagamento: state => state.negociar.formaPagamento,
      totalNegociado: state => state.negociar.totais.valorTotal,
      clienteInativo: state => state.negociar.clienteInativo,
      alert: state => state.negociar.alert,
      descOpDisabled: state => state.negociar.descOpDisabled,
      getRemuneracao: state => state.negociar.getRemuneracao,
    }),
    dataEntradaFormat() {
      const { dataEntrada } = this
      const a = dataEntrada.split('-')
      const date = `${a[2]}/${a[1]}/${a[0]}`
      return date
    },
    dataRetroFormat() {
      const { dataRetro } = this
      if (dataRetro) {
        const a = dataRetro.split('-')
        const date = `${a[2]}/${a[1]}/${a[0]}`
        return date
      }
      return null
    },
    descOp: {
      get() {
        return this.$store.state.negociar.descOpDisabled
      },
      set(value) {
        this.$store.commit('negociar/setDescOp', value)
      },
    },
    nParcelas: {
      get() {
        return this.$store.state.negociar.negociacao.nParcelas
      },
      set(value) {
        this.$store.commit('negociar/setnParcelas', value)
      },
    },

    honorariosChecked: {
      get() {
        return this.$store.state.negociar.honorariosChecked
      },
      set(value) {
        this.$store.commit('negociar/setHonorariosChecked', value)
      },
    },

    honorariosCash: {
      get() {
        return this.$store.state.negociar.honorariosCash
      },
      set(value) {
        this.$store.commit('negociar/setHonorariosCash', value)
      },
    },

    isSimulacao: {
      get() {
        return this.$store.state.negociar.isSimulacao
      },
      set(value) {
        this.$store.commit('negociar/setDataRetro', this.dataEntrada)
        this.$store.commit('negociar/setIsSimulacao', value)
      },
    },

    isBaixaCredor: {
      get() {
        return this.$store.state.negociar.isBaixaCredor
      },
      set(value) {
        this.$store.commit('negociar/setDataRetro', this.dataEntrada)
        this.$store.commit('negociar/setIsBaixaCredor', value)
      },
    },

    intervalo: {
      get() {
        return this.$store.state.negociar.negociacao.intervalo
      },
      set(value) {
        this.$store.commit('negociar/setIntervalo', value)
      },
    },

    entrada: {
      get() {
        return this.$store.state.negociar.negociacao.entrada
      },
      set(value) {
        this.$store.commit('negociar/setEntrada', value)
      },
    },

    juros: {
      get() {
        return this.$store.state.negociar.negociacao.juros
      },
      set(value) {
        this.$store.commit('negociar/setJuros', value)
      },
    },

    multa: {
      get() {
        return this.$store.state.negociar.negociacao.multa
      },
      set(value) {
        this.$store.commit('negociar/setMulta', value)
      },
    },

    honorarios: {
      get() {
        return this.$store.state.negociar.negociacao.honorarios
      },
      set(value) {
        this.$store.commit('negociar/setHonorarios', value)
      },
    },

    dataEntrada: {
      get() {
        return this.$store.state.negociar.negociacao.dataEntrada
      },
      set(value) {
        this.$store.commit('negociar/setDataEntrada', value)
      },
    },
    dataRetro: {
      get() {
        return this.$store.state.negociar.negociacao.dataRetro
      },
      set(value) {
        this.$store.commit('negociar/setDataRetro', value)
      },
    },

    desconto: {
      get() {
        return this.$store.state.negociar.negociacao.desconto
      },
      set(value) {
        this.$store.commit('negociar/setDesconto', value)
      },
    },
    descontoCash: {
      get() {
        return this.$store.state.negociar.descontoCash
      },
      set(value) {
        this.$store.commit('negociar/setDescontoCash', value)
      },
    },
    descontoChecked: {
      get() {
        return this.$store.state.negociar.descontoChecked
      },
      set(value) {
        this.$store.commit('negociar/setDescontoChecked', value)
      },
    },
  },
  watch: {

    totalNegociado() {
      const paramsKeys = this.parametro[this.formaPagamento.key]
      if (this.parametroDefault.base_parcela && this.parametroDefault.base_parcela > 0 && !this.isSimulacao) {
        const { base_parcela: baseParcela } = this.parametroDefault
        let maxParcelas = Math.floor(parseFloat(this.totalNegociado) / baseParcela)
        maxParcelas = maxParcelas > parseFloat(paramsKeys.nParcelas) ? paramsKeys.nParcelas : maxParcelas
        this.nParcelas_max = maxParcelas < 2 ? 2 : maxParcelas
      }
    },

    formaPagamento(n) {
      const paramsKeys = this.parametro[n.key]

      if (this.parametroDefault.base_parcela && this.parametroDefault.base_parcela > 0 && !this.isSimulacao) {
        const { base_parcela: baseParcela } = this.parametroDefault
        let maxParcelas = Math.floor(parseFloat(this.totalNegociado) / baseParcela)
        maxParcelas = maxParcelas > parseFloat(paramsKeys.nParcelas) ? paramsKeys.nParcelas : maxParcelas
        this.nParcelas_max = maxParcelas < 2 ? 2 : maxParcelas
      }

      this.$store.dispatch('negociar/setParamKey', { paramsKeys: this.parametro[this.formaPagamento.key], totalNegociado: this.totalNegociado, verifyEntrada: this.verifyEntrada })
      // if (!n || !this.parametro[n.key]) {
      //   this.$store.commit('negociar/setFormaPagamento', this.formasPagemento[0])
      // }

      if (paramsKeys) {
        const { nParcelas } = paramsKeys
        if (nParcelas) {
          if ((this.parametroDefault.base_parcela === 0 || !this.parametroDefault.base_parcela) && !this.isSimulacao) {
            switch (n.key) {
              case 'boleto_pix': {
                const { limitarBoleto } = this.parametroCompleto
                this.nParcelas_max = limitarBoleto ? this.calcLimitar : paramsKeys.nParcelas
                break
              }
              case 'cartao_credor': {
                const { limitarCartao } = this.parametroCompleto
                this.nParcelas_max = limitarCartao ? this.calcLimitar : paramsKeys.nParcelas
                break
              }
              case 'cartao_voz': {
                const { limitarCartao } = this.parametroCompleto
                this.nParcelas_max = limitarCartao ? this.calcLimitar : paramsKeys.nParcelas
                break
              }
              case 'maquineta': {
                const { limitarCartao } = this.parametroCompleto
                this.nParcelas_max = limitarCartao ? this.calcLimitar : paramsKeys.nParcelas
                break
              }
              default:
                break
            }
          }
        }
      }
    },
    getRemuneracao(n) {
      if (!n) {
        this.honorarios = '0'
      } else {
        const { remuneracao_max: honorarioParametro } = this.parametroCompleto[this.condicaoSelected.key][this.formaPagamento.key]
        if (honorarioParametro) {
          this.honorarios = honorarioParametro
        }
      }
    },
    calcLimitar(n) {
      const { limitarCartao, limitarBoleto } = this.parametroCompleto
      if (limitarCartao || limitarBoleto) {
        if ((limitarBoleto && this.formaPagamento === 'boleto_pix') || (limitarCartao && (this.formaPagamento === 'cartao_voz' || this.formaPagamento === 'cartao_credor'))) {
          if (parseInt(this.nParcelas) > n) {
            this.$store.commit('negociar/setnParcelas', n)
          }
          this.nParcelas_max = n
        }
      }
    },
    nParcelas_max(n) {
      if (parseInt(this.nParcelas) > n) {
        this.$store.commit('negociar/setnParcelas', n)
      }
    },
    formasPagemento(n) {
      if (n && n.length > 0 && (!this.formaPagamento || !this.parametro[this.formaPagamento])) {
        this.$store.commit('negociar/setFormaPagamento', n[0])
      }
    },
    condicaoSelected(n) {
      if (n) {
        this.parametro = this.parametroCompleto[n.key]
      }
    },
  },
  methods: {
    setParametro(item) {
      this.$store.commit('negociar/setCondicao', item)
    },
    setFormPagamento(item) {
      this.$store.commit('negociar/setFormaPagamento', item)
    },
    setParametroKey() {
      // const paramsKeys = this.parametro[this.formaPagamento.key]
    },
  },
}

</script>
