<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-table
            striped
            responsive
            :fields="fields"
            :items="sortedItems"
          >
            <template #cell(valor_nominal)="data">
              <!-- {{ data.item }} -->
              {{ valorBr(parseFloat(data.item.valor_nominal), true) }}
            </template>
            <template #cell(created_at)="data">
              {{ formatTimezone(data.item.created_at) }}
            </template>

            <template #cell(vencimento)>
              <!-- {{}} -->
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="resolveVariantStatus(data.item.status)"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-avatar
                :id="`vizualizar-proposta-${data.item.id}`"
                class="mr-1 cursor-pointer"
                size="32"
                :variant="`light-warning`"
              >
                <feather-icon
                  icon="EyeIcon"
                  @click="ShowProposta(data.item)"
                />
                <b-tooltip
                  :target="`vizualizar-proposta-${data.item.id}`"
                  placement="top-right"
                >
                  <p class="mb-0">
                    Vizualizar proposta
                  </p>
                </b-tooltip>
              </b-avatar>
              <b-avatar
                class="mr-1 cursor-pointer"
                size="32"
                :variant="`light-primary`"
              >
                <feather-icon
                  icon="ListIcon"
                  @click="ShowLogProposta(data.item.id)"
                />
                <b-tooltip
                  title="Visualizar Log Proposta"
                  placement="top-right"
                >
                  <p class="mb-0">
                    Visualizar Log Proposta
                  </p>
                </b-tooltip>
              </b-avatar>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="showProposta"
      size="xl"
      no-close-on-backdrop
      :hide-footer="!actions"
    >
      <ViewProposta />

      <hr>

      <b-row>
        <b-col>
          <b-row>
            <b-col class="text-center">
              <h4>{{ progressMessage }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-progress
                :key="bar.variant"
                animated
                :value="bar.value"
                :variant="bar.variant"
                :class="'progress-bar-' + bar.variant"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template
        v-if="actions"
        #modal-footer
      >
        <b-row>
          <b-button
            variant="outline-success"
            size="md"
            class="float-right mr-1"
            @click="showDemonstrativo"
          >
            Demonstrativo
          </b-button>
          <div
            v-if="isSupervisor"
            class="mr-1"
          >
            <b-button
              variant="outline-warning"
              size="md"
              class="float-right"
              @click="enviarParaInstituicao"
            >
              Enviar para Instituição
            </b-button>
          </div>
          <div
            v-if="gerarAcordo && !updatePropostaBool"
            class="mr-1"
          >
            <b-button
              variant="warning"
              size="md"
              class="float-right"
              @click="fecharAcordo"
            >
              Gerar Acordo
            </b-button>
          </div>
          <div
            v-if="updatePropostaBool && isSupervisor"
            class="mr-1"
          >
            <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="updateProposta(true)"
            >
              Aprovar Proposta
            </b-button>
          </div>
          <div
            v-if="updatePropostaBool && isSupervisor"
            class="mr-1"
          >
            <b-button
              variant="danger"
              size="md"
              class="float-right"
              @click="updateProposta(false)"
            >
              Negar Proposta
            </b-button>
          </div>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      ref="viewLogProposta"
      title="Log Proposta"
      ok-only
    >
      <LogProposta
        :id-proposta="idProposta"
      />
    </b-modal>
    <b-modal
      id="modal-demonstrativo"
      ref="modal-demonstrativo"
      size="xl"
      title=""
    >
      <b-row>
        <b-col>
          <iframe
            id="myFrame"
            :src="urlDemonstrativo"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BModal, BProgress, BRow, BTable, BTooltip, BBadge,
} from 'bootstrap-vue'

import qs from 'qs'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'crypto-js/md5'
import { mapState } from 'vuex'
import api from '@/../axios-auth'
import ViewProposta from './components/ViewProposta.vue'
import LogProposta from '../../../supervisor/components/cards/LogProposta.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BTooltip,
    BModal,
    ViewProposta,
    BButton,
    BProgress,
    BBadge,
    LogProposta,
  },
  props: {
    updateTabs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'status', label: 'status' },
        { key: 'usuario', label: 'Usuário' },
        { key: 'numero_proposta', label: 'Nº Proposta' },
        { key: 'valor_nominal', label: 'Valor Nominal' },
        { key: 'created_at', label: 'Criada em' },
        { key: 'vencimento', label: 'Vencimento' },
        { key: 'actions', label: 'Ações' },
      ],

      bar: { variant: '', value: 0 },
      progressMessage: '',
      urlDemonstrativo: null,
      actions: false,
      gerarAcordo: false,
      updatePropostaBool: false,
      propostaSelected: null,
      idProposta: null,
    }
  },
  computed: {
    ...mapState({
      items: state => state.negociacao.propostas,
      operacoes: state => state.proposta.operacoes,
      parcelas: state => state.proposta.parcelas,
      acordo: state => state.proposta.acordo,
      formaPagamento: state => state.proposta.formaPagamento,
      simulacao: state => state.proposta.simulacao,
      parametros_contato: state => state.proposta.parametros_contato,
      parcelasOperacoes: state => state.proposta.parcelasOperacoes,
      parametroDefault: state => state.proposta.parametroDefault,
      credor: state => state.proposta.credor,
      cliente: state => {
        const { infoCliente } = state.negociacao
        return infoCliente
      },
      emailsCliente: state => {
        const { emails } = state.negociacao
        return emails
      },
    }),
    isSupervisor() {
      const { funcao } = localStorage
      this.actions = true
      if (funcao === 'Supervisor(a) Cobrança') {
        this.actions = true
        return true
      }
      return false
    },
    sortedItems() {
      return this.items.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
  },
  mounted() {},
  methods: {
    ShowProposta(item) {
      if (item.proposta) {
        const proposta = JSON.parse(item.proposta)

        if (item.status === 'Aprovado') {
          this.actions = true
          this.gerarAcordo = true
          this.updatePropostaBool = false
        } else if (item.status === 'Analise Supervisao') {
          this.actions = true
          this.updatePropostaBool = true
        } else if (item.status === 'Analise Credor') {
          this.actions = true
          this.updatePropostaBool = true
        } else {
          this.gerarAcordo = false
          this.updatePropostaBool = false
          if (!this.isSupervisor) {
            this.actions = false
          }
        }

        Object.keys(proposta).map(key => {
          this.$store.commit('proposta/setProposta', {
            key,
            value: proposta[key],
          })
        })
      }
      this.propostaSelected = item
      this.$refs.showProposta.toggle()
    },

    async gerarBoleto(acordo) {
      const firstParcela = this.parcelas[0]

      const { codigo_banco: banco, convenio } = this.parametroDefault.convenio

      const { cpf_cnpj, nome, endereco } = this.cliente

      const dataVencimento = firstParcela.data_vencimento.replace('/')

      const valorBoleto = parseFloat(firstParcela.valor_nominal) * 100
      const body = qs.stringify({
        token: '43241324',
        banco,
        convenio,
        numero_acordo: acordo.id,
        cpf_cnpj,
        nome,
        endereco,
        bairro: 'Três Poderes',
        cidade: 'IMPERATRIZ',
        uf: 'MA',
        cep: '65901150',
        data_vencimento: dataVencimento,
        valor: valorBoleto,
      })

      const config = {
        method: 'post',
        url: 'http://192.168.254.205:9000/boleto',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: body,
      }

      const boleto = await api(config).then(res => res.data)
      return boleto
    },
    async gerarPdf(boleto, acordo) {
      const [parcela] = acordo.parcelas
      const { usuarioEmpresas: userId } = localStorage

      const {
        codigo_barras,
        nosso_numero,
        numero_linha_digitavel,
        pix_copia_cola,
        txid,
      } = boleto

      const { convenio: cv } = this.parametroDefault

      const { nome_beneficiario } = cv
      const { cnpj_beneficiario } = cv
      const { endereco_beneficiario } = cv
      const { carteira } = cv
      const { agencia } = cv
      const banco = cv.codigo_banco
      const { convenio } = cv

      const data = {
        codigo_banco: banco,
        convenio,
        linha_digitavel: this.linhaDigitavel(numero_linha_digitavel),
        codigo_barra: codigo_barras,
        chave_pix: pix_copia_cola,
        nome_beneficiario,
        cnpj_beneficiario,
        endereco_beneficiario,
        vencimento: parcela.data_vencimento,
        valor: this.valorTotal,
        nosso_numero,
        carteira,
        agencia,
        data_documento: this.americaDate(parcela.data_vencimento),
        numero_documento: '2116654',
        desconto: '0',
        deducoes: '0',
        juros: '0',
        acrescimos: '0',
        // valor_cobrado: 3.50,
        valor_cobrado: this.valorTotal,
        nome_pagador: this.cliente.nome,
        cpf_pagador: this.cpfCNPJ(this.cliente.cpf_cnpj),
        nome_avaliador: this.credor.nome,
        codigo_credor: this.credor.id,
        parcelas_id: parcela.id,
        id_pix: txid,
        usuario_empresas_id: userId,
      }

      const { id } = await api
        .post('api/v1/boletos/store', data)
        .then(res => res.data.dados.boleto)
      const boletoPdf = await api
        .post(
          'api/v1/boletos/viewBoleto',
          {
            boleto: id,
          },
          { responseType: 'arraybuffer' },
        )
        .then(
          res =>
            new File([res.data], 'Boleto.pdf', {
              type: 'application/pdf',
            }),
        )

      const demonstrativo = await api
        .post(`api/v1/acordo/viewDemoSimples/${acordo.id}`, null, {
          responseType: 'arraybuffer',
        })
        .then(
          res =>
            new File([res.data], 'Demonstrativo.pdf', {
              type: 'application/pdf',
            }),
        )
      return { boletoPdf, demonstrativo }
    },
    async enviarEmail(boleto, demonstrativo, acordo) {
      const { cliente } = this

      const body = new FormData()
      body.append('name', `${this.fisrtLastName(cliente.nome)}`)
      body.append(
        'to',
        JSON.stringify(
          this.emailsCliente.map(email => ({
            name: this.fisrtLastName(cliente.nome),
            address: email,
          })),
        ),
      )
      body.append('idAcordo', acordo.id)
      body.append('boleto', boleto)
      body.append('demonstrativo', demonstrativo)

      const configEmail = {
        method: 'post',
        url: 'http://192.168.254.219:3000/api/v1/boleto/',
        data: body,
      }

      await api(configEmail)
    },
    fecharAcordo() {
      this.$swal({
        text: 'Deseja gerar o acordo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, gerar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line consistent-return
      }).then(async result => {
        if (result.value) {
          const {
            operacoes,
            parcelasOperacoes,
            acordo: acordoConfig,
            simulacao,
            parametros_contato,
            parcelas,
          } = this

          const body = {
            simulacao,
            acordo: acordoConfig,
            parcelas,
            operacoes,
            parametros_contato,
            parcelasOperacoes,
          }

          body.acordo.cliente_id = this.$route.params.id
          body.acordo.status = 'Aguardando'

          const formaPagamento = this.formaPagamento.key

          this.progressMessage = 'GERANDO ACORDO NA BASE'

          const { acordo } = await api
            .post('api/v1/acordo/store', body)
            .then(res => res.data.dados)
          // const acordo = null

          if (!acordo) {
            this.$swal({
              title: 'Error!',
              text: 'Erro ao gerar Acordo!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

            return null
          }

          this.bar = {
            variant: 'success',
            value: 25,
          }

          switch (formaPagamento) {
            case 'boleto_pix': {
              this.progressMessage = 'GERANDO BOLETO'
              const boleto = await this.gerarBoleto(acordo)

              if (!boleto.numero_linha_digitavel) {
                this.$swal({
                  title: 'Error!',
                  text: `${boleto.mensagem || 'Erro ao gerar boleto'}`,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.bar = {
                  variant: 'error', value: 0,
                }
                return null
              }

              this.bar = {
                variant: 'success',
                value: 50,
              }

              this.progressMessage = 'GERANDO PDF'
              const pdf = await this.gerarPdf(boleto, acordo)

              this.bar = {
                variant: 'success',
                value: 80,
              }
              this.progressMessage = 'ENVIANDO PARA O CLIENTE'

              await this.enviarEmail(pdf.boletoPdf, pdf.demonstrativo, acordo)

              this.bar = {
                variant: 'success',
                value: 90,
              }

              await this.updateTabs()

              this.bar = {
                variant: 'success',
                value: 100,
              }

              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso',
                    icon: 'CheckIcon',
                    text: '👋 Acordo gerado e enviado boleto para o email',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.bar = {
                variant: '',
                value: 0,
              }
              break
            }
            case 'cartao_voz': {
              const demonstrativo = await api
                .post(`api/v1/acordo/viewDemoSimples/${acordo.id}`, null, {
                  responseType: 'arraybuffer',
                })
                .then(
                  res =>
                    new File([res.data], 'Demonstrativo.pdf', {
                      type: 'application/pdf',
                    }),
                )

              const { nome } = this.cliente
              const hash = md5(acordo.numero_acordo).toString()
              const cartaoBody = new FormData()
              cartaoBody.append('name', this.fisrtLastName(nome))
              cartaoBody.append(
                'link',
                `https://fatura.grupovoz.com.br/cartao/${hash}`,
              )
              cartaoBody.append('demonstrativo', demonstrativo)
              cartaoBody.append('idAcordo', acordo.id)
              cartaoBody.append(
                'to',
                JSON.stringify(
                  this.emailsCliente.map(email => ({
                    name: this.fisrtLastName(nome),
                    address: email,
                  })),
                ),
              )

              const configEmail = {
                method: 'post',
                url: 'http://192.168.254.219:3000/api/v1/cartao/voz',
                data: cartaoBody,
              }
              await api(configEmail)
              await this.updateTabs()
              this.progressMessage = 'ENVIADO!!'
              this.bar = {
                variant: 'success',
                value: 100,
              }
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso',
                    icon: 'CheckIcon',
                    text: '👋 Acordo gerado e enviado fatura para o email',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )

              this.bar = {
                variant: '',
                value: 0,
              }
              break
            }
            case 'cartao_credor': {
              const demonstrativo = await api
                .post(`api/v1/acordo/viewDemoSimples/${acordo.id}`, null, {
                  responseType: 'arraybuffer',
                })
                .then(
                  res =>
                    new File([res.data], 'Demonstrativo.pdf', {
                      type: 'application/pdf',
                    }),
                )
              const reprentantes = await api
                .get(`api/v1/credor_contatos/${this.credor.id}`)
                .then(rep => rep.data.dados)

              const to = reprentantes.map(rep => {
                // eslint-disable-next-line consistent-return
                const resp = rep.relatorios.map(rel => {
                  if (rel.title.includes('Link')) {
                    return {
                      name: rep.nome,
                      address: rep.email,
                    }
                  }
                })
                return resp
              })

              if (to.length > 0) {
                const cartaoBody = new FormData()
                cartaoBody.append('to', JSON.stringify(to))
                cartaoBody.append('demonstrativo', demonstrativo)
                cartaoBody.append(
                  'emails',
                  JSON.stringify(this.emailsCliente.map(email => email)),
                )
                cartaoBody.append('name', this.cliente.nome)
                cartaoBody.append('credor', this.credor.nome)
                cartaoBody.append('cpfCliente', this.cliente.cpf_cnpj)
                cartaoBody.append('idAcordo', acordo.id)
                cartaoBody.append('telefones', JSON.stringify([]))

                api
                  .post(
                    'http://192.168.254.219:3000/api/v1/cartao/credor',
                    cartaoBody,
                    {},
                  )
                  .then(async () => {
                    await this.updateTabs()
                    this.bar = {
                      variant: 'success',
                      value: 100,
                    }
                    this.progressMessage = 'ENVIADO!!'
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Sucesso',
                          icon: 'CheckIcon',
                          text: '👋 Acordo gerado e enviado para o credor',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center',
                      },
                    )
                    this.bar = {
                      variant: '',
                      value: 0,
                    }
                  })
              }
              this.progressMessage = 'ENVIADO!!'
              this.bar = {
                variant: '',
                value: 0,
              }
              break
            }
            default:
              break
          }

          // this.$refs.showProposta.toggle()
        }
      })
    },
    updateProposta(value) {
      const item = this.propostaSelected

      this.$swal({
        title: 'Deseja atualizar a proposta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, atualizar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.progressMessage = 'Atualizando Proposta...'
          this.bar.variant = 'success'
          this.bar.value = 25
          api
            .put(`api/v1/proposta/update/status/${item.id}`, {
              status: value ? 'Aprovado' : 'Reprovado',
            })
            .then(async () => {
              this.bar.value = 50
              await this.updateTabs()
              this.bar.value = 100
              this.$refs.showProposta.toggle()
              this.$swal({
                icon: 'success',
                text: value ? 'Proposta Aprovada ' : 'Proposta Reprovada',
                timer: 1500,
                showConfirmButton: false,
              })
              this.bar.value = 0
              this.progressMessage = ''
            })
        }
      })
    },
    showDemonstrativo() {
      const bodyDemonstrativo = JSON.parse(this.propostaSelected.proposta)
      bodyDemonstrativo.simulacao = true
      bodyDemonstrativo.acordo.status = 'Aguardando'
      bodyDemonstrativo.acordo.cliente_id = this.$route.params.id
      this.$swal({
        title: 'Selecione modelo do demonstrativo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Resumido',
        cancelButtonText: 'Detalhado',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        const endpoint = result.dismiss ? 'api/v1/acordo/viewDemoDetalhado' : 'api/v1/acordo/viewDemoSimples'

        const demonstrativo = await api.post(endpoint, bodyDemonstrativo, {
          responseType: 'arraybuffer',
        }).then(res => {
          const file = new File([res.data], 'demonstrativo.pdf', {
            type: 'application/pdf',
          })
          return file
        })
        this.urlDemonstrativo = URL.createObjectURL(demonstrativo)
        this.$refs['modal-demonstrativo'].show()
      })
    },

    async enviarParaInstituicao() {
      const itemProposta = this.propostaSelected
      const bodyDemonstrativo = JSON.parse(this.propostaSelected.proposta)

      bodyDemonstrativo.simulacao = true
      bodyDemonstrativo.acordo.status = 'Aguardando'
      bodyDemonstrativo.acordo.cliente_id = this.$route.params.id

      this.progressMessage = 'Gerando PDF...'
      this.bar = {
        value: 25,
        variant: 'success',
      }
      const demonstrativo = await api
        .post('api/v1/acordo/viewDemoDetalhado', bodyDemonstrativo, {
          responseType: 'arraybuffer',
        })
        .then(res => {
          const file = new File([res.data], 'demonstrativo.pdf', {
            type: 'application/pdf',
          })
          return file
        })

      this.progressMessage = 'Enviando para credor...'
      this.bar.value = 75

      const { nome, cpf_cnpj } = this.cliente

      const representantes = await api.get(`api/v1/credor_contatos/${this.credor.id}`).then(res => res.data.dados)

      const to = representantes.filter(e => e.relatorios.filter(i => i.title === 'Proposta').length > 0).map(item => ({
        name: item.nome,
        address: item.email,
      }))

      const getRemuneracao = parseFloat(bodyDemonstrativo.acordo.honorarios_porcento) === 0 && parseFloat(bodyDemonstrativo.acordo.comissao_porcento) > 0

      const body = new FormData()
      body.append('name', `${nome}`)
      body.append('from', JSON.stringify({ name: localStorage.userName, address: localStorage.userEmail }))
      body.append('to', JSON.stringify(to))
      body.append('credor', this.credor.nome)
      body.append('demonstrativo', demonstrativo)
      body.append('nParcelas', bodyDemonstrativo.parcelas.length)
      body.append('getRemuneracao', getRemuneracao)
      // eslint-disable-next-line camelcase
      body.append('cpfCliente', cpf_cnpj)

      body.append(
        'dataProposta',
        this.americaDate(bodyDemonstrativo.parcelas[0].data_vencimento),
      )
      body.append('total', `R$ ${this.valorBr(parseFloat(bodyDemonstrativo.acordo.valor_nominal), false)}`)

      body.append(
        'table',
        `
        <table class="MsoNormalTable" border="1" cellspacing="0" cellpadding="0">
                    <tbody>
                    <tr>
                      <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Juros</span></b></p>
                      </td>
                      <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Multa</span></b></p>
                      </td>
                      <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Honorário</span></b></p>
                      </td>
                      <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">Desconto</span></b></p>
                      </td>
                    </tr>
                    <tr style="height:7.1pt">
                      <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.juros_porcento
  // eslint-disable-next-line indent
                        }%</span></b></p>
                      </td>
                      <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.multas_porcento
}%</span></b></p>
                      </td>
                      <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.honorarios_porcento
}%</span></b></p>
                      </td>
                      <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt; height:7.1pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><b><span style="font-size:9.0pt; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${
  bodyDemonstrativo.acordo.desconto_porcento
}%</span></b></p>
                      </td>
                    </tr>
                    <tr>
                      <td width="97" style="width:72.55pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.juros),
    true,
  )}</span></p>
                      </td>
                      <td width="85" style="width:63.8pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.multas),
    true,
  )}</span></p>
</td>
                      <td width="94" style="width:70.85pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.honorarios),
    true,
  )}</span></p>
                      </td>
                      <td width="95" style="width:70.9pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
                        <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(
    parseFloat(bodyDemonstrativo.acordo.desconto),
    true,
  )}</span></p>
                      </td>
                    </tr>
                    </tbody>
                  </table>
      `,
      )

      const configEmail = {
        method: 'post',
        url: 'http://192.168.254.219:3000/api/v1/proposta-credor',
        data: body,
      }

      await api(configEmail)

      const bodyEvento = {
        descricao: '<h2>Proposta Enviada para o credor<h2>',
        cliente_id: parseInt(this.$route.params.id),
        motivo_evento_id: 43,
      }

      this.progressMessage = 'Gerando Evento'
      this.bar.value = 75

      await api.post('api/v1/eventos/store', bodyEvento)

      await api.put(`api/v1/proposta/update/status/${itemProposta.id}`, {
        status: 'Analise Credor',
      })

      await this.updateTabs()

      this.progressMessage = 'Concluído...'
      this.bar.value = 100

      setTimeout(() => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Concluído',
              icon: 'CheckIcon',
              text: 'Proposta enviada para o credor',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          },
        )
        this.progressMessage = ''
        this.bar = {
          value: 0,
          variant: '',
        }
      }, 1000)
    },
    resolveVariantStatus(itemStatus) {
      if (itemStatus) {
        const letter = itemStatus.toLowerCase()
        const variants = {
          aprovado: 'success',
          reprovado: 'danger',
          'analise supervisao': 'primary',
          'analise credor': 'warning',
        }
        if (variants[letter]) {
          return variants[letter]
        }
      }
      return 'info'
    },
    ShowLogProposta(id) {
      this.idProposta = id
      this.$refs.viewLogProposta.show()
    },
  },
}
</script>
